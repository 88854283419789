import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Layout from 'components/Layout';

// import Home from './Home';
import Logistic from './Dashboard/Logistic';
import Juridical from './Dashboard/Juridical';
import Financial from './Dashboard/Financial';
import TrafficTickets from './TrafficTickets';
import TrafficTicketDetail from './TrafficTicketDetail';
import UserNew from './UserNew';

function AdminPage() {
  return (
    <Layout>
      <Routes>
        {/* <Route path='/inicio' element={<Home />} /> */}
        <Route path='/inicio' element={<Logistic />} />
        <Route path='/dashboard/financeiro' element={<Financial />} />
        <Route path='/dashboard/juridico' element={<Juridical />} />
        <Route path='/multas' element={<TrafficTickets />} />
        <Route path='/multas/:trafficTicketId' element={<TrafficTicketDetail />} />
        <Route path='/usuarios/novo' element={<UserNew />} />
        <Route path='/*' element={<Navigate to='/inicio' replace />} />
      </Routes>
    </Layout>
  );
}

export default AdminPage;
