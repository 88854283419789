import { EFontFamily } from 'helpers/constants';
import { colors, styled } from '@mui/material';
import { rem } from 'helpers/utils';

export const Title = styled('div')(() => ({
  margin: `${rem(10)} 0`,

  '& p': {
    width: 'auto',
    fontSize: rem(18),
    fontWeight: 600,
    fontFamily: EFontFamily.poppins,
    color: '#474747',
    margin: 0,
    padding: 0
  },

  '& hr': {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid #d5d7d8',
    margin: `${rem(4)} 0`,
    color: colors.grey[400]
  },

  '& > .title-content': {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',

    '& > div > svg': {
      color: colors.grey[400],
      marginRight: rem(6)
    }
  }
}));
