import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Pages from './components/Pages';
import CustomThemeProvider from './components/Layout/ThemeProvider';

export default function App() {
  return (
    <CustomThemeProvider>
      <CssBaseline />
      <Pages />
    </CustomThemeProvider>
  );
}
