import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Divider from 'components/Shared/Divider';
import FormSelect from 'components/Shared/FormFields/FormSelect';
import FormText from 'components/Shared/FormFields/FormText';
import FormToggleGroup from 'components/Shared/FormFields/FormToggleGroup';
import StaticField from 'components/Shared/StaticField';
import { EMPTY_FIELD_VALUE, TITLE_LAYER_URL } from 'helpers/constants';
import { formatDate } from 'helpers/date';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { infraction, infractionPatchData } from 'services/antt';
import { addCarrier, Carrier, getCarrierOptions } from 'services/carriers';
import { Options } from 'services/filters';
import { ROLE, User } from 'services/auth';

import { Marker, Popup, TileLayer } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';

import { formatCurrency } from 'helpers/formats';
import { normalizeAddress } from 'helpers/utils';
import { GEOAPIFY_KEY } from 'settings';
import { buildGeocodeUrl, fetchData } from 'services/antt/geocode';
import { responsabilityOptions, appealOptions, toFinancialOptions } from '../constants';

import type { orderService, legalData, financeData, infractionDetails, GeocodingResponse } from './constants';
import AddCarrier from './AddCarrier';
import LocationIcon from '../../../../../assets/images/location-icon.png';
import useStyle, { CustomMapContainer, CustomTooltip, LocationRow, ResponsibleToggle, Row } from './styles';
import 'leaflet/dist/leaflet.css';

function Logistics(prop: { infractionData: infraction; onChange: (data: Partial<infractionPatchData>) => void }) {
  const [responsibility, setResponsibility] = useState<string>('');
  const [carrierOptions, setCarrierOptions] = useState<Options>([]);
  const [carrierSelected, setCarrierSelected] = useState('');
  const [modalAddCarrierIsOpen, setModalAddCarrierIsOpen] = useState(false);

  const [orderService, setOrderService] = useState<orderService | null>(null);
  const [legalData, setLegalData] = useState<legalData | null>(null);
  const [financeData, setFinanceData] = useState<financeData | null>(null);

  const [hasModuleAccess, setHasModuleAccess] = useState(false);
  const userSerialized = localStorage.getItem('user') || '';
  const storageUser: User = JSON.parse(userSerialized);
  const userRole = storageUser.roles[0].name;

  const [location, setLocation] = useState<LatLngTuple>([0, 0]);

  const { infractionData, onChange } = prop;
  const {
    authority_name,
    subtype,
    infraction_date,
    infraction_time,
    address,
    authority_situation,
    city,
    state,
    number,
    process_number,
    vehicle,
    nickname,
    fine_amount
  } = infractionData;

  const handleToggleAddCarrier = () => setModalAddCarrierIsOpen(oldState => !oldState);

  const classes = useStyle()();

  const handleConfirmAddCarrier = (newCarrier: Carrier) => {
    addCarrier(
      newCarrier,
      data => {
        carrierOptions.push({
          label: data.name,
          value: data.name
        });
        setCarrierOptions(carrierOptions);
        setCarrierSelected(data.name);
        setModalAddCarrierIsOpen(false);
      },
      error => {
        console.log(error);
        setModalAddCarrierIsOpen(false);
      }
    );
  };

  const handleChangeCanAppeal = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    const assigmentDate =
      newValue === 'true' && !infractionData.legal_assignment_date
        ? DateTime.now().toFormat('yyyy-MM-dd')
        : infractionData.legal_assignment_date?.split(' ')[0];

    setLegalData({
      ...legalData,
      assignmentDate: assigmentDate,
      isWithLegal: newValue !== null ? newValue === 'true' : undefined
    });
  };

  const handleChangeResponsability = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    setResponsibility(newValue);
  };

  const handleChangeAdvancedToFinancial = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    const assigmentDate =
      newValue === 'true' && !infractionData.finance_assignment_date
        ? DateTime.now().toFormat('yyyy-MM-dd')
        : infractionData.finance_assignment_date?.split(' ')[0];

    setFinanceData({
      ...financeData,
      assignmentDate: assigmentDate,
      isWithFinance: newValue !== null ? newValue === 'true' : undefined
    });
  };

  const handleChangeCarrier = (event: SelectChangeEvent) => setCarrierSelected(event.target.value);
  const renderValue = (value: string | null) => value ?? EMPTY_FIELD_VALUE;

  const formattedDate = infraction_date ? formatDate(infraction_date) : EMPTY_FIELD_VALUE;

  const loadData = useCallback(() => {
    setOrderService({
      number: infractionData.os_number,
      date: infractionData.os_date,
      carrierNumber: infractionData.os_carrier_number,
      additionalInfo: infractionData.os_additional_info
    });
    setLegalData({
      assignmentDate: infractionData.legal_assignment_date?.split(' ')[0],
      instructions: infractionData.legal_instructions,
      isWithLegal: infractionData.legal_is_with_legal
    });
    setFinanceData({
      assignmentDate: infractionData.finance_assignment_date?.split(' ')[0],
      instructions: infractionData.finance_instructions,
      isWithFinance: infractionData.finance_is_with_finance,
      paymentOrder: infractionData.finance_payment_order,
      requestDate: infractionData.finance_request_date?.split(' ')[0],
      sentDate: infractionData.finance_sent_date?.split(' ')[0]
    });
  }, [infractionData]);

  const fetchCarriers = useCallback(() => {
    getCarrierOptions(
      (options: Options) => {
        setCarrierOptions(options);
        const details = JSON.parse(infractionData.details) as infractionDetails;
        setCarrierSelected(details?.transportadora || '');
        setResponsibility(details?.responsavel || infractionData.responsible || '');
      },
      (error: string) => console.error(error)
    );
  }, [infractionData]);

  const getTitlePlate = () => {
    if (!infractionData.plate_owner_cnpj && !infractionData.plate_owner_name) {
      return 'Responsável não informado';
    }

    return (
      <>
        <span>
          <b>Proprietário:</b>
        </span>
        <br />
        <span>Nome: {infractionData.plate_owner_name || 'Não informado'}</span>
        <br />
        <span>CNPJ: {infractionData.plate_owner_cnpj || 'Não informado'}</span>
        <br />
        <span>É Terceiro?: {infractionData.plate_owner_is_third_party ? 'Sim' : 'Não'}</span>
      </>
    );
  };

  const extractCoordinates = (data: GeocodingResponse) => {
    const { lat, lon } = data.results[1];
    return [lat, lon] as LatLngTuple;
  };

  useEffect(() => {
    setHasModuleAccess(userRole === ROLE.LOGISTIC || userRole === ROLE.MANAGER || userRole === ROLE.ADMIN);

    loadData();
    fetchCarriers();

    const geocode = async () => {
      try {
        const normalizedAddress = normalizeAddress(address);
        const url = buildGeocodeUrl(normalizedAddress, city, state, GEOAPIFY_KEY);

        const data = await fetchData(url);

        if (data && data.results.length > 0) {
          const coordinates = extractCoordinates(data);
          setLocation(coordinates);
        }
      } catch (error) {
        console.error('Nenhum resultado encontrado para o endereço fornecido.');
      }
    };

    geocode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData, fetchCarriers]);

  const customIcon = new L.Icon({
    iconUrl: LocationIcon,
    iconSize: [26, 26],
    iconAnchor: [11, 30],
    popupAnchor: [0, -38]
  });

  const hasLocation = location[0] !== 0 && location[1] !== 0;

  useEffect(() => {
    onChange({
      carrier_name: carrierSelected,
      responsible: responsibility,
      os_number: orderService?.number,
      os_date: orderService?.date,
      os_carrier_number: orderService?.carrierNumber,
      os_additional_info: orderService?.additionalInfo,
      legal_assignment_date: legalData?.assignmentDate,
      legal_instructions: legalData?.instructions,
      legal_is_with_legal: legalData?.isWithLegal,
      finance_assignment_date: financeData?.assignmentDate,
      finance_instructions: financeData?.instructions,
      finance_is_with_finance: financeData?.isWithFinance,
      finance_payment_order: financeData?.paymentOrder,
      finance_request_date: financeData?.requestDate,
      finance_sent_date: financeData?.sentDate
    });

    // Not needed to watch onChange state changes (callback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderService, legalData, financeData, carrierSelected, responsibility]);

  return (
    <>
      <Divider title='Detalhes da Multa' />

      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Row>
            <StaticField label='Número do Processo' value={renderValue(process_number)} />
          </Row>
          <Row>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={4}>
                <StaticField label='Autoridade' value={renderValue(authority_name)} />
              </Grid>
              <Grid item xs={4}>
                <StaticField label='Subtipo' value={renderValue(subtype)} />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <StaticField label='Placa' value={renderValue(vehicle)} />
                <CustomTooltip title={getTitlePlate()}>
                  <InfoIcon color='primary' fontSize='inherit' />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Row>
          <Row>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={6}>
                <StaticField label='Data da Ocorrência' value={renderValue(formattedDate)} />
              </Grid>
              <Grid item xs={6}>
                <StaticField label='Hora' value={renderValue(infraction_time)} />
              </Grid>
            </Grid>
          </Row>
          <Row>
            <StaticField label='Autuado' value={renderValue(nickname)} />
          </Row>
          <Row>
            <StaticField label='Situação' value={renderValue(authority_situation)} />
          </Row>
          <Row>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={6}>
                <StaticField label='Número do Documento' value={renderValue(number)} />
              </Grid>
              <Grid item xs={6}>
                <StaticField label='Valor da Multa' value={renderValue(formatCurrency(fine_amount, true))} />
              </Grid>
            </Grid>
          </Row>
        </div>

        <LocationRow>
          <StaticField
            label='Localização'
            value={`${renderValue(address)} - ${renderValue(city)} - ${renderValue(state)}`}
          />
          {hasLocation ? (
            <CustomMapContainer center={location} zoom={14}>
              <TileLayer url={TITLE_LAYER_URL} />
              <Marker position={location} icon={customIcon}>
                <Popup>{`${renderValue(address)} - ${renderValue(city)} - ${renderValue(state)}`}</Popup>
              </Marker>
            </CustomMapContainer>
          ) : null}
        </LocationRow>
      </div>

      <Divider title='Responsável' />

      <Row className={classes.inputGroup}>
        <Grid container spacing={12} alignItems='center'>
          <Grid item xs={4}>
            <FormToggleGroup
              disabled={!hasModuleAccess}
              value={responsibility}
              onChange={handleChangeResponsability}
              options={responsabilityOptions}
              fixedWidth={140}
            />
          </Grid>

          <Grid item xs={8} alignItems='center'>
            {responsibility === 'transportador' && (
              <ResponsibleToggle>
                <FormSelect
                  disabled={!hasModuleAccess}
                  id='offending-driver'
                  label='Transportadora Responsável'
                  value={carrierSelected}
                  onChange={handleChangeCarrier}
                  options={carrierOptions}
                />

                <Tooltip title='Adicionar novo' placement='left'>
                  <IconButton disabled={!hasModuleAccess} size='small' color='primary' onClick={handleToggleAddCarrier}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </ResponsibleToggle>
            )}
          </Grid>
        </Grid>
      </Row>

      <Divider title='Dados do Transporte' />

      <Row>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={4}>
            <FormText
              disabled={!hasModuleAccess}
              label='Nota Fiscal'
              value={orderService?.number || ''}
              onChange={e => setOrderService({ ...orderService, number: e.target.value })}
            />
          </Grid>

          <Grid item xs={4}>
            <FormText
              disabled={!hasModuleAccess}
              type='date'
              InputLabelProps={{ shrink: true }}
              label='Data emissão NF'
              value={orderService?.date || ''}
              onChange={e => setOrderService({ ...orderService, date: e.target.value })}
            />
          </Grid>

          <Grid item xs={4}>
            <FormText
              disabled={!hasModuleAccess}
              label='Número Transporte'
              value={orderService?.carrierNumber || ''}
              onChange={e => setOrderService({ ...orderService, carrierNumber: e.target.value })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormText
              disabled={!hasModuleAccess}
              label='Outras Informações'
              value={orderService?.additionalInfo || ''}
              onChange={e => setOrderService({ ...orderService, additionalInfo: e.target.value })}
              rows={2}
              multiline
            />
          </Grid>
        </Grid>
      </Row>

      <Divider title='Processo Jurídico' />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormToggleGroup
            disabled={!hasModuleAccess}
            label='Esse processo será encaminhado para análise jurídica?'
            value={legalData?.isWithLegal === null ? '' : String(legalData?.isWithLegal)}
            onChange={handleChangeCanAppeal}
            options={appealOptions}
            fixedWidth={60}
          />
        </Grid>

        <Grid item xs={12}>
          <FormText
            label='Descreva de forma objectiva as evidências identificadas para auxiliar no processo de defesa/recurso'
            value={legalData?.instructions || ''}
            onChange={e => setLegalData({ ...legalData, instructions: e.target.value })}
            rows={2}
            disabled={!legalData?.isWithLegal || !hasModuleAccess}
            fullWidth
            multiline
          />
        </Grid>
      </Grid>

      <Divider title='Processo Financeiro' />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormToggleGroup
            disabled={!hasModuleAccess}
            label='Processo encaminhado ao financeiro?'
            value={financeData?.isWithFinance === null ? '' : String(financeData?.isWithFinance)}
            onChange={handleChangeAdvancedToFinancial}
            options={toFinancialOptions}
            fixedWidth={60}
          />
        </Grid>

        <Grid item xs={4}>
          <FormText
            label='Número Ordem de Pagamento'
            value={financeData?.paymentOrder || ''}
            onChange={e => setFinanceData({ ...financeData, paymentOrder: e.target.value })}
            disabled={!financeData?.isWithFinance || !hasModuleAccess}
          />
        </Grid>

        <Grid item xs={4}>
          <FormText
            type='date'
            InputLabelProps={{ shrink: true }}
            label='Data da Solicitação'
            value={financeData?.requestDate || ''}
            onChange={e => setFinanceData({ ...financeData, requestDate: e.target.value })}
            disabled={!financeData?.isWithFinance || !hasModuleAccess}
          />
        </Grid>

        <Grid item xs={4}>
          <FormText
            type='date'
            InputLabelProps={{ shrink: true }}
            label='Data do Envio'
            value={financeData?.sentDate || ''}
            onChange={e => setFinanceData({ ...financeData, sentDate: e.target.value })}
            disabled={!financeData?.isWithFinance || !hasModuleAccess}
          />
        </Grid>

        <Grid item xs={12}>
          <FormText
            disabled={!hasModuleAccess}
            label='Outras informações'
            value={financeData?.instructions || ''}
            onChange={e => setFinanceData({ ...financeData, instructions: e.target.value })}
            rows={2}
            multiline
          />
        </Grid>
      </Grid>

      <AddCarrier
        isOpen={modalAddCarrierIsOpen}
        setClose={handleToggleAddCarrier}
        setConfirm={handleConfirmAddCarrier}
      />
    </>
  );
}

export default Logistics;
