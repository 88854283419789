import { createUseStyles } from 'react-jss';
import { styled } from '@mui/material/styles';
import { rem } from 'helpers/utils';
import { EFontFamily } from 'helpers/constants';
import { common } from '@mui/material/colors';

const useStyle = createUseStyles({});

export const PaymentSummaryContainer = styled('div')(() => {
  return {
    borderRadius: rem(16),
    border: `1px solid #BAC5CD`,
    width: rem(230),
    color: 'fff',

    backgroundColor: '#DCE5E9',

    '& > div:first-of-type': {
      backgroundColor: '#8897A2',
      '& p': {
        color: '#fff'
      }
    },

    '& p': {
      color: '#8897A2'
    },

    '& svg': {
      marginLeft: 18,
      marginBottom: 5,
      color: '#fff'
    },

    '&.payment-status': {
      backgroundColor: '#FBE9E9',
      border: '1px solid #F1B0B0',

      '& > div:first-of-type': {
        backgroundColor: '#E98282',
        '& p': {
          color: '#fff'
        }
      },

      '& p': {
        color: '#E87B79'
      },

      '& > div:nth-of-type(2)': {
        '& svg': {
          color: '#E77A7A',
          marginTop: 18,
          marginRight: 14
        }
      }
    },

    '&.payment-status-pending': {
      backgroundColor: '#FCF0E4',
      border: '1px solid #EDC096',

      '& > div:first-of-type': {
        backgroundColor: '#E8AE79',
        '& p': {
          color: '#fff'
        }
      },

      '& p': {
        color: '#E8AE79'
      },

      '& > div:nth-of-type(2)': {
        '& svg': {
          color: '#E8AE79',
          marginTop: 18,
          marginRight: 14
        }
      }
    },

    '&.payment-status-concluded': {
      backgroundColor: '#EAEEE8',
      border: '1px solid #BCCDB5',

      '& > div:first-of-type': {
        backgroundColor: '#A2B09D',
        '& p': {
          color: '#fff'
        }
      },

      '& p': {
        color: '#A2B09D'
      },

      '& > div:nth-of-type(2)': {
        '& svg': {
          color: '#A2B09D',
          marginTop: 18,
          marginRight: 14
        }
      }
    }
  };
});

export const PaymentSummaryContent = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  };
});

export const PaymentSummaryTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  borderRadius: rem(14),
  height: rem(80),

  '& .MuiIconButton-sizeMedium': {
    height: 36,
    width: 37,
    borderRadius: 0,
    marginRight: 5,

    '& svg': {
      marginLeft: 0,
      marginBottom: 0,
      color: '#fff'
    }
  },

  '& .title': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',

    '& p': {
      margin: 0,
      fontFamily: EFontFamily.inter,
      color: common.white,
      fontWeight: 400,
      fontSize: rem(14),
      padding: '0 20px'
    }
  }
}));

export const PaymentSummaryAmount = styled('p')(() => ({
  color: '#8897A2',
  fontWeight: 600,
  margin: 0,
  padding: '15px 20px',
  fontFamily: EFontFamily.inter,
  fontSize: rem(22)
}));

export default useStyle;
