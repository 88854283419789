import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Route, Routes } from 'react-router-dom';

import UPLogo from 'assets/images/upfleet_logo.png';
import BGConnected from 'assets/images/bg_connected.png';

import Login from './Login';
import { root, imageBg, mainBox } from './styles';
import ForgotPass from './ForgotPass/index';
import NewPass from './NewPass';
import ResetPass from './ResetPass';

export default function SignIn() {
  return (
    <Container sx={root} maxWidth={false}>
      <img src={BGConnected} alt='Imagem de fundo com pontos conectados' style={imageBg} />

      <Box sx={mainBox}>
        <img src={UPLogo} alt='UP Fleet Logo' style={{ marginBottom: 40 }} />

        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/esqueci-minha-senha' element={<ForgotPass />} />
          <Route path='/nova-senha' element={<NewPass />} />
          <Route path='/redefinir-senha' element={<ResetPass />} />
        </Routes>
      </Box>
    </Container>
  );
}
