import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from './api';
import { Options } from './filters';

type objError = {
  data: null;
  message: string;
  status: string;
};

export type Carrier = {
  id?: number;
  name: string;
  cnpj?: number;
  address?: string;
  neighborhood?: string;
  city_id?: number;
  cep?: number;
  active?: boolean;
};

export function getCarrierOptions(callback: (data: Options) => void, handleError: (errorMsg: string) => void) {
  api
    .get(`/selectOptions/carriers`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      handleError(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function addCarrier(
  newCarrier: Carrier,
  callback: (data: Carrier) => void,
  handleError: (errorMsg: string) => void
) {
  api
    .post(`carriers`, newCarrier)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      handleError(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}
