import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AdminPage from 'components/Pages/Admin';
import AuthContext, { valueData } from 'components/Providers/Auth';
import { User } from 'services/auth';

function PermissionRoute() {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext) as valueData;

  useEffect(() => {
    const storagedToken = localStorage.getItem('TK');

    if (!token && !storagedToken) navigate('/login');

    try {
      const userSerialized = localStorage.getItem('user') || '';
      const currentUser: User = JSON.parse(userSerialized);

      if (currentUser.first_login) navigate('/login/nova-senha');
    } catch (e) {
      console.log((e as Error).message);
    }
  }, [navigate, token]);

  return <AdminPage />;
}

export default PermissionRoute;
