import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormText from 'components/Shared/FormFields/FormText';
import React, { useState } from 'react';

import { Carrier } from 'services/carriers';

type Props = {
  isOpen: boolean;
  setClose: () => void;
  setConfirm: (data: Carrier) => void;
};

function AddCarrier({ isOpen, setClose, setConfirm }: Props) {
  const [name, setName] = useState<string | undefined>();
  const [document, setDocument] = useState<string | undefined>();

  const onAddCarrier = () => {
    const newCarrier: Carrier = {
      name: name || '',
      cnpj: Number(document)
    };
    setConfirm(newCarrier);
  };

  return (
    <Dialog maxWidth='sm' open={isOpen} onClose={setClose}>
      <DialogTitle>Adicionar transportadora</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormText label='Nome' value={name} onChange={e => setName(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <FormText
              type='number'
              inputProps={{ maxLength: 14 }}
              label='CNPJ'
              value={document}
              onChange={e => setDocument(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={setClose}>Fechar</Button>
        <Button variant='contained' onClick={onAddCarrier} autoFocus disableElevation>
          Informar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCarrier;
