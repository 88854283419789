import React, { useCallback, useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Divider from 'components/Shared/Divider/index';
import Loader from 'components/Shared/Loader';
import { DateTime } from 'luxon';
import AuthContext, { valueData } from 'components/Providers/Auth';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import FormText from 'components/Shared/FormFields/FormText';

import { User } from 'services/auth';
import { infraction } from 'services/antt';
import { addAscription, ascription, getAscriptions } from 'services/antt/ascriptions';
import useStyle from './styles';
import Item from './Item';

function Ascriptions(props: { infractionData: infraction }) {
  const classes = useStyle();
  const { user } = useContext(AuthContext) as valueData;

  let storageUser: User | null = null;
  try {
    storageUser = JSON.parse(localStorage.getItem('user') || '') as User;
  } catch (e) {
    console.log((e as Error).message);
  }

  const { infractionData } = props;
  const infractionId = infractionData?.id;

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [ascriptions, setAscriptions] = useState<ascription[]>([]);
  const [newDescription, setNewDescription] = useState('');

  const fetchAscriptions = useCallback(() => {
    setIsFetching(true);

    getAscriptions(
      infractionId,
      (data: ascription[]) => {
        setIsFetching(false);
        setAscriptions(data);
      },
      (error: string) => {
        setIsFetching(false);
        console.error(error);
      }
    );
  }, [infractionId]);

  const onAdd = () => {
    const newData = {
      reported_by: user?.name || storageUser?.name,
      description: newDescription,
      ts: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss')
    };

    addAscription(
      infractionId,
      newData,
      () => fetchAscriptions(),
      (error: string) => console.error(error)
    );

    setNewDescription('');
  };

  useEffect(() => fetchAscriptions(), [fetchAscriptions]);

  return (
    <>
      <div>
        <Divider title='Relatos' noMargin icon={<TurnedInIcon />} />
      </div>

      <div className={classes.description}>
        <p>Escreva aqui um relato que você ache importante registrar, para futuras consultas!</p>
      </div>

      <div className={classes.rootInput}>
        <FormText
          className={classes.input}
          value={newDescription}
          label='Descrição'
          variant='outlined'
          size='small'
          rows={2}
          fullWidth
          multiline
          onChange={e => setNewDescription(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
        />

        <Button size='small' variant='text' aria-label='add' onClick={() => onAdd()} className={classes.button}>
          Adicionar
        </Button>
      </div>

      {ascriptions.length > 0 && (
        <div className={classes.history}>
          <Divider title='Histórico' noMargin />
        </div>
      )}

      {isFetching ? (
        <Loader />
      ) : (
        <div className={classes.ascriptionsList}>
          {ascriptions.map((ascription, index) => (
            <Item
              key={ascription.id}
              data={{ ...ascription, reported_by_system: ascription.source === 'system' }}
              isLast={index === ascriptions.length - 1}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default Ascriptions;
