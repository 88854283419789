import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

export const CustomFormControl = styled(FormControl)(({ theme }) => ({
  '& label:not(.Mui-focused)': {
    fontSize: 13
  },
  '& label.Mui-focused': {
    fontSize: '.8rem'
  },
  '& > div': {
    fontSize: 13,
    lineHeight: '150%',
    minHeight: 20
  },
  '&:hover fieldset': {
    border: `thin solid ${theme.palette.primary.main}`
  }
}));

export const CustomMenuItem = styled(MenuItem)(() => ({
  fontSize: 13
}));
