import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import { createUseStyles } from 'react-jss';

export const CustomToggleButton = styled(ToggleButton)(() => ({
  height: 40,
  padding: '7px 10px',
  '&.Mui-selected': {
    backgroundColor: '#759884',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#759884',
      opacity: '.7'
    }
  }
}));

const useStyle = createUseStyles({
  label: {
    fontSize: 13,
    paddingRight: 10
  }
});

export default useStyle;
