import React from 'react';
import { getOnlyDate, getOnlyTime } from 'helpers/date';
import useStyle from './styles';

type Props = {
  data: {
    id: number;
    description: string;
    reported_by: string;
    ts: string;
    reported_by_system: boolean;
  };
  isLast: boolean;
};

function Item({ data, isLast }: Props) {
  const { id, description, reported_by, ts, reported_by_system = false } = data;
  const classes = useStyle();

  return (
    <blockquote key={id} className={classes.root}>
      <div key={id} className={classes.timeline}>
        <div className={!isLast ? classes.timelineMiddle : ''}>
          <div className={classes.timelineCircle}>
            <span>{getOnlyDate(ts)}</span>
          </div>
          <div className={classes.description}>
            <p>
              <span>{reported_by_system ? 'Sistema: ' : 'Usuário: '}</span>
              {description}
            </p>
            <p>
              {reported_by} às {getOnlyTime(ts)}
            </p>
          </div>
        </div>
      </div>
    </blockquote>
  );
}

export default Item;
