import moment from 'moment';

export function isSameDay(a: any, b: any) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date() && a.month() === b.month() && a.year() === b.year();
}

export const months = [
  { id: 1, label: 'Janeiro', value: '1' },
  { id: 2, label: 'Fevereiro', value: '2' },
  { id: 3, label: 'Março', value: '3' },
  { id: 4, label: 'Abril', value: '4' },
  { id: 5, label: 'Maio', value: '5' },
  { id: 6, label: 'Junho', value: '6' },
  { id: 7, label: 'Julho', value: '7' },
  { id: 8, label: 'Agosto', value: '8' },
  { id: 9, label: 'Setembro', value: '9' },
  { id: 10, label: 'Outubro', value: '10' },
  { id: 11, label: 'Novembro', value: '11' },
  { id: 12, label: 'Dezembro', value: '12' }
];

export const generateYears = (initialDate = '2015-01-01') => {
  const dateStart = moment(initialDate, 'YYYY-MM-DD');
  const dateEnd = moment();
  const years = [];

  while (dateEnd > dateStart || dateStart.format('YYYY') === dateEnd.format('YYYY')) {
    years.push(dateStart.format('YYYY'));
    dateStart.add(1, 'year');
  }
  return years;
};

export const years = (() => {
  const ye = generateYears();
  return ye.map(y => ({ id: y, label: y, value: y }));
})();

const dateOptions = {
  timeZone: 'UTC'
};

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('pt-BR', dateOptions);
};

export const formatDateTime = (date: string) => {
  return new Date(date).toLocaleString('pt-BR');
};

export const formatTime = (date: string) => {
  return new Date(date).toLocaleTimeString('pt-BR', { timeStyle: 'short' });
};

export const getDateRelativeToToday = (format: boolean, months = 0, days = 0): string => {
  const date = new Date();
  date.setMonth(date.getMonth() + months);
  date.setDate(date.getDate() + days);

  if (format) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return date.toISOString();
};

export const getOnlyDate = (date: string): string => {
  const dateArray = date.split(' ');
  return dateArray[0].split('-').reverse().join('/');
};

export const getOnlyTime = (date: string): string => {
  const dateArray = date.split(' ');
  return dateArray[1].split(':').slice(0, 2).join(':');
};

export const getFirstDayOfYear = (year: number) => {
  const date = new Date(year, 0, 1);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export function getLastDayOfYear(year: number) {
  const date = new Date(year, 11, 31);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function getYesterday() {
  const today = new Date();

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const day = String(yesterday.getDate()).padStart(2, '0');
  const month = String(yesterday.getMonth() + 1).padStart(2, '0');
  const year = yesterday.getFullYear();

  return `${year}-${month}-${day}`;
}
