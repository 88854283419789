import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from '../api';
import type { objError } from '../antt';

export type Attach = {
  id: number;
  infraction_id: number;
  filename: string;
  description: string;
  uploaded_by: string;
  storage_path: string;
  authority_id: number;
  created_at: string;
  deleted_at: string;
  updated_at: string;
};

export function uploadAttach(callback: (data: Attach) => void, infractionId: number, data: FormData) {
  api
    .upload(`/antt/infraction/${infractionId}/attach/upload`, data)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function getAttachments(callback: (attachments: Attach[]) => void, infractionId: number) {
  api
    .get(`/antt/infraction/${infractionId}/attachments`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function updateAttach(callback: () => void, infractionId: number, data: any) {
  api
    .patch(`/antt/infraction/${infractionId}/attach`, data)
    .then(() => {
      callback();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function deleteAttach(callback: () => void, infractionId: number, attachId: number) {
  api
    .delete(`/antt/infraction/${infractionId}/attach/${attachId}`)
    .then(() => {
      callback();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function downloadAttach(infractionId: number, attachId: number, attachFilename: string, callback?: () => void) {
  api
    .download(`/antt/infraction/${infractionId}/attach/${attachId}`)
    .then(response => {
      const blob = new Blob([response]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';
      link.download = `${attachFilename}`;
      link.click();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    })
    .finally(() => callback && callback());
}
