import type { AxiosError } from 'axios';
import api from './api';

type objError = {
  data: null;
  message: string;
  status: string;
};

export type DashboardUrls = {
  legal: string;
  logistic: string;
  financial: string;
};

export type DashboadSettings = {
  org_id: number;
  base_url: string;
  dashboards: DashboardUrls;
};

export type AccessGroup = {
  id: number;
  name: string;
  display_name: string;
  dashboard_settings: DashboadSettings;
};

export type Permission = {
  id: number;
  name: string;
};

export const ROLE = {
  ADMIN: 'administrator',
  LOGISTIC: 'operational',
  FINANCIAL: 'finance',
  LEGAL: 'legal',
  LEGAL_EXTERNAL: 'legal-external',
  MANAGER: 'manager'
};

export type Role = {
  id: number;
  name: string;
  permissions: Permission[];
};

export type User = {
  id: number;
  name: string;
  email: string;
  first_login: boolean;
  profile_id: number;
  access_groups: AccessGroup[];
  roles: Role[];
  // access_group_id: number;
};

export function login(
  data: unknown,
  callback: (token: string, user: User) => void,
  handleError: (errorMsg: string) => void
) {
  api
    .post('/login', data)
    .then(result => {
      const { token, user } = result.data;

      callback(token, user);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      const defaultMessage =
        'Ops! Houve algum erro. Tente novamente mais tarde ou contate o administrador se o erro persistir';

      handleError(errorData?.message || defaultMessage);
    });
}

export function forgotPassword(data: unknown, callback: () => void, handleError: (errorMsg: string) => void) {
  api
    .post('/forgot-password', data)
    .then(() => {
      callback();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      const defaultMessage =
        'Ops! Houve algum erro. Tente novamente mais tarde ou contate o administrador se o erro persistir';

      handleError(errorData?.message || defaultMessage);
    });
}

export function resetPassword(data: unknown, callback: () => void, handleError: (errorMsg: string) => void) {
  api
    .post('/reset-password', data)
    .then(() => {
      callback();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      const defaultMessage =
        'Ops! Houve algum erro. Tente novamente mais tarde ou contate o administrador se o erro persistir';

      handleError(errorData?.message || defaultMessage);
    });
}
