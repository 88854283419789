import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const CustomTextField = styled(TextField)(() => ({
  '& label:not(.Mui-focused)': {
    fontSize: 13
  },
  '& input, & textarea': {
    fontSize: 13,
    lineHeight: '150%',
    minHeight: 20
  }
}));
