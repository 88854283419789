import React from 'react';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { AMOUNT_PAID } from 'helpers/constants';
import texts from 'texts/payment-summary';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StatusPayment } from 'helpers/types';

import { PaymentSummaryAmount, PaymentSummaryContainer, PaymentSummaryContent, PaymentSummaryTitle } from './styles';

const options = ['Informar pagamento'];
type Props = {
  title: string;
  amount: string | number;
  statusPayment: string;
  onReportPayment: () => void;
  icon?: JSX.Element;
  showEditValue?: boolean;
  tutorial: string;
};

type TooltipTexts = {
  unpaid: string;
  pending: string;
  paid: string;
};

const statusMapping: Record<StatusPayment, { statusClass: string; icon: JSX.Element }> = {
  unpaid: {
    statusClass: 'payment-status',
    icon: <HighlightOffRoundedIcon />
  },
  pending: {
    statusClass: 'payment-status-pending',
    icon: <ErrorOutlineRoundedIcon />
  },
  paid: {
    statusClass: 'payment-status-concluded',
    icon: <TaskAltRoundedIcon />
  }
};

const ITEM_HEIGHT = 48;

function PaymentSummary({ title, amount, icon, statusPayment, onReportPayment, showEditValue, tutorial }: Props) {
  const { statusClass, icon: statusIcon } = statusMapping[statusPayment as StatusPayment];
  const tooltipTitle = texts.Tooltip[statusPayment as keyof TooltipTexts];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onReportPayment();
  };

  return (
    <PaymentSummaryContainer
      aria-label='Payment Summary'
      className={title === AMOUNT_PAID ? statusClass : 'payment-summary'}
    >
      <PaymentSummaryTitle>
        <div className='title'>
          {icon ?? <SavingsRoundedIcon />}
          <p>{title}</p>
        </div>
        {title === AMOUNT_PAID && (
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleClick}
            className={tutorial}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch'
            }
          }}
        >
          {options.map(option => (
            <MenuItem key={option} onClick={handleClose} disableRipple disabled={!showEditValue}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </PaymentSummaryTitle>
      <PaymentSummaryContent>
        <PaymentSummaryAmount>{amount}</PaymentSummaryAmount>
        {title === AMOUNT_PAID && (
          <Tooltip title={tooltipTitle} placement='bottom'>
            {statusIcon}
          </Tooltip>
        )}
      </PaymentSummaryContent>
    </PaymentSummaryContainer>
  );
}

export default React.memo(PaymentSummary);
