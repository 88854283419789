import React from 'react';
import { CustomTypography } from './styles';

interface IProps {
  title: string;
}

export default function TopPage({ title }: IProps) {
  return (
    <div>
      <CustomTypography variant='h1'>{title}</CustomTypography>
    </div>
  );
}
