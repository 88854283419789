import React from 'react';

import TextField from '@mui/material/TextField';

import useStyle, { CustomAutocomplete } from './styles';

type Option = {
  value: string;
  label: string;
};

type Props = {
  id: string;
  label: string;
  value: string;
  options: Array<Option>;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  groupBy?: (option: unknown) => string;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: unknown) => void;
  onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

function FormAutoComplete({ id, label, value, options, disabled, error, helperText, groupBy, onChange }: Props) {
  const classes = useStyle();

  const [inputValue, setInputValue] = React.useState('');

  const getOptionLabel = (option: any) => option?.label ?? option;

  return (
    <CustomAutocomplete
      // multiple
      fullWidth
      disabled={disabled}
      id={id}
      value={value}
      inputValue={inputValue}
      options={options}
      groupBy={groupBy}
      onChange={onChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions
      limitTags={1}
      size='small'
      renderInput={params => <TextField {...params} label={label} error={error} helperText={helperText} />}
      renderOption={(props, option: any) => (
        <li {...props}>
          <span className={classes.menuItem}>{option.label}</span>
        </li>
      )}
    />
  );
}

export default FormAutoComplete;
