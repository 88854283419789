import Switch from '@mui/material/Switch';
import React from 'react';

import { CustomFormControlLabel } from './styles';

type Props = {
  label: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

function FormSwitch({ label, checked, disabled, handleOnChange }: Props) {
  return (
    <CustomFormControlLabel
      control={<Switch defaultChecked={checked || false} disabled={disabled || false} onChange={handleOnChange} />}
      label={label}
    />
  );
}

export default FormSwitch;
