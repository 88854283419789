import React from 'react';
import { Title } from './styles';

type Props = {
  title: string;
  noMargin?: boolean;
  icon?: React.ReactNode;
  className?: string;
};

function Divider({ title, noMargin = false, icon = '', className, ...props }: Props) {
  return (
    <Title {...props} className={className}>
      <div className='title-content'>
        <div>{icon}</div>
        <p>{title}</p>
      </div>
      <div>
        <hr style={noMargin ? { marginTop: 0 } : {}} />
      </div>
    </Title>
  );
}

export default Divider;
