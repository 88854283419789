import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from './api';
import { Options } from './filters';

type objError = {
  data: null;
  message: string;
  status: string;
};

type licensePlateOwner = {
  responsable_cnpj: string;
  responsable_name: string;
};

type licensePlateOwnerData = {
  status: string;
  message: null | string;
  data: licensePlateOwner[];
};

export function getLicensePlateOwnersOptions(callback: (data: Options) => void) {
  api
    .get(`/selectOptions/license_plate_owners`)
    .then((result: licensePlateOwnerData) => {
      const { data } = result;

      const options: Options = [];

      data.forEach(l => {
        let label = l.responsable_cnpj || l.responsable_name;

        if (l.responsable_cnpj && l.responsable_name) {
          label = `${l.responsable_cnpj} - ${l.responsable_name}`;
        }

        options.push({
          value: l.responsable_cnpj || l.responsable_name,
          label,
          data: l
        });
      });

      callback(options);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}
