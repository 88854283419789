import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { formatCurrency } from 'helpers/formats';
import { DEFAULT_AMOUNT, DISCOUNT_TITLE, FEE_TITLE, RESIDUAL_TITLE } from 'helpers/constants';
import text from '../../../../../../texts/list-payment-detail';
import { Amount, ContentItem, Item, List, Title } from './style';
import { ListPaymentDetailProps } from '../../Logistics/constants';

type Props = {
  items: ListPaymentDetailProps[];
} & React.HTMLAttributes<HTMLUListElement>;

function ListPaymentDetail({ items, ...props }: Props) {
  const getBackGroundColor = (title: string): string | undefined => {
    switch (title) {
      case DISCOUNT_TITLE:
        return 'discount';
      case FEE_TITLE:
        return 'fee';
      case RESIDUAL_TITLE:
        return 'residual';
      default:
        return undefined;
    }
  };

  const shouldApplyLineThrough = (item: ListPaymentDetailProps): boolean => {
    const discountValue = parseFloat(item.amount) || 0;
    return !item.isDiscountApplied && discountValue > 0 && item.title === 'Desconto/Abatimento';
  };

  const formatValue = (item: ListPaymentDetailProps): string => {
    if (item.title === DISCOUNT_TITLE) {
      return formatCurrency(item.amount, true) ?? `R$ ${DEFAULT_AMOUNT}`;
    }
    return item.amount;
  };

  return (
    <List {...props}>
      {items.map(item => (
        <Item
          key={item.title}
          className={getBackGroundColor(item.title)}
          style={shouldApplyLineThrough(item) ? { textDecoration: 'line-through' } : {}}
        >
          <ContentItem>
            <Title>{item.title}:</Title>
            <Amount>{formatValue(item)}</Amount>

            {shouldApplyLineThrough(item) && (
              <Tooltip title={text.Tooltip.title} placement='top' arrow disableInteractive>
                <IconButton color='primary' size='small'>
                  <ErrorRoundedIcon style={{ fontSize: 17, color: '#96949b' }} />
                </IconButton>
              </Tooltip>
            )}
          </ContentItem>
        </Item>
      ))}
    </List>
  );
}

export default React.memo(ListPaymentDetail);
