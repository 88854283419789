export type UnionToType<U extends Record<string, unknown>> = {
  [K in U extends unknown ? keyof U : never]: U extends unknown ? (K extends keyof U ? U[K] : never) : never;
};

export type StatusPayment = 'unpaid' | 'pending' | 'paid';

export interface Locale {
  close: string;
  back?: string;
  last?: string;
  next?: string;
  open?: string;
  skip?: string;
}

export interface DashboardSettings {
  org_id: number;
  base_url: string;
  dashboards: {
    legal: string;
    logistic: string;
    financial: string;
  };
}
export interface Permission {
  id: number;
  name: string;
}

export interface Role {
  id: number;
  access_group_id: number | null;
  name: string;
  permissions: Permission[];
}
export interface AccessGroup {
  id: number;
  name: string;
  display_name: string;
  dashboard_settings: DashboardSettings;
}

export interface User {
  id: number;
  name: string;
  email: string;
  first_login: boolean;
  tutorial: {
    financial: boolean;
  };
  profile_id: number;
  access_groups: AccessGroup[];
  roles: Role[];
}

export enum InvoiceStatus {
  WaitingForInvoice = 'Aguardando boleto',
  InvoiceAvailable = 'Boleto disponível',
  InvoiceUpdated = 'Boleto atualizado',
  InvoicePaid = 'Boleto pago',
  InvoiceConcluded = 'Boleto quitado'
}

export type StepType = {
  label: string;
  date: string | null;
  active: boolean;
};

export type FastFilterType = {
  name: string | null;
  page?: number;
  rowsPerPage?: number;
};
