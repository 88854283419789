/* eslint-disable no-bitwise */
import { Accordion, styled } from '@mui/material';
import { theme } from 'components/Layout/ThemeProvider';
import { EFontFamily } from 'helpers/constants';
import { createUseStyles } from 'react-jss';

const isYellow = (hex: string) => hex === '#f4e184';

const hexToRgb = (hex: any) => {
  const sanitizedHex = hex.replace(/^#/, '');

  const r = parseInt(sanitizedHex.substring(0, 2), 16);
  const g = parseInt(sanitizedHex.substring(2, 4), 16);
  const b = parseInt(sanitizedHex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
};

const darkenColor = (hexColor: string, factor: number) => {
  if (!isYellow(hexColor)) {
    return '#fff';
  }
  const rgbString = hexToRgb(hexColor);

  const [r, g, b] = rgbString.split(',').map(Number);

  const darkerR = Math.max(0, Math.floor(r * factor));
  const darkerG = Math.max(0, Math.floor(g * factor));
  const darkerB = Math.max(0, Math.floor(b * factor));

  const darkerHex = `#${((darkerR << 16) | (darkerG << 8) | darkerB).toString(16).padStart(6, '0')}`;

  return darkerHex;
};

export const BadgeItem = styled(Accordion)(({ color }) => ({
  color: `${darkenColor(color as string, 0.6)}`,
  backgroundColor: color,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'none',
  width: 210,
  padding: '5px 10px',
  fontSize: 12,
  fontWeight: `${isYellow(color as string) ? 500 : 300}`,
  borderRadius: '6px !important',
  fontFamily: EFontFamily.inter
}));

export const StatusText = {
  width: 18,
  height: 18,
  backgroundColor: 'none',
  color: '#fff'
};

export const IconStyle = {
  width: 18,
  height: 18,
  color: '#919191'
};

export const CnpjNumber = {
  fontWeight: 'bold',
  color: '#54585a'
};

export const ClientName = {
  fontSize: 12,
  color: '#54585a'
};
export const ViewButton = {
  padding: '6px 10px 10px 10px',
  borderRadius: 8,
  border: '1px solid #c1c1c1'
};

export const useStyle = createUseStyles({
  datatableWrapper: {
    position: 'relative',
    '& [role="toolbar"]': {
      flexDirection: 'row-reverse',
      '& > div:first-child': {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& > div': {
          border: '1px solid #cacaca',
          borderRadius: 50,
          padding: '0 8px'
        },
        '& div::before': {
          display: 'none'
        },
        '& div::after': {
          display: 'none'
        }
      },
      '& button': {
        display: 'none'
      }
    },
    '& table': {
      fontSize: 14,
      lineHeight: '100%',
      border: 'none',
      borderRadius: 5,
      borderBottom: 'none',
      '& thead': {
        '& th': {
          color: '#fff',
          textTransform: 'uppercase',
          fontWeight: 400,
          backgroundColor: 'transparent',
          padding: '12px 16px',
          '&:first-child': {
            borderRadius: '10px 0px 0px 0px'
          },
          '&:last-child': {
            borderRadius: '0px 10px 0px 0px'
          },
          '& > div > span': {
            color: '#fff',
            padding: 0,
            '&[class*=checked], &[class*=indeterminate]': {
              color: '#fff'
            }
          }
        },
        '& button': {
          fontSize: 'inherit',
          padding: 'inherit',
          minWidth: 'unset',
          '& div, & svg': {
            color: '#fff !important'
          }
        }
      },
      '& tbody': {
        borderLeft: '#fdf5f5 thin solid',
        borderRight: '#fdf5f5 thin solid',
        '& tr': {
          borderBottom: '#f1f1f1 thin solid',
          '&.mui-row-selected': {
            backgroundColor: 'rgb(237 233 249)'
          },
          '&:hover td': { background: '#eceef3' },
          '&:nth-child(even)': { background: '#f8f6ff' }
        },
        '& td': {
          padding: 8,
          color: '#54585a',
          position: 'relative',
          '&:first-child': {
            textAlign: 'center',
            paddingLeft: 16
          },
          '& > div > span': {
            padding: 0,
            color: theme.palette.secondary.light,
            '&[class*=checked], &[class*=indeterminate]': {
              color: '#9e99b7'
            }
          }
        }
      },
      '&.check-row': {
        '& tr': {
          cursor: 'pointer',
          '&.selected': {
            '& td': {
              backgroundColor: theme.palette.success.light,
              color: '#fff'
            }
          }
        }
      },
      '& .sign': {
        width: 10,
        height: 10,
        display: 'block',
        borderRadius: '50%',
        margin: 'auto',
        '&.active': { backgroundColor: theme.palette.success.main },
        '&.inactive': { backgroundColor: '#ccc' }
      }
    },
    '& > div > div': {
      paddingLeft: 0,
      paddingRight: 0
    },
    '& [class*="emptyTitle"]': {
      fontSize: 13,
      padding: '24px 0'
    },
    '& [class*="navContainer"] p': {
      fontSize: 12
    },
    '& [aria-label="Search"]': {
      fontSize: 13,
      minHeight: 25
    }
  }
});
