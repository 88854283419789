import React from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Clear from '@mui/icons-material/Clear';

import type { SelectChangeEvent } from '@mui/material/Select';
import { CustomFormControl, CustomMenuItem } from './styles';

type Options = Array<{
  value: string;
  label: string;
}>;

type Props = {
  id: string;
  label: string;
  value: string;
  options: Options;
  disabled?: boolean;
  onChange: (event: SelectChangeEvent, child: React.ReactNode) => void;
  onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

function FormSelect({ id, label, value, options, disabled, onChange, onClear }: Props) {
  return (
    <CustomFormControl fullWidth size='small' variant='outlined'>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        disabled={disabled}
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
        endAdornment={
          <IconButton sx={{ display: value ? '' : 'none' }} onClick={onClear}>
            <Clear fontSize='small' />
          </IconButton>
        }
        sx={{
          '& .MuiSelect-iconOutlined': { display: value ? 'none' : '' },
          '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' }
        }}
      >
        {options?.map(option => {
          return (
            <CustomMenuItem key={option.value} value={option.value}>
              {option.label}
            </CustomMenuItem>
          );
        })}
      </Select>
    </CustomFormControl>
  );
}

export default FormSelect;
