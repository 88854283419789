import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { EFontFamily } from 'helpers/constants';

export const CustomAppBar = styled(AppBar)(({ theme }) => ({
  background: '#fff',
  padding: '10px',
  height: '60px',
  flexDirection: 'row',
  color: 'unset',
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  borderBottom: `1px solid #e9e9e9`,
  '& > .greetingText': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1,
    justifyContent: 'space-evenly',
    alignItems: 'end',
    marginRight: '20px',
    paddingRight: '20px',
    fontFamily: EFontFamily.exo,
    position: 'relative',
    '&::after': {
      content: '""',
      width: '1px',
      height: '75%',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      backgroundColor: '#888'
    }
  },
  '& big': {
    fontSize: '16px'
  },
  '& small': {
    fontSize: '12px'
  },
  '& strong': {
    color: theme.palette.primary.main
  },
  '& .header-logo': {
    maxHeight: 40,
    padding: '5px 20px'
  }
}));
