import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from '../api';

type objError = {
  data: null;
  message: string;
  status: string;
};

export type ascription = {
  id: number;
  source: string;
  reported_by: string;
  ts: string;
  description: string;
  color?: string;
  created_at: string;
};

export function getAscriptions(
  infractionId: number,
  callback: (data: ascription[]) => void,
  handleError: (errorMsg: string) => void
) {
  api
    .get(`/antt/infraction/${infractionId}/history`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      handleError(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function addAscription(
  infractionId: number,
  data: any,
  callback: (data: ascription) => void,
  handleError: (errorMsg: string) => void
) {
  api
    .post(`/antt/infraction/${infractionId}/history`, data)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      handleError(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}
