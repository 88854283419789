import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';

import useStyles, { CustomToggleButton } from './styles';

type Options = Array<{
  value: string;
  label: string;
}>;

type Props = {
  label?: string;
  value: string;
  options: Options;
  fixedWidth?: number;
  disabled?: boolean;
  onChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
};

function FormToggleGroup({ label, value, options, fixedWidth, disabled, onChange }: Props) {
  const classes = useStyles();

  return (
    <>
      {label && <span className={classes.label}>{label}</span>}

      <ToggleButtonGroup color='secondary' value={value} size='small' exclusive onChange={onChange}>
        {options?.map(option => {
          return (
            <CustomToggleButton
              disabled={disabled}
              sx={{ width: fixedWidth || 'unset' }}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </CustomToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
}

export default FormToggleGroup;
