/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, Method } from 'axios';

import { API_ENDPOINT } from '../settings';

axios.defaults.withCredentials = true;

export class ApiService {
  public get<T = any>(url: string, params?: any, respType?: any): Promise<T> {
    return this.request<T>('GET', url, params, respType);
  }

  public post<T = any>(url: string, body: any): Promise<T> {
    return this.request<T>('POST', url, body);
  }

  public put<T = any>(url: string, body: any): Promise<T> {
    return this.request<T>('PUT', url, body);
  }

  public patch<T = any>(url: string, body: any): Promise<T> {
    return this.request<T>('PATCH', url, body);
  }

  public delete<T = any>(url: string, params?: any): Promise<T> {
    return this.request<T>('DELETE', url, params);
  }

  public upload<T = any>(url: string, data: FormData): Promise<T> {
    return this.request<T>('POST', url, data);
  }

  public download<T = any>(url: string): Promise<T> {
    return this.request<T>('GET', url, null, 'blob');
  }

  private async request<T = any>(method: Method, url: string, data?: any, respType?: any): Promise<T> {
    try {
      const requestHeaders = {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('TK')}`,
        Accept: data instanceof FormData ? 'multipart/form-data' : 'application/json'
      };

      const request = axios.request({
        baseURL: API_ENDPOINT,
        url,
        method,
        headers: requestHeaders,
        params: method === 'GET' ? data : null,
        data: method === 'POST' || method === 'PUT' || method === 'PATCH' ? data : null,
        responseType: respType ?? null
      });

      const response = await request;

      return response.data || {};
    } catch (err: any) {
      if (err.response.status === 401) {
        localStorage.removeItem('TK');
        if (window.location.pathname !== '/login') {
          window.location.href = '/';
        }
      }

      return this.handleError<T>(err);
    }
  }

  private async handleError<T>(err: AxiosError): Promise<T> {
    if (!err.config || !err.response) throw err;
    throw err;
  }
}

const apiService = new ApiService();
export default apiService;
