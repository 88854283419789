import { createUseStyles } from 'react-jss';

export const useStyle = createUseStyles({
  footer: {
    '& .MuiSelect-select': {
      padding: '6px 12px',
      minWidth: '10px',
      fontSize: '13px',
      lineHeight: '150%',
      color: 'inherit',
      backgroundColor: 'transparent',
      borderRadius: '4px',
      '&:focus': {
        border: 'none',
        boxShadow: 'none'
      }
    },
    '& .MuiTableRow-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '100%'
    },
    '& .MuiTableCell-root': {
      border: 'none'
    },
    '& .MuiInputBase-root': {
      margin: '0 10px'
    },
    '& .MuiButtonBase-root': {
      padding: 0,
      marginBottom: 3
    },
    '& .page-number': {
      margin: '0 14px',
      fontSize: '14px',
      cursor: 'pointer'
    },
    '& .MuiIconButton-root': {
      color: 'rgb(180 169 169 / 54%)'
    },
    '& .page-active': {
      color: '#fff',
      border: '1px solid #5e5b73',
      padding: '5px 16px',
      borderRadius: '4px',
      fontWeight: 400,
      backgroundColor: '#696391',

      '&:hover': {
        backgroundColor: '#5b567f'
      }
    },
    '& .row-count-number': {
      fontWeight: 'bold'
    }
  }
});
