/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import { UnionToType } from 'helpers/types';
import { useStyle } from './styles';
import CustomFooter from './custom-footer';

type filtersObject = {
  searchText: string;
};

type paginationObject = {
  page: number;
  count: number;
  rowsPerPage: number;
  onTableChange: (action: string, tableState: MUIDataTableState) => void;
};

type Props = {
  columns: MUIDataTableColumnDef[];
  data: any;
  optionsObj: UnionToType<filtersObject | paginationObject>;
};

function DataTable({ columns, data, optionsObj }: Props) {
  const classes = useStyle();
  const [selectedRowsText, setSelectedRowsText] = React.useState<string>('');

  const customFooterComponent = (
    rowCount: number,
    page: number,
    rowsPerPage: number,
    changeRowsPerPage: (page: string | number) => void,
    changePage: (newPage: number) => void
  ) => (
    <CustomFooter
      rowCount={rowCount}
      page={page}
      rowsPerPage={rowsPerPage}
      changeRowsPerPage={changeRowsPerPage}
      changePage={changePage}
    />
  );

  const options: MUIDataTableOptions = {
    onRowSelectionChange: (rowsSelected: any[], allRowsSelected: any[]) => {
      const selectedItemCount = allRowsSelected.length;
      const pluralMessage = 'itens foram selecionados';
      const singularMessage = 'item foi selecionado';

      setSelectedRowsText(`${selectedItemCount > 1 ? pluralMessage : singularMessage}`);
    },
    customFooter: customFooterComponent,
    elevation: 0,
    filterType: 'checkbox',
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    serverSide: true,
    searchAlwaysOpen: true,
    searchPlaceholder: 'Buscar',
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado!'
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página',
        displayRows: 'de',
        jumpToPage: 'ir para página'
      },
      filter: {
        all: 'Todos os registros',
        title: 'Filtros',
        reset: 'Limpar'
      },
      selectedRows: {
        delete: 'Apagar',
        deleteAria: 'Apagar registros selecionados',
        text: selectedRowsText
      }
    },
    ...optionsObj
  };

  return (
    <div className={classes.datatableWrapper}>
      <MUIDataTable title='' columns={columns} data={data} options={options} />
    </div>
  );
}

export default DataTable;
