import React, { useEffect, useState } from 'react';
import DataTable from 'components/Shared/Table';
import Divider from 'components/Shared/Divider';
import FormAutoComplete from 'components/Shared/FormFields/FormAutoComplete';
import FormText from 'components/Shared/FormFields/FormText';
import Loader from 'components/Shared/Loader';
import TopPage from 'components/Shared/TopPage';
import { Card, CardContent, CircularProgress, Collapse, Grid, IconButton, Tooltip } from '@mui/material';
import { client, getClients } from 'services/clients';
import { createUser } from 'services/user';
import { MUIDataTableState } from 'mui-datatables';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import { User } from 'services/auth';
import { getAccessGroupProfileOptions, getRoleOptions, Options, OptionsRole } from 'services/filters';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import useStyles, { SubmitButton } from './styles';

type OptionsObjProps = {
  searchText: string;
  page: number;
  count: number;
  rowsPerPage: number;
  onTableChange: (action: string, tableState: MUIDataTableState) => void;
  search?: boolean;
  selectableRows?: boolean;
};

type InvalidAccessGroupProps = {
  access_group?: boolean;
  profile?: boolean;
  role?: boolean;
};

type AccessGroupProps = {
  invalid: InvalidAccessGroupProps;
  access_group: any;
  role: any;
  profile: any;
  profileList: Options;
  roleList: OptionsRole;
  showDataClients?: boolean;
  dataClients: client[];
  loadingDataClients?: boolean;
  pagePagination: number;
  perPagePagination: number;
  countPagination: number;
};

type UserProps = {
  name: string;
  email: string;
  accessGroup: AccessGroupProps[];
};

type InvalidProps = {
  name?: boolean;
  email?: boolean;
};

function UserNew() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles(theme)();
  const currentUser: User = JSON.parse(localStorage.getItem('user') || '');
  const [invalid, setInvalid] = useState<InvalidProps>({});
  const [isSubmitting, setSubmitting] = useState(false);
  const defaultUserData: UserProps = {
    name: '',
    email: '',
    accessGroup: [
      {
        invalid: {},
        access_group: {
          value: currentUser.access_groups[0].id,
          label: currentUser.access_groups[0].name
        },
        role: '',
        profile: '',
        profileList: [],
        roleList: [],
        showDataClients: false,
        dataClients: [],
        loadingDataClients: false,
        pagePagination: 1,
        perPagePagination: 10,
        countPagination: 0
      }
    ]
  };

  const [user, setUser] = useState<UserProps>({
    ...defaultUserData
  });

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'cnpj',
      label: 'CNPJ',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: false
      }
    }
  ];

  const loadAccessGroupProfiles = (index: number, access_group_id = '') => {
    getAccessGroupProfileOptions((options: Options) => {
      const accessGroup: AccessGroupProps[] = [...user.accessGroup];
      accessGroup[index].profileList = options;

      setUser({ ...user, accessGroup });
    }, access_group_id);
  };

  const loadRoles = (index: number, access_group_id = '') => {
    getRoleOptions((options: OptionsRole) => {
      const accessGroup: AccessGroupProps[] = [...user.accessGroup];
      accessGroup[index].roleList = options;

      setUser({ ...user, accessGroup });
    }, access_group_id);
  };

  const handleFilterClients = (index: number) => {
    const access_group_id = user.accessGroup[index].access_group?.value || '';
    const access_group_profile_id = user.accessGroup[index].profile?.value || '';
    return `&access_group_id=${access_group_id}&access_group_profile_id=${access_group_profile_id}`;
  };

  const handleSuccessClients = (data: client[], newCount: number, index: number) => {
    console.log(data, index);
    const accessGroup = [...user.accessGroup];
    accessGroup[index].dataClients = data;
    accessGroup[index].countPagination = newCount;
    accessGroup[index].loadingDataClients = false;

    setUser({ ...user, accessGroup });
  };

  const fetchData = (index: number, perPage?: number, page?: number, search?: string, filters?: string) => {
    const accessGroup = [...user.accessGroup];
    accessGroup[index].loadingDataClients = true;
    setUser({ ...user, accessGroup });

    getClients((data: client[], newCount: number) => handleSuccessClients(data, newCount, index), {
      perPage: perPage || 10,
      page: page || 1,
      search: search || '',
      filters: filters || ''
    });
  };

  const loadDataClients = (index: number) => {
    const accessGroup = [...user.accessGroup];
    if (accessGroup[index].access_group || accessGroup[index].profile) {
      fetchData(index, accessGroup[index].perPagePagination, 1, '', handleFilterClients(index));
    } else {
      accessGroup[index].dataClients = [];
      accessGroup[index].countPagination = 0;
      accessGroup[index].pagePagination = 1;
      accessGroup[index].showDataClients = false;

      setUser({ ...user, accessGroup });
    }
  };

  const handleValidateAccessGroups = (index?: number, prop?: keyof InvalidAccessGroupProps) => {
    let accessGroup = [...user.accessGroup];
    let hasError = false;

    if (index !== undefined && prop) {
      accessGroup[index].invalid[prop] = !accessGroup[index][prop];
      hasError = !accessGroup[index][prop];
    } else {
      accessGroup = accessGroup.map(a => {
        const validation: InvalidAccessGroupProps = {};

        if (!a.access_group) {
          validation.access_group = true;
        }
        if (!a.profile) {
          validation.profile = true;
        }
        if (!a.role) {
          validation.role = true;
        }

        hasError = Object.keys(validation).length > 0;

        return {
          ...a,
          invalid: validation
        };
      });
    }

    setUser({ ...user, accessGroup });

    return hasError;
  };

  const handleChangeAccessGroup = (value: any, prop: keyof AccessGroupProps, index: number) => {
    const accessGroup = [...user.accessGroup];
    accessGroup[index][prop] = value;

    if (prop === 'access_group') {
      if (value) {
        loadAccessGroupProfiles(index, value.value);
        loadRoles(index, value.value);
      } else {
        accessGroup[index].profileList = [];
        accessGroup[index].roleList = [];
      }

      accessGroup[index].profile = '';
      accessGroup[index].role = '';
    }

    if (['access_group', 'profile'].includes(prop)) {
      loadDataClients(index);
    }

    setUser({ ...user, accessGroup });
  };

  const onChangePage = (page: number, index: number) => {
    const accessGroup = [...user.accessGroup];
    accessGroup[index].pagePagination = page;
    setUser({ ...user, accessGroup });

    fetchData(index, accessGroup[index].perPagePagination, page, '');
  };

  const onChangeRowsPerPage = (rowsPerPage: number, index: number) => {
    const accessGroup = [...user.accessGroup];
    accessGroup[index].perPagePagination = rowsPerPage;
    setUser({ ...user, accessGroup });

    fetchData(index, rowsPerPage, accessGroup[index].pagePagination, '');
  };

  const handleOptionsObj = (index: number) => {
    const optionsObj: OptionsObjProps = {
      searchText: '',
      page: user.accessGroup[index].pagePagination,
      count: user.accessGroup[index].countPagination,
      rowsPerPage: user.accessGroup[index].perPagePagination,
      search: false,
      selectableRows: false,
      onTableChange: (action: string, tableState: MUIDataTableState) => {
        switch (action) {
          case 'changePage':
            return onChangePage(tableState.page, index);
          case 'changeRowsPerPage':
            return onChangeRowsPerPage(tableState.rowsPerPage, index);
          case 'search':
            return console.log('search');
          default:
            return console.info(`action (${action}) not handled.`);
        }
      }
    };

    return optionsObj;
  };

  const handleValidate = (input?: keyof InvalidProps) => {
    let validation: InvalidProps = {};
    if (input) {
      validation = { ...invalid };
      validation[input] = false;
    }

    if ((!user.name || !user.name.trim()) && (!input || input === 'name')) {
      validation.name = true;
    }
    if (
      (!user.email || !user.email.trim() || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(user.email)) &&
      (!input || input === 'email')
    ) {
      validation.email = true;
    }

    setInvalid(validation);

    let hasError = false;

    if (!input) {
      hasError = handleValidateAccessGroups();
    }

    if ((Object.keys(validation).length > 0 && !input) || hasError) {
      enqueueSnackbar('Os campos marcados com * são obrigatórios', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      });

      return false;
    }

    return true;
  };

  const handleRequestData = () => {
    return {
      name: user.name,
      email: user.email,
      access_groups: user.accessGroup.map(accessGroup => ({
        id: accessGroup.access_group.value,
        profile_id: accessGroup.profile.value,
        role_id: accessGroup.role.value
      }))
    };
  };

  const handleSuccess = () => {
    setSubmitting(false);
    enqueueSnackbar('Usuário cadastrado com sucesso!', {
      variant: 'success',
      anchorOrigin: { vertical: 'top', horizontal: 'right' }
    });

    setUser({ ...defaultUserData });
    setInvalid({});
  };

  const handleError = (errorMsg: string) => {
    setSubmitting(false);
    enqueueSnackbar(errorMsg, {
      variant: 'error',
      anchorOrigin: { vertical: 'top', horizontal: 'right' }
    });
  };

  const handleSubmit = () => {
    if (!handleValidate()) {
      return false;
    }

    setSubmitting(true);
    return createUser(handleRequestData(), handleSuccess, handleError);
  };

  useEffect(() => {
    loadAccessGroupProfiles(0, `${currentUser.access_groups[0].id}`);
    loadRoles(0, `${currentUser.access_groups[0].id}`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TopPage title='Novo Usuário' />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Divider title='Dados do usuário' />
              <div className={classes.tabsContainer}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <FormText
                      label='* Nome'
                      value={user.name}
                      onChange={e => setUser({ ...user, name: e.target.value })}
                      error={invalid.name}
                      helperText={invalid.name ? 'Informe um nome válido' : ''}
                      onKeyUp={() => handleValidate('name')}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormText
                      type='email'
                      inputMode='email'
                      label='* E-mail'
                      value={user.email}
                      onChange={e => setUser({ ...user, email: e.target.value })}
                      error={invalid.email}
                      helperText={invalid.email ? 'Informe um e-mail válido' : ''}
                      onKeyUp={() => handleValidate('email')}
                    />
                  </Grid>
                </Grid>
              </div>
              <Divider title='Grupos de Acesso' />
              <div className={classes.tabsContainer}>
                <Grid container spacing={2}>
                  {user.accessGroup.map((accessGroup, index) => (
                    <>
                      <Grid item md={11} xs={11}>
                        <Grid container spacing={2}>
                          <Grid item md={4} xs={12}>
                            <FormAutoComplete
                              disabled
                              id='access_group'
                              label='* Grupo de Acesso'
                              value={accessGroup.access_group}
                              options={[]}
                              onChange={(_, newValue) => {
                                handleChangeAccessGroup(newValue, 'access_group', index);
                                handleValidateAccessGroups(index, 'access_group');
                              }}
                              error={accessGroup.invalid.access_group}
                              helperText={accessGroup.invalid.access_group ? 'Selecione um grupo de acesso válido' : ''}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormAutoComplete
                              disabled={!accessGroup.access_group}
                              id='profile'
                              label='* Perfil de Acesso'
                              value={accessGroup.profile}
                              options={accessGroup.profileList}
                              onChange={(_, newValue) => {
                                handleChangeAccessGroup(newValue, 'profile', index);
                                handleValidateAccessGroups(index, 'profile');
                              }}
                              error={accessGroup.invalid.profile}
                              helperText={accessGroup.invalid.profile ? 'Selecione um perfil de acesso válido' : ''}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormAutoComplete
                              disabled={!accessGroup.access_group}
                              id='role'
                              label='* Nível de Acesso'
                              value={accessGroup.role}
                              groupBy={(option: any) => option.type}
                              options={accessGroup.roleList}
                              onChange={(_, newValue) => {
                                handleChangeAccessGroup(newValue, 'role', index);
                                handleValidateAccessGroups(index, 'role');
                              }}
                              error={accessGroup.invalid.role}
                              helperText={accessGroup.invalid.role ? 'Selecione um nível de acesso válido' : ''}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={1} xs={1}>
                        {accessGroup.access_group && (
                          <Tooltip
                            title={`${accessGroup.showDataClients ? 'Ocultar' : 'Exibir'} Clientes`}
                            placement='left'
                          >
                            <IconButton
                              color='primary'
                              onClick={() =>
                                handleChangeAccessGroup(!accessGroup.showDataClients, 'showDataClients', index)
                              }
                            >
                              {accessGroup.showDataClients ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item md={12}>
                        <Collapse in={accessGroup.showDataClients}>
                          <div className={classes.tabsContainer}>
                            <Divider title='Clientes Vinculados ao Grupo/Perfil de Acesso' />
                            <Grid container>
                              <Grid item md={12}>
                                {accessGroup.loadingDataClients ? (
                                  <Loader />
                                ) : (
                                  <DataTable
                                    columns={columns}
                                    data={accessGroup.dataClients}
                                    optionsObj={handleOptionsObj(index)}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </Collapse>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>

              <div className={classes.bottomButtons} style={{ marginTop: 50 }}>
                <SubmitButton color='secondary' variant='contained' onClick={handleSubmit} disableElevation>
                  {isSubmitting ? <CircularProgress size={16} /> : 'Cadastrar'}
                </SubmitButton>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UserNew;
