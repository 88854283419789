import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { createUseStyles } from 'react-jss';

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  '& label:not(.Mui-focused), & input': {
    fontSize: 13
  },
  '& label.Mui-focused': {
    fontSize: '.8rem'
  },
  '& .MuiAutocomplete-tag': {
    maxWidth: '50%'
  }
}));

const useStyle = createUseStyles({
  menuItem: {
    fontSize: 13
  }
});

export default useStyle;
