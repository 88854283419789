import { common } from '@mui/material/colors';
import { EFontFamily } from 'helpers/constants';
import { rem } from 'helpers/utils';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  ascriptionsList: {
    paddingTop: 35,
    overflowY: 'auto',
    paddingLeft: 4,

    '@media (max-width: 1800px)': {
      paddingLeft: 20
    }
  },
  description: {
    '& p': {
      margin: 0,
      fontSize: 14,
      fontFamily: EFontFamily.poppins,
      color: '#484545',
      fontWeight: 300,
      padding: rem(4)
    }
  },
  info: {
    margin: 0,
    fontSize: 1,
    lineHeight: '1.6',
    color: '#8a8a8a',
    overflowWrap: 'break-word'
  },
  rootInput: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'flex-start'
  },
  input: {
    margin: '20px 0',

    '& .MuiFormLabel-root': {
      fontSize: '16px !important',
      fontFamily: EFontFamily.poppins,
      fontWeight: '500 !important',
      color: '#424242'
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: '#696391'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    backgroundColor: '#888EA2',
    padding: '8px 22px',
    color: common.white,
    fontFamily: EFontFamily.poppins,
    fontSize: 12,
    borderRadius: 6,
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: '#797F92'
    }
  },
  history: {
    '& p': {
      fontSize: 14,
      marginTop: 20
    }
  }
});

export default useStyle;
