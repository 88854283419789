import { useEffect, useRef, useState } from 'react';
import { FINANCIAL_TUTORIAL_STEP } from 'helpers/constants';
import { tutorialStepEmmiter } from '../../components/Pages/Admin/event/eventEmitter';

const useTutorialStepEmitter = () => {
  const [tutorialStep, setTutorialStep] = useState<number>(0);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    const handleTutorialStep = (number: number) => {
      if (isMounted.current) {
        setTutorialStep(number);
      }
    };

    tutorialStepEmmiter.on(FINANCIAL_TUTORIAL_STEP, handleTutorialStep);

    return () => {
      isMounted.current = false;
      tutorialStepEmmiter.off(FINANCIAL_TUTORIAL_STEP, handleTutorialStep);
    };
  }, [tutorialStep]);

  return tutorialStep;
};

export default useTutorialStepEmitter;
