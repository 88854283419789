import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from './api';

type objError = {
  data: null;
  message: string;
  status: string;
};

export type Option = {
  value: string;
  label: string;
  data?: any;
};

export type OptionRole = Option & {
  type: string;
};

export type Options = Array<Option>;
export type OptionsRole = Array<OptionRole>;

export function getClientOptions(callback: (data: Options) => void) {
  api
    .get(`/selectOptions/clients`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function getAuthorities(callback: (data: Options) => void) {
  api
    .get(`/selectOptions/authorities`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

function getAnttInfrationAttributeOptions(callback: (data: Options) => void, attribute: string) {
  api
    .get(`/antt/selectOptions/infractions/${attribute}`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function getAccessGroupOptions(callback: (data: Options) => void) {
  api
    .get(`/selectOptions/access_groups`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function getAccessGroupProfileOptions(callback: (data: Options) => void, access_group_id = '') {
  api
    .get(`/selectOptions/access_groups/profiles?access_group_id=${access_group_id}`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function getRoleOptions(callback: (data: OptionsRole) => void, access_group_id = '') {
  api
    .get(`/selectOptions/roles?access_group_id=${access_group_id}`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function getAnttInfractionTypeOptions(callback: (data: Options) => void) {
  getAnttInfrationAttributeOptions(callback, 'type');
}

export function getAnttInfractionSubtypeOptions(callback: (data: Options) => void) {
  getAnttInfrationAttributeOptions(callback, 'subtype');
}

export function getAnttInfractionStatusOptions(callback: (data: Options) => void) {
  getAnttInfrationAttributeOptions(callback, 'status');
}

export function getAnttAuthoritySituationOptions(callback: (data: Options) => void) {
  getAnttInfrationAttributeOptions(callback, 'authority_status');
}

export function getAnttInvoiceStatusOptions(callback: (data: Options) => void) {
  getAnttInfrationAttributeOptions(callback, 'invoice_status');
}
