import React from 'react';
import type { TextFieldProps } from '@mui/material/TextField';
import { CustomTextField } from './styles';

function FormText({
  id,
  label,
  variant,
  rows,
  multiline,
  type,
  value,
  disabled,
  onChange,
  InputLabelProps,
  ...rest
}: TextFieldProps) {
  return (
    <CustomTextField
      id={id}
      disabled={disabled}
      label={label}
      variant={variant}
      InputLabelProps={InputLabelProps}
      type={type}
      onChange={onChange}
      value={value}
      size='small'
      multiline={!!multiline}
      rows={rows}
      fullWidth
      {...rest}
    />
  );
}

export default FormText;
