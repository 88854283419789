import { rem } from 'helpers/utils';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';
import { MapContainer } from 'react-leaflet';

export const Row = styled('div')(() => ({
  padding: `${rem(15)} 0`
}));

export const LocationRow = styled('div')(() => ({
  padding: `${rem(15)} 0 0 ${rem(50)}`,
  flex: 1
}));

export const CustomTooltip = styled(Tooltip)(() => ({
  marginLeft: `${rem(4)}`
}));

export const ResponsibleToggle = styled('div')(() => ({
  paddingTop: 7,
  display: 'flex',
  alignItems: 'center'
}));

export const CustomMapContainer = styled(MapContainer)(() => ({
  border: '1px solid #D9D9D9',
  borderRadius: 10,
  height: '30vh',
  width: '100%',
  marginTop: rem(18),

  '& .leaflet-control-attribution.leaflet-control': {
    display: 'none'
  },

  '& .leaflet-control-zoom': {
    border: 'none',

    '& a.leaflet-control-zoom-in': {
      borderTopLeftRadius: rem(17),
      borderTopRightRadius: rem(17),
      height: rem(34),

      '& span': {
        color: '#595959'
      }
    },

    '& a.leaflet-control-zoom-out': {
      borderBottomLeftRadius: rem(17),
      borderBottomRightRadius: rem(17),
      height: rem(34),

      '& span': {
        color: '#595959'
      }
    }
  }
}));

const useStyle = () => {
  return createUseStyles({
    inputGroup: {
      '& fieldset': {
        borderRadius: rem(10)
      }
    }
  });
};

export default useStyle;
