import React, { useCallback, useEffect, useState } from 'react';
import FormText from 'components/Shared/FormFields/FormText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import {
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden
} from '@mui/material';

import { infraction } from 'services/antt';
import { Attach, deleteAttach, updateAttach, downloadAttach } from 'services/antt/attachments';
import { ROLE, User } from 'services/auth';
import { useTheme } from '@mui/material/styles';
import { formatDate, formatTime } from 'helpers/date';
import useStyle from './styles';
import { CustomLoadingButton } from '../styles';

const AUTO_SAVE_DEBOUNCE_MS = 1500;

type props = {
  file: Attach;
  infraction: infraction;
};

function FileItem({ file, infraction }: props) {
  const theme = useTheme();
  const classes = useStyle(theme)();

  const [editDescriptionDialog, setEditDescriptionDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isRequestingDownload, setIsRequestingDownload] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [description, setDescription] = useState<string>('');

  const userSerialized = localStorage.getItem('user') || '';
  const currentUser: User = JSON.parse(userSerialized);
  const userRole = currentUser.roles[0].name;

  const canDeleteFile = userRole === ROLE.ADMIN || currentUser.name === file.uploaded_by;
  const shouldDisableDescriptionInput = userRole !== ROLE.ADMIN && currentUser.name !== file.uploaded_by;

  const createDate = formatDate(file.created_at);
  const createTime = formatTime(file.created_at);

  const toggleEditDescriptionDialog = () => setEditDescriptionDialog(isOpen => !isOpen);
  const toggleDeleteDialog = () => setDeleteDialog(isOpen => !isOpen);

  const handleConfirmDelete = () => {
    setIsDeleting(true);
    deleteAttach(
      () => {
        setIsDeleting(false);
        setDeleteDialog(false);
        setDeleted(true);
      },
      file.infraction_id,
      file.id
    );
  };

  const handleUpdate = () => console.log('Description updated');

  const handleDownload = () => {
    setIsRequestingDownload(true);
    downloadAttach(infraction.id, file.id, file.filename, () => setIsRequestingDownload(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const autoSaveChanges = useCallback(
    debounce(async data => {
      await updateAttach(handleUpdate, file.infraction_id, { id: file.id, description: data });
    }, AUTO_SAVE_DEBOUNCE_MS),
    []
  );

  useEffect(() => {
    autoSaveChanges(description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    setDescription(file.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (deleted) return <Hidden />;

  return (
    <div className={classes.root}>
      <a className={classes.downloadLink} onClick={handleDownload} href='#download'>
        <div className={classes.info}>
          {isRequestingDownload && <CircularProgress size={16} />}
          <span className={classes.name}>{`${file.filename} - `}</span>
          <small>{`Por ${file.uploaded_by} em ${createDate} às ${createTime}`}</small>
          {description && (
            <span className={classes.description}>
              <small>Descrição do Arquivo: {description}</small>
            </span>
          )}
        </div>
      </a>

      <div className={classes.actions}>
        {!shouldDisableDescriptionInput && (
          <Tooltip title='Editar descrição' placement='left'>
            <IconButton color='primary' onClick={toggleEditDescriptionDialog}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

        {canDeleteFile && (
          <Tooltip title='Excluir' placement='left'>
            <IconButton color='primary' onClick={toggleDeleteDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <Dialog
        fullWidth
        maxWidth='xs'
        open={editDescriptionDialog}
        onClose={toggleEditDescriptionDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Editar descrição do arquivo</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <div>
              <p>
                <small>
                  <strong>Arquivo:</strong>
                </small>
                <br />
                <small>{file.filename}</small>
              </p>
            </div>
            <FormText
              disabled={shouldDisableDescriptionInput}
              type='text'
              label='Descrição'
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomLoadingButton
            variant='contained'
            color='primary'
            onClick={toggleEditDescriptionDialog}
            loading={isDeleting}
          >
            Confirmar
          </CustomLoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth='xs'
        open={deleteDialog}
        onClose={toggleDeleteDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Confirma a exclusão do anexo?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{file.filename}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeleteDialog} disabled={isDeleting} autoFocus>
            Não
          </Button>
          <CustomLoadingButton variant='contained' color='primary' onClick={handleConfirmDelete} loading={isDeleting}>
            Sim
          </CustomLoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FileItem;
