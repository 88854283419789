import React, { useCallback, useEffect, useState } from 'react';
import {
  downloadAnttInfraction,
  getInfraction,
  infraction,
  infractionPatchData,
  updateInfraction
} from 'services/antt';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Tab from '@mui/material/Tab';
import TopPage from 'components/Shared/TopPage';
import Loader from 'components/Shared/Loader';
import { BILL_PAID, EMPTY_FIELD_VALUE, FINANCIAL_TUTORIAL_STEP } from 'helpers/constants';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import UserTutorial from 'components/Shared/UserTutorial';
import { TrafficTicketDetailTutorial } from 'texts/tutorial';

import { cnpjMask } from 'helpers/mask';
import { usePaymentStatusEmitter } from 'hooks';
import { User } from '../../../../helpers/types';
import Financial from './Financial';
import Attachments from './Attachments';
import Ascriptions from './Ascriptions';
import Legal from './Legal';
import Logistics from './Logistics';
import { TABS } from './constants';
import useStyles, { CardCustom, CardHeader, CustomTabs, SubmitButton, TopItem, TopTitle } from './styles';
import AlertPayment from './Financial/AlertPayment';

import { tutorialStepEmmiter } from '../event/eventEmitter';

interface RenderFieldProps {
  label: string;
  value: string | null;
}

function RenderField({ label, value }: RenderFieldProps) {
  return (
    <div>
      <p>
        <span>{label}</span> {value ?? EMPTY_FIELD_VALUE}
      </p>
    </div>
  );
}

function TrafficTicketDetail() {
  const theme = useTheme();
  const classes = useStyles(theme)();
  const { trafficTicketId } = useParams();

  const LOGISTICS_TAB = 0;
  const LEGAL_TAB = 1;
  const FINANCIAL_TAB = 2;
  const ATTACHMENTS_TAB = 3;

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [currTab, setCurrTab] = useState<number>(0);
  const [infraction, setInfraction] = useState<infraction>({} as infraction);
  const [infractionPathData, setInfractionPathData] = useState<infractionPatchData>({} as infractionPatchData);
  const [downloadingInfraction, setDownloadingInfraction] = useState<boolean>(false);
  const [isActiveTutorial, setIsActiveTutorial] = useState<boolean>(false);

  const newPaymentStatus = usePaymentStatusEmitter();

  const handleSuccess = (newData: infraction) => {
    setInfraction(newData);
    const details = JSON.parse(newData.details || '{}');
    setInfractionPathData({
      os_number: newData.os_number,
      os_date: newData.os_date,
      os_carrier_number: newData.os_carrier_number,
      os_additional_info: newData.os_additional_info,
      legal_assignment_date: newData.legal_assignment_date,
      legal_instructions: newData.legal_instructions,
      legal_is_with_legal: newData.legal_is_with_legal,
      legal_additional_info: newData.legal_additional_info,
      finance_assignment_date: newData.finance_assignment_date,
      finance_is_with_finance: newData.finance_is_with_finance,
      finance_payment_order: newData.finance_payment_order,
      finance_request_date: newData.finance_request_date,
      finance_sent_date: newData.finance_sent_date,
      finance_instructions: newData.finance_instructions,
      finance_additional_info: newData.finance_additional_info,
      carrier_name: details.transportadora,
      responsible: details.responsavel
    });
    setIsFetching(false);
  };

  const fetchData = useCallback(() => {
    setIsFetching(true);
    getInfraction(handleSuccess, Number(trafficTicketId));
  }, [trafficTicketId]);

  useEffect(() => {
    return fetchData();
  }, [fetchData]);

  const changedInfractionData = (data: Partial<infractionPatchData>) => {
    setInfractionPathData({
      ...infractionPathData,
      ...data
    });
  };

  const updateInfractionData = () => {
    setIsFetching(true);

    updateInfraction(
      Number(trafficTicketId),
      infractionPathData,
      () => fetchData(),
      (error: string) => {
        setIsFetching(false);
        console.error(error);
      }
    );
  };

  const handleChangTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrTab(newValue);
  };

  const { number, type, status, nickname, id, extrato_situacao, invoice_status, cnpj } = infraction;

  const handleDownloadAuto = () => {
    setDownloadingInfraction(true);

    downloadAnttInfraction(id, number, () => {
      setDownloadingInfraction(false);
    });
  };

  const getStatusPayment = () => {
    if (extrato_situacao === BILL_PAID) return 'paid';

    if (newPaymentStatus === 'pending' || invoice_status === 'Pago') return 'pending';

    return 'unpaid';
  };

  const statusPayment = getStatusPayment();

  const stepsTutorial = TrafficTicketDetailTutorial.steps;
  const handleOnFinish = () => {
    setTimeout(() => {
      tutorialStepEmmiter.emit(FINANCIAL_TUTORIAL_STEP, 1);
    }, 0);

    setCurrTab(2);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const actualUser: User = storedUser ? JSON.parse(storedUser) : null;

    setIsActiveTutorial(actualUser.tutorial.financial);
  }, []);

  return (
    <>
      <TopPage title='Multa - Detalhes' />
      {isFetching ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid item xs={9}>
            <UserTutorial
              stepsTutorial={stepsTutorial}
              customLocale={{
                close: 'Vamos lá!',
                last: 'Iniciar tour'
              }}
              run={isActiveTutorial}
              onFinish={() => handleOnFinish()}
            />
            <CardHeader>
              <div className={classes.topInfo}>
                <TopTitle>
                  <div>
                    <h2>Auto de infração</h2>
                    <p>{number ?? EMPTY_FIELD_VALUE}</p>
                  </div>
                  <div>
                    <LoadingButton
                      variant='contained'
                      size='small'
                      disableElevation
                      onClick={handleDownloadAuto}
                      loading={downloadingInfraction}
                      loadingIndicator={<CircularProgress size={16} />}
                      startIcon={downloadingInfraction ? null : <DownloadRoundedIcon />}
                    >
                      {downloadingInfraction ? '' : 'Baixar auto de infração'}
                    </LoadingButton>
                  </div>
                </TopTitle>

                <TopItem>
                  <RenderField label='Status:' value={status} />
                  <RenderField label='Tipo da Multa:' value={type} />
                  <RenderField label='CNPJ:' value={cnpjMask(cnpj)} />
                  <RenderField label='Empresa:' value={nickname} />
                </TopItem>
              </div>
            </CardHeader>

            <CustomTabs value={currTab} onChange={handleChangTab} className='welcome-tutorial'>
              {TABS.map(tab => (
                <Tab key={tab} label={tab} />
              ))}
            </CustomTabs>

            {currTab === FINANCIAL_TAB || statusPayment === 'paid' ? <AlertPayment status={statusPayment} /> : null}

            <CardCustom className={classes.container}>
              <CardContent className={classes.cardContent}>
                <div>
                  <div className={classes.tabsContainer}>
                    {currTab === LOGISTICS_TAB && (
                      <Logistics infractionData={infraction} onChange={changedInfractionData} />
                    )}
                    {currTab === LEGAL_TAB && <Legal infractionData={infraction} onChange={changedInfractionData} />}
                    {currTab === FINANCIAL_TAB && (
                      <Financial
                        infractionData={infraction}
                        onChange={changedInfractionData}
                        paymentStatus={statusPayment}
                      />
                    )}
                    {currTab === ATTACHMENTS_TAB && <Attachments infractionData={infraction} />}
                  </div>

                  <div className={classes.bottomButtons}>
                    <Link to='/multas'>
                      <Button variant='text'>Voltar</Button>
                    </Link>

                    <SubmitButton color='secondary' variant='contained' onClick={updateInfractionData} disableElevation>
                      Salvar
                    </SubmitButton>
                  </div>
                </div>
              </CardContent>
            </CardCustom>
          </Grid>
          <Grid item xs={3}>
            <CardCustom className={classes.ascriptions}>
              <CardContent>{isFetching ? <Loader /> : <Ascriptions infractionData={infraction} />}</CardContent>
            </CardCustom>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TrafficTicketDetail;
