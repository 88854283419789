import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import { IconStyle, CnpjNumber, ClientName, ViewButton, BadgeItem } from 'components/Shared/Table/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { MUIDataTableMeta } from 'mui-datatables';
import { getColorFromString } from 'helpers/utils';

export const columns = [
  {
    name: 'id',
    label: 'id',
    options: {
      filter: false,
      sort: false,
      display: false
    }
  },
  {
    name: 'nickname',
    label: 'Apelido',
    options: {
      filter: false,
      sort: false,
      display: false
    }
  },
  {
    name: 'cnpj',
    label: 'CNPJ',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
        const { rowData } = tableMeta;
        return (
          <>
            <span style={CnpjNumber}>{value}</span>
            <br />
            <span style={ClientName}>{rowData[1]}</span>
          </>
        );
      }
    }
  },
  {
    name: 'number',
    label: 'Auto de Infração',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'process_number',
    label: 'Nº do Processo',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'datetime',
    label: 'Data da Infração',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'type',
    label: 'Tipo',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'subtype',
    label: 'Subtipo',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: string) => {
        const statusColor = getColorFromString(value);

        return (
          <BadgeItem color={statusColor}>
            <span>{value}</span>
          </BadgeItem>
        );
      }
    }
  },
  {
    name: 'actions',
    label: 'Ações',
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
        return (
          <Tooltip title='Visualizar' placement='left-start' style={ViewButton}>
            <Link to={`/multas/${tableMeta.rowData[0]}`}>
              <IconButton aria-label='view'>
                <RemoveRedEyeIcon style={IconStyle} />
              </IconButton>
            </Link>
          </Tooltip>
        );
      }
    }
  }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export type FilterItem = {
  name: string;
  order?: string;
  filter?: unknown;
};

export type ToggleItem = {
  name: string;
};

export const filterByOptions = [
  {
    value: 'occurrence',
    label: 'Data de Ocorrência'
  },
  {
    value: 'due_date',
    label: 'Data de Vencimento'
  }
];

export const filterByPeriodOptions = [
  {
    value: 'today',
    label: 'Hoje'
  },
  {
    value: 'week',
    label: 'Essa semana'
  },
  {
    value: 'month',
    label: 'Esse mês'
  },
  {
    value: 'year',
    label: 'Esse Ano'
  },
  {
    value: '30',
    label: 'Últmos 30 dias'
  },
  {
    value: '365',
    label: 'Últimos 12 meses'
  },
  {
    value: 'custom',
    label: 'Personalizado'
  }
];

export const NEW_INFRACTIONS = 'Infrações nos últimos 30 dias';

export const INVOICES_AVAILABLE = 'Boletos disponíveis';

export const INVOICES_DUE = 'Boletos a vencer em 7 dias';

export const INVOICES_SERASA = 'Boletos na Serasa';

export const INVOICES_EXPIRED = 'Boletos vencidos';

export const FAST_FILTERS_LABEL = [
  NEW_INFRACTIONS,
  INVOICES_AVAILABLE,
  INVOICES_DUE,
  INVOICES_SERASA,
  INVOICES_EXPIRED
];
