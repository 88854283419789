import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { EFontFamily } from 'helpers/constants';
import Warning from '../../../../../../assets/images/warning.gif';

export const WarningImage = styled('div')(() => ({
  backgroundImage: `url(${Warning})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: 170,
  height: 170
}));

export const WarningImageContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const WarningDiologTypography = styled(Typography)(() => ({
  fontFamily: EFontFamily.poppins,
  fontSize: 16,
  color: '#777777',
  fontWeight: 300,
  padding: '15px 8px'
}));

export const WarningDiologTypographyTitle = styled(Typography)(() => ({
  fontFamily: EFontFamily.poppins,
  fontSize: 18,
  color: '#5d5d5d',
  fontWeight: 500
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 20,
    width: 482
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
