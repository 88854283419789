import { Grid, styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: 12,
  margin: '-8px 2px 10px',
  color: theme.palette.error.main
}));

export const CustomGrid = styled(Grid)(() => ({
  '&.MuiGrid-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiGrid-item': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '& .MuiButton-containedPrimary': {
      height: 45,
      borderRadius: 10
    }
  }
}));
