import Loader from 'components/Shared/Loader';
import TopPage from 'components/Shared/TopPage';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboadSettings, ROLE, User } from 'services/auth';

function DashboardLogistic() {
  const navigate = useNavigate();
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [loadCount, setLoadCount] = useState(0);

  const getDashboardUrl = () => {
    try {
      const userSerialized = localStorage.getItem('user') || '';
      const storageUser: User = JSON.parse(userSerialized);

      const dashSettings: DashboadSettings | null = storageUser?.access_groups[0].dashboard_settings || null;
      const logisticDash = dashSettings?.dashboards.logistic || '';
      const userId = storageUser?.id;
      const profileId = storageUser?.profile_id;

      if (storageUser?.roles[0].name === ROLE.LEGAL_EXTERNAL) {
        navigate('/multas');
        return '';
      }

      // eslint-disable-next-line prefer-template
      return logisticDash + '&var-uid=' + userId + '&var-pid=' + profileId;
    } catch (e) {
      navigate('/login');
    }

    return '';
  };

  useEffect(() => {
    setUrl(getDashboardUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopPage title='Dashboard Logística' />

      {loading ? <Loader /> : null}

      <iframe
        role='presentation'
        title='dash-logistica'
        width='100%'
        style={{ border: 'none', height: '200vh', display: loading ? 'none' : '' }}
        src={url}
        sandbox='allow-same-origin allow-scripts'
        loading='eager'
        onLoad={() => {
          if (loadCount > 0) {
            setLoading(false);
          }
          setLoadCount(loadCount + 1);
          // eslint-disable-next-line prefer-template
          setUrl(getDashboardUrl() + '&reload');
        }}
      />
    </>
  );
}

export default DashboardLogistic;
