import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Header from 'components/Layout/Header';
import Sidebar from 'components/Layout/Sidebar';
import { layout } from './style';

function Layout({ children }: Record<string, ReactNode>) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Sidebar />
      <Box component='main' sx={{ flexGrow: 1, padding: '0' }}>
        <Container component='main' maxWidth='xl' style={layout}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}

export default Layout;
