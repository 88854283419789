import { decisionDocument } from 'services/antt/decisions';
import { formatDate } from 'helpers/date';
import { EMPTY_FIELD_VALUE } from 'helpers/constants';
import { infraction } from 'services/antt';

const filter = {
  searchText: ''
};

const pagination = {
  page: 1,
  count: 10,
  rowsPerPage: 10,
  onTableChange: (action: string) => {
    switch (action) {
      case 'changePage':
        return console.log('changePage');
      case 'changeRowsPerPage':
        return console.log('changeRowsPerPage');
      case 'search':
        return console.log('search');
      default:
        return console.info(`action (${action}) not handled.`);
    }
  }
};

export const optionsObj = {
  ...filter,
  ...pagination,
  search: false,
  selectableRows: false
};

export const formatData = (item: decisionDocument, infractionData: infraction) => {
  const { type, date, protocol_date } = item;
  const dateFormatted = date ? formatDate(date) : EMPTY_FIELD_VALUE;
  const protocolDateFormatted = protocol_date ? formatDate(protocol_date) : EMPTY_FIELD_VALUE;

  return {
    ...item,
    document_type: type,
    document_date: dateFormatted,
    protocol_date: protocolDateFormatted,
    view: infractionData.id
  };
};
