const AlertPaymentTutorial = {
  steps: [
    {
      target: '.label-payment-status',
      content: 'Aqui você será notificado pelos principais alertas da infração, como o status do pagamento!'
    }
  ]
};

export default AlertPaymentTutorial;
