import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import { getFilterParams } from 'helpers/utils';
import api from './api';

export type objError = {
  data: null;
  message: string;
  status: string;
};

export type infraction = {
  address: string;
  administrative_process_id: number;
  authority_fullname: string;
  authority_id: number;
  authority_name: string;
  authority_situation: string;
  city: string;
  client_id: number;
  cnpj: string;
  code: string;
  created_at: string;
  datetime: string;
  decisions_count: number;
  description: string;
  details: string;
  fine_amount_discounted: number;
  fine_amount: number;
  id: number;
  infraction_date: string;
  infraction_time: string;
  invoice_amount_discounted: number;
  invoice_amount: number;
  invoice_due_date: string;
  invoice_number: string;
  invoice_protocol: string;
  invoice_status: string;
  invoice_created_at: string;
  invoice_updated_at: string;
  invoice_amount_paid: number;
  invoice_paid_at: string;
  invoice_fee_amount: number;
  invoice_available: boolean;
  invoices_count: number;
  invoice_id: number;
  nickname: string;
  number: string;
  process_date: string;
  process_number: string;
  state: string;
  status: string;
  subtype: string;
  type: string;
  updated_at: string;
  vehicle: string;
  responsible: string;
  os_number: string;
  os_date: string;
  os_carrier_number: string;
  os_additional_info: string;
  legal_assignment_date: string;
  legal_instructions: string;
  legal_is_with_legal?: boolean;
  legal_is_defensible?: boolean;
  legal_additional_info: string;
  finance_assignment_date: string;
  finance_is_with_finance?: boolean;
  finance_payment_order: string;
  finance_request_date: string;
  finance_sent_date: string;
  finance_instructions: string;
  finance_additional_info: string;
  plate_owner_name: string;
  plate_owner_cnpj: string;
  plate_owner_is_third_party: boolean;
  extrato_data_pagamento: string;
  extrato_saldo_residual: number;
  extrato_situacao: string;
  extrato_valor_original: number;
  extrato_valor_pagamento: number;
  valor_multa_mora: number;
  valor_juros: number;
  desconto: number;
  pago_com_desconto: boolean | null;
  desconto_perdido: number;
};

type plateOwnerParam = {
  cnpj: string;
  name: string;
};

export type tableRequest = {
  perPage: number;
  page: number;
  search?: string;
  filters?: string;
  dateRef?: string;
  from?: string;
  to?: string;
  plateOwner?: plateOwnerParam | null;
};

export function getInfractions(callback: (data: infraction[], newCount: number) => void, request: tableRequest) {
  const { perPage, page, search, filters, dateRef, from, to, plateOwner } = request;
  const searchParam = search ? `&search=${search}` : '';
  let periodFilter = dateRef ? `&date_ref=${dateRef}` : '';
  periodFilter += from ? `&from=${from}` : '';
  periodFilter += to ? `&to=${to}` : '';
  let plateOwnerParam = '';

  if (plateOwner) {
    plateOwnerParam += `&plateOwner[cnpj]=${plateOwner.cnpj || ''}`;
    plateOwnerParam += `&plateOwner[name]=${plateOwner.name || ''}`;
  }

  api
    .get(`/antt/infractions?per_page=${perPage}&page=${page}${searchParam}${periodFilter}${filters}${plateOwnerParam}`)
    .then(result => {
      const { data, total } = result;

      callback(data, total);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function downloadInfractions(callback: () => void, request: tableRequest) {
  const { search, filters, dateRef, from, to, plateOwner } = request;
  const searchParam = search ? `&search=${search}` : '';
  let periodFilter = dateRef ? `&date_ref=${dateRef}` : '';
  periodFilter += from ? `&from=${from}` : '';
  periodFilter += to ? `&to=${to}` : '';
  let plateOwnerParam = '';

  if (plateOwner) {
    plateOwnerParam += `&plateOwner[cnpj]=${plateOwner.cnpj || ''}`;
    plateOwnerParam += `&plateOwner[name]=${plateOwner.name || ''}`;
  }

  api
    .download(`/antt/infractions/export?${searchParam}${periodFilter}${filters}${plateOwnerParam}`)
    .then(response => {
      const blob = new Blob([response], { type: 'application/xlsx' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'infractions.xlsx';
      link.click();

      callback();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function downloadInfractionsFastFilter(callback: () => void, searchParams: any) {
  api
    .download(`/antt/infractions/export?${searchParams}`)
    .then(response => {
      const blob = new Blob([response], { type: 'application/xlsx' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'infractions.xlsx';
      link.click();

      callback();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function downloadAnttInfraction(infractionId: number, infractionNumber: string, finishCallback: () => void) {
  api
    .download(`/antt/infraction/${infractionId}/download`)
    .then(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';
      link.download = `ANTT-${infractionNumber}.pdf`;
      link.click();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    })
    .finally(() => {
      finishCallback();
    });
}

export function getInfraction(callback: (data: infraction) => void, infractionId: number) {
  api
    .get(`/antt/infraction/${infractionId}`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export type infractionPatchData = {
  responsible: string;
  carrier_name: string;
  os_number: string;
  os_date: string;
  os_carrier_number: string;
  os_additional_info: string;
  legal_assignment_date: string;
  legal_instructions: string;
  legal_is_with_legal?: boolean;
  legal_is_defensible?: boolean;
  legal_additional_info: string;
  finance_assignment_date: string;
  finance_is_with_finance?: boolean;
  finance_payment_order: string;
  finance_request_date: string;
  finance_sent_date: string;
  finance_instructions: string;
  finance_additional_info: string;
};

export function updateInfraction(
  infractionId: number,
  data: infractionPatchData,
  callback: () => void,
  handleError: (msgError: string) => void
) {
  api
    .patch(`/antt/infraction/${infractionId}`, data)
    .then(() => {
      callback();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      handleError(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function getFinancial(callback: (data: infraction) => void, infractionId: number) {
  api
    .get(`/antt/infraction/${infractionId}/financial`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export async function getTotalAccountsByFilter(filterName: string): Promise<number> {
  const filterParam = getFilterParams(filterName);
  let total: number;

  const totalPromise = new Promise<number>(resolve => {
    const getInfractionTotal = (data: infraction[], newCount: number) => {
      total = newCount;
      resolve(total);
    };

    getInfractions(getInfractionTotal, filterParam);
  });

  return totalPromise;
}
