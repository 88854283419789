import FormText from 'components/Shared/FormFields/FormText';
import React, { useEffect, useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTheme } from '@mui/material/styles';
import { infraction } from 'services/antt';
import { Attach, getAttachments, uploadAttach } from 'services/antt/attachments';
import FileItem from './FileItem';

import useStyles, { CustomLoadingButton } from './styles';

function Attachments(props: { infractionData: infraction }) {
  const theme = useTheme();
  const classes = useStyles(theme)();

  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<Attach[]>([]);

  const { infractionData } = props;

  const handleGetAttachments = (attachments: Attach[]) => {
    setAttachments(attachments);
    setIsUploading(false);
    setSelectedFiles(null);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(e.target.files);
  };

  const uploadFile = () => {
    const formData = new FormData();

    if (!selectedFiles) {
      return;
    }

    setIsUploading(true);
    formData.append('file', selectedFiles[0]);

    uploadAttach(
      () => {
        getAttachments(handleGetAttachments, infractionData.id);
      },
      infractionData.id,
      formData
    );
  };

  const handleSelectFile = () => {
    document.getElementById('inputFile')?.click();
  };

  useEffect(() => {
    getAttachments(handleGetAttachments, infractionData.id);
  }, [infractionData.id]);

  return (
    <div>
      <div className={classes.inputSection}>
        <button type='button' className={classes.inputArea} onClick={handleSelectFile}>
          <UploadFileIcon fontSize='large' />
          <span>{selectedFiles ? selectedFiles[0].name : 'Envie aqui o seu arquivo'}</span>
        </button>
        <CustomLoadingButton
          id='btnSendFile'
          variant='outlined'
          size='medium'
          color='primary'
          disableElevation
          onClick={uploadFile}
          loading={isUploading}
        >
          Adicionar
        </CustomLoadingButton>
      </div>

      <div className={classes.inputHidden}>
        <FormText id='inputFile' type='file' onChange={onFileChange} />
      </div>

      <div className={classes.fileList}>
        {attachments.map(attach => (
          <FileItem key={attach.id} file={attach} infraction={infractionData} />
        ))}
      </div>
    </div>
  );
}

export default Attachments;
