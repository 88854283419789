import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from '../api';
import type { objError } from '../antt';

export function updateInvoice(callback: () => void, invoiceId: number, data: any, handleError?: () => void) {
  api
    .patch(`/antt/infraction/financial/${invoiceId}`, data)
    .then(() => {
      callback();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      handleError?.();
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function downloadInvoice(
  infractionId: number,
  infractionNumber: string,
  invoiceNumber: string,
  finishCallback: () => void
) {
  api
    .download(`/antt/infraction/${infractionId}/financial/invoice`)
    .then(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';
      link.download = `ANTT-${infractionNumber}-${invoiceNumber}.pdf`;
      link.click();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    })
    .finally(() => {
      finishCallback();
    });
}

export function downloadReceiptFile(
  infractionId: number,
  infractionNumber: string,
  invoiceNumber: string,
  finishCallback: () => void
) {
  api
    .download(`/antt/infraction/${infractionId}/financial/receipt`)
    .then(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';
      link.download = `ANTT-${infractionNumber}-${invoiceNumber}.pdf`;
      link.click();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    })
    .finally(() => {
      finishCallback();
    });
}

export async function generateInvoiceFile(infractionId: number) {
  try {
    const response = await api.get(`/antt/infraction/${infractionId}/financial/invoice`, {}, 'blob');
    return response;
  } catch {
    return null;
  }
}

export async function generateReceiptFile(infractionId: number) {
  try {
    const response = await api.get(`/antt/infraction/${infractionId}/financial/receipt`, {}, 'blob');
    return response;
  } catch {
    return null;
  }
}
