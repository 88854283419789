import React, { ElementType } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { checkIcon, closeIcon, text, textDanger, textSuccess } from './styles';

type Props = TypographyProps & {
  isValid: boolean;
  label: string;
  component: ElementType;
};

function TypographyValidate({ isValid, label, ...rest }: Props) {
  return (
    <Typography sx={[text, isValid ? textSuccess : textDanger]} {...rest}>
      {isValid ? <Check sx={checkIcon} /> : <Close sx={closeIcon} />} {label}
    </Typography>
  );
}

export default TypographyValidate;
