import React, { useContext /* , useEffect */ } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import AuthContext, { valueData } from 'components/Providers/Auth';

import { CustomGrid, ErrorMessage } from './styles';
import { FormBox, CustomTextField, CustomInputLabel, linkForgotPass, CustomSubmit } from '../styles';

const initialValues = { access_group: '', email: '', password: '' };

type typeValues = typeof initialValues;

export default function Login() {
  const { token, handleSignIn } = useContext(AuthContext) as valueData;
  const storagedToken = localStorage.getItem('TK');
  const navigate = useNavigate();

  if (!!token || !!storagedToken) navigate('/inicio');

  const onValidate = (values: typeValues) => {
    let errors = {};

    if (!values.access_group) {
      errors = {
        ...errors,
        access_group: 'Obrigatório'
      };
    }

    if (!values.email) {
      errors = {
        ...errors,
        email: 'Obrigatório'
      };
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors = {
        ...errors,
        email: 'Email inválido'
      };
    }

    if (!values.password) {
      errors = {
        ...errors,
        password: 'Obrigatório'
      };
    }

    return errors;
  };

  const onSubmit = (values: typeValues, formikHelpers: FormikHelpers<typeValues>) => {
    const { setSubmitting } = formikHelpers;

    const transformedValues = {
      ...values,
      access_group: values.access_group.toLowerCase()
    };

    handleSignIn(transformedValues, setSubmitting);
  };

  return (
    <>
      <Helmet>
        <title>Acesso - UP Gestão de Frotas</title>
      </Helmet>

      <FormBox>
        <Formik initialValues={initialValues} validate={onValidate} onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='access_group'>
                  Grupo de Acesso
                </CustomInputLabel>
                <CustomTextField
                  id='access_group'
                  name='access_group'
                  autoComplete='access_group'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.access_group}
                  autoFocus
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.access_group && errors.access_group}</ErrorMessage>
              </FormControl>

              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='email'>
                  E-mail
                </CustomInputLabel>
                <CustomTextField
                  id='email'
                  name='email'
                  autoComplete='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.email && errors.email}</ErrorMessage>
              </FormControl>

              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='password'>
                  Senha
                </CustomInputLabel>
                <CustomTextField
                  id='password'
                  autoComplete='password'
                  name='password'
                  type='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.password && errors.password}</ErrorMessage>
              </FormControl>

              <CustomGrid>
                <Grid item>
                  <CustomSubmit
                    disabled={isSubmitting || !!Object.keys(errors).length}
                    type='submit'
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isSubmitting ? <CircularProgress size={16} /> : 'Entrar'}
                  </CustomSubmit>
                </Grid>
                <Grid item xs>
                  <Link component={RouterLink} to='/login/esqueci-minha-senha' sx={linkForgotPass}>
                    Esqueceu sua senha?
                  </Link>
                </Grid>
              </CustomGrid>
            </form>
          )}
        </Formik>
      </FormBox>
    </>
  );
}
