import { CSSProperties } from 'react';

export const text: CSSProperties = {
  fontSize: 13,
  display: 'flex',
  alignItems: 'center',
  margin: '5px 0',
  textAlign: 'center'
};

export const checkIcon: CSSProperties = {
  color: '#84b215',
  fontSize: 13,
  display: 'inline'
};

export const closeIcon: CSSProperties = {
  color: '#F44336',
  fontSize: 13,
  display: 'inline',
  marginRight: '5px'
};

export const textSuccess: CSSProperties = {
  color: '#84b215'
};

export const textDanger: CSSProperties = {
  color: '#F44336'
};
