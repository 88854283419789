import React, { useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import LoadingButton from '@mui/lab/LoadingButton';
import 'react-pdf/dist/Page/TextLayer.css';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import Skeleton from '@mui/material/Skeleton';

import useStyle, { Container } from './style';

type Props = {
  page: number;
  file: any;
  onClick: () => void;
  disabled?: boolean;
  loading: boolean;
  loadingIndicator: JSX.Element;
  label: string;
  className?: string;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function InvoicePreview({ page, file, onClick, disabled, loading, loadingIndicator, label, className }: Props) {
  const classes = useStyle();
  const options = useMemo(() => ({ cMapUrl: 'cmaps/' }), []);

  return (
    <Container label={label} className={className}>
      {file ? (
        <Document file={file} options={options}>
          <Page pageNumber={page} width={500} className={classes.page} scale={1.5} />
        </Document>
      ) : (
        <Skeleton variant='rounded' width={288} height={162} />
      )}

      <LoadingButton
        disabled={disabled}
        variant='contained'
        size='small'
        disableElevation
        onClick={onClick}
        loading={loading}
        loadingIndicator={loadingIndicator}
        className={classes.button}
        endIcon={<SaveAltRoundedIcon />}
      >
        {label}
      </LoadingButton>
    </Container>
  );
}

export default React.memo(InvoicePreview);
