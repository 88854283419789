import React, { ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const themeMui = createTheme({});

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: { backgroundColor: '#fff' }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#696391',
          borderRadius: '10px',
          fontSize: 16,
          padding: 500,
          margin: 500,
          position: 'relative',
          top: 2
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontSize: 16,
          padding: 500,
          margin: 500,
          border: '1px solid #cfcfcf'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [themeMui.breakpoints.up('lg')]: {
            maxWidth: 'none'
          }
        },
        maxWidthXl: {
          [themeMui.breakpoints.up('lg')]: {
            maxWidth: 'none'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '100%'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          position: 'relative',
          zIndex: 100
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          borderColor: '#585858'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          height: 25
        }
      }
    }
  },
  typography: {
    fontFamily: ['"Open Sans"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      light: '#c4d600',
      main: '#696391',
      dark: '#565175'
    },
    secondary: {
      main: '#c4d600',
      light: '#707072',
      dark: '#a7a7a7'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
});

function CustomThemeProvider({ children }: Record<string, ReactNode>) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default CustomThemeProvider;
