export const TABS = ['Logística', 'Jurídico', 'Financeiro', 'Anexos'];

export const responsabilityOptions = [
  {
    value: 'embarcador',
    label: 'Embarcador'
  },
  {
    value: 'transportador',
    label: 'Transportador'
  }
];

export const appealOptions = [
  {
    value: 'false',
    label: 'Não'
  },
  {
    value: 'true',
    label: 'Sim'
  }
];

export const toFinancialOptions = [
  {
    value: 'false',
    label: 'Não'
  },
  {
    value: 'true',
    label: 'Sim'
  }
];

export const carrierOptions = [
  {
    value: '1',
    label: 'Nome da Transportadora'
  }
];
