import { createUseStyles } from 'react-jss';

const lite = '#cad225';
const brand = '#584c96';
const absoluteCommonRules = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto'
};

export const useStyle = createUseStyles({
  container: {
    height: '50vh',
    padding: 10,
    position: 'relative'
  },
  images: {
    ...absoluteCommonRules,
    '& img': absoluteCommonRules,
    '& object': absoluteCommonRules
  },
  obj: {
    ...absoluteCommonRules,
    width: 100,
    height: 100,
    borderRadius: '50%',
    display: 'inline-block',
    borderTop: `4px solid ${lite}`,
    borderRight: '4px solid transparent',
    animation: 'rotation 1s linear infinite',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      width: 85,
      height: 85,
      borderRadius: '50%',
      borderLeft: `4px solid ${brand}`,
      borderBottom: '4px solid transparent',
      animation: 'rotation 0.5s linear infinite reverse'
    }
  },
  brand: {
    width: 73,
    height: 45,
    objectFit: 'cover',
    objectPosition: 'top left',
    animation: 'scaleInOut 2s linear infinite'
  },
  car: {
    width: 73,
    height: 73,
    objectFit: 'cover',
    objectPosition: 'top left',
    animation: 'scaleInOut 2s linear reverse infinite'
  }
});
