import React from 'react';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { IconButton, MenuItem, Select } from '@mui/material';
import { ChevronLeft, ChevronRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useStyle } from './styles';

interface CustomFooterProps {
  rowCount: number;
  page: number;
  rowsPerPage: number;
  changeRowsPerPage: (page: string | number) => void;
  changePage: (newPage: number) => void;
}

function CustomFooter({ rowCount, page, rowsPerPage, changeRowsPerPage, changePage }: CustomFooterProps) {
  const rowsPerPageOptions = [10, 25, 50, 100];
  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const classes = useStyle();

  const handlePageChange = (newPage: number) => {
    changePage(newPage);
  };

  const handleRowsPerPageChange = (value: number) => {
    changeRowsPerPage(value);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5;
    const startPage = Math.max(1, page - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    const handleKeyPress = (pageNumber: any) => (event: any) => {
      if (event.key === 'Enter') {
        handlePageChange(pageNumber);
      }
    };

    for (let i = startPage; i <= endPage; i += 1) {
      pageNumbers.push(
        <span
          key={i}
          role='button'
          tabIndex={0}
          onClick={() => handlePageChange(i)}
          onKeyPress={handleKeyPress(i)}
          className={`page-number ${i === page ? 'page-active' : ''}`}
        >
          {i}
        </span>
      );
    }

    return pageNumbers;
  };

  return (
    <div className={classes.footer}>
      <TableFooter>
        <TableRow>
          <TableCell>
            Mostrar
            <Select value={rowsPerPage} onChange={e => handleRowsPerPageChange(Number(e.target.value))}>
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            por página
          </TableCell>
          <TableCell sx={{ pr: 18 }}>
            <IconButton onClick={() => handlePageChange(1)} disabled={page === 1}>
              <KeyboardDoubleArrowLeft />
            </IconButton>
            <IconButton onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
              <ChevronLeft />
            </IconButton>
            {getPageNumbers()}
            <IconButton onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
              <ChevronRight />
            </IconButton>
            <IconButton onClick={() => handlePageChange(totalPages)} disabled={page === totalPages}>
              <KeyboardDoubleArrowRight />
            </IconButton>
          </TableCell>
          <TableCell>
            <p>
              Total de <span className='row-count-number'>{rowCount}</span> registros
            </p>
          </TableCell>
        </TableRow>
      </TableFooter>
    </div>
  );
}

export default CustomFooter;
