const itsDocumentNumber = (value: string) => {
  const regex = /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/;

  return !!value.match(regex);
};

export const cnpjMask = (value: string) => {
  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números
};

export const unmaskDocument = (value: string) => {
  if (itsDocumentNumber(value)) return value.replace(/\D/g, '');
  return value;
};

export function currencyMask(value: string): string {
  const cleanValue = value.replace(/[^\d,.]/g, '');
  const numberValue = parseFloat(cleanValue.replace(',', '.')) || 0;
  const formattedValue = numberValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });

  return formattedValue;
}

export const unmaskCurrency = (value: string): number => {
  const cleanValue = value.replace(/\D/g, '');
  const floatValue = parseFloat(cleanValue) / 100;

  return floatValue || 0;
};
