import LoadingButton from '@mui/lab/LoadingButton';
import { styled, Theme } from '@mui/material/styles';
import { EFontFamily } from 'helpers/constants';
import { createUseStyles } from 'react-jss';

export const CustomLoadingButton = styled(LoadingButton)(() => ({
  borderRadius: 40,
  fontSize: 13
}));

const useStyle = (theme: Theme) => {
  return createUseStyles({
    inputSection: {
      display: 'flex',
      position: 'relative',
      '& > #btnSendFile': {
        marginLeft: 10,
        position: 'absolute',
        zIndex: 99,
        top: 0,
        bottom: 0,
        right: 16,
        maxHeight: 30,
        margin: 'auto'
      }
    },
    fileList: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 16
    },
    inputArea: {
      display: 'flex',
      width: '100%',
      padding: 16,
      color: theme.palette.primary.main,
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: 10,
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      '& > span': {
        flexGrow: 1,
        textAlign: 'center',
        fontFamily: EFontFamily.exo,
        fontWeight: 500,
        fontSize: 14,
        textTransform: 'uppercase',
        paddingRight: 100
      }
    },
    inputHidden: {
      visibility: 'hidden',
      width: 0,
      height: 0,
      pointerEvents: 'none'
    }
  });
};

export default useStyle;
