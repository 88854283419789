import React, { memo } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from 'components/Providers/Auth';
import PermissionRoute from 'components/Shared/PermissionRoute';

import SignIn from './Public/SignIn';

const Pages = memo(() => {
  return (
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path='/login/*' element={<SignIn />} />
            <Route path='/*' element={<PermissionRoute />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </SnackbarProvider>
  );
});

export default Pages;
