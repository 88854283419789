import { useEffect, useState } from 'react';
import { STATUS_CHANGE_EVENT } from 'helpers/constants';
import { paymentStatusEmitter } from '../../components/Pages/Admin/event/eventEmitter';

const usePaymentStatusEmitter = () => {
  const [paymentStatus, setPaymentStatus] = useState<string>('');

  useEffect(() => {
    const handleStatusChange = (status: string) => {
      setPaymentStatus(status);
    };

    paymentStatusEmitter.on(STATUS_CHANGE_EVENT, handleStatusChange);

    return () => {
      paymentStatusEmitter.off(STATUS_CHANGE_EVENT, handleStatusChange);
    };
  }, []);

  return paymentStatus;
};

export default usePaymentStatusEmitter;
