import { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from './api';

export type objError = {
  data: null;
  message: string;
  status: string;
};

export type client = {
  cnpj: string;
  name: string;
};

export type tableRequest = {
  perPage: number;
  page: number;
  search?: string;
  filters?: string;
  dateRef?: string;
  from?: string;
  to?: string;
};

export function getClients(callback: (data: client[], newCount: number) => void, request: tableRequest) {
  const { perPage, page, filters } = request;

  api
    .get(`/clients?per_page=${perPage}&page=${page}${filters}`)
    .then(result => {
      const { data, total } = result;

      callback(data, total);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}
