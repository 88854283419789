import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormText from 'components/Shared/FormFields/FormText';
import { currencyMask, unmaskCurrency } from 'helpers/mask';
import React, { useEffect, useState } from 'react';

type Props = {
  amountPaid: number;
  paidAt: string;
  isOpen: boolean;
  setAmountPaid: (amountPaid: number) => void;
  setPaidAt: (paidAt: string) => void;
  setClose: () => void;
  handleUpdate: () => void;
};

function ModalSetPayment({ amountPaid, setAmountPaid, paidAt, setPaidAt, handleUpdate, isOpen, setClose }: Props) {
  const [localAmountPaid, setLocalAmountPaid] = useState<number>(amountPaid);
  const [internalPaidAt, setInternalPaidAt] = useState<string>(paidAt);
  const isAmountValid = amountPaid > 0;
  const isDateValid = !!paidAt && paidAt.trim() !== '';

  const isFormInvalid = !isAmountValid || !isDateValid;

  useEffect(() => {
    setLocalAmountPaid(amountPaid);
    setInternalPaidAt(paidAt);
  }, [amountPaid, paidAt]);

  return (
    <Dialog open={isOpen} onClose={setClose} maxWidth='xs'>
      <DialogTitle id='alert-dialog-title'>Informar pagamento</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ padding: '10px 0' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormText
                label='Valor pago'
                value={currencyMask(localAmountPaid.toString())}
                onChange={e => {
                  const unmaskedValue = unmaskCurrency(e.target.value);
                  setLocalAmountPaid(unmaskedValue);
                  setAmountPaid(unmaskedValue);
                }}
                rows={2}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                type='date'
                label='Data de pagamento'
                value={internalPaidAt}
                onChange={e => {
                  setInternalPaidAt(e.target.value);
                  setPaidAt(e.target.value);
                }}
                rows={2}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onBlur={() => setPaidAt(internalPaidAt)}
                required
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={setClose}>Fechar</Button>
        <Button
          variant='contained'
          onClick={() => {
            handleUpdate();
            setClose();
          }}
          disabled={isFormInvalid}
          autoFocus
          disableElevation
        >
          Informar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalSetPayment;
