import { styled } from '@mui/material/styles';
import { EFontFamily } from 'helpers/constants';
import { createUseStyles } from 'react-jss';

type ContainerProps = {
  label: string;
};

export const Container = styled('div')<ContainerProps>(({ label }) => ({
  '& .react-pdf__Page__canvas': {
    width: '260px !important',
    height: '140px !important',
    border: '1px solid #E2E2E2',
    borderRadius: '10px 10px 0 0',
    display: 'block',
    overflow: 'auto',
    objectFit: 'cover',
    objectPosition: label === 'Boleto' ? '4px -152px' : '0px 10px'
  },

  '& .MuiSkeleton-rounded': {
    borderRadius: '10px 10px 0 0'
  },

  '& .react-pdf__Page__annotations': {
    display: 'none'
  },

  '& .react-pdf__Page__textContent': {
    display: 'none'
  },

  '& .react-pdf__Page__textContentLayer': {
    display: 'none'
  },

  '& .react-pdf__Page__textContentLayer canvas': {
    display: 'none'
  },

  '& .react-pdf__Page__textContentLayer svg': {
    display: 'none'
  },

  '& .react-pdf__Page__textContentLayer path': {
    display: 'none'
  },

  '& .react-pdf__Page__textContentLayer rect': {
    display: 'none'
  }
}));

const useStyle = createUseStyles({
  page: {
    padding: 0
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '0 0 16px 16px',
    padding: '0 24px',
    backgroundColor: '#909090',
    fontFamily: EFontFamily.inter,
    height: 45,
    width: 260,
    textTransform: 'none',
    fontSize: 13,
    fontWeight: 400,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#909090'
    }
  }
});

export default useStyle;
