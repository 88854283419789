import LoadingButton from '@mui/lab/LoadingButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { EFontFamily } from 'helpers/constants';
import { createUseStyles } from 'react-jss';

export const CustomAccordion = styled(Accordion)(() => ({
  border: '#f6f6f6 thin solid',
  margin: '8px 0px 25px 0px',
  borderRadius: 8,
  position: 'static'
}));

export const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#f4f4f4',
  minHeight: 40,
  color: theme.palette.grey[700],
  fontFamily: EFontFamily.poppins,
  borderRadius: 8,
  height: 45,

  '& svg': {
    width: 20,
    height: 20,
    marginRight: 8
  },

  '& > div': {
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',

    '& > p': {
      paddingTop: 3,
      fontSize: 15,
      fontWeight: 400
    }
  },

  '&[class*="expanded"]': {
    minHeight: 48,
    '& > div': {
      margin: '12px 0'
    }
  },

  '& > submit-button': {
    backgroundColor: theme.palette.primary.main
  }
}));

export const CustomAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 16
}));

export const CustomLoadingButton = styled(LoadingButton)(() => ({
  borderRadius: 40,
  fontSize: 13
}));

const useStyle = createUseStyles({
  actionsRoot: {
    textAlign: 'right',
    padding: 10,
    width: 'calc(100% - 210px)',
    marginBottom: -60,
    zIndex: 1,
    position: 'relative'
  }
});

export const CustomCardContent = {
  background: '#fff',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  borderRadius: 14
};

export const WrapperCartFastFilter = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  position: 'relative',
  bottom: 40,

  '@media (max-width: 1355px)': {
    justifyContent: 'flex-start'
  }
}));

export const TitleCartFastFilter = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > p': {
    fontFamily: EFontFamily.poppins,
    color: '#716E6E',
    fontWeight: 400,
    fontSize: 14,
    width: 102,

    '@media (max-width: 1490px)': {
      width: 120
    },

    '@media (max-width: 1340px)': {
      width: 135
    }
  },

  '& > hr': {
    border: 'none',
    borderBottom: '1px solid #d5d7d8',
    width: '93%',
    height: 1
  }
}));

export default useStyle;
