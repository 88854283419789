import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/Shared/Table';
import Loader from 'components/Shared/Loader';
import { infraction, infractionPatchData } from 'services/antt';
import { decisionDocument, getDecisionDocuments, downloadDecisionDocument } from 'services/antt/decisions';

import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import { IconStyle } from 'components/Shared/Table/styles';
import { Button } from '@mui/material';

import Grid from '@mui/material/Grid';
import FormToggleGroup from 'components/Shared/FormFields/FormToggleGroup';
import FormText from 'components/Shared/FormFields/FormText';
import { ROLE, User } from 'services/auth';
import { formatData, optionsObj } from './constants';
import { appealOptions } from '../constants';

function Legal(props: { infractionData: infraction; onChange: (data: Partial<infractionPatchData>) => void }) {
  const { trafficTicketId } = useParams();

  const { infractionData, onChange } = props;

  const [canAppeal, setCanAppeal] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<decisionDocument[] | null>(null);
  const [isRequestingDownload, setIsRequestingDownload] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [additionalInfo, setAdditionalInfo] = useState(infractionData.legal_additional_info);

  const [hasModuleAccess, setHasModuleAccess] = useState(false);
  const userSerialized = localStorage.getItem('user') || '';
  const storageUser: User = JSON.parse(userSerialized);
  const userRole = storageUser.roles[0].name;

  const renderDeferred = (value: boolean | null) => {
    if (value === null) {
      return '-';
    }

    return value ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />;
  };

  const renderView = (value: any, meta: any) => {
    const protocol = meta.rowData[2].replace(/\D+/g, '');

    const handleView = () => {
      console.log('value, protocol, meta', value, protocol, meta);
      setIsRequestingDownload(protocol);
      downloadDecisionDocument(value, protocol, () => setIsRequestingDownload(null));
    };

    return (
      <Tooltip title='Visualizar' placement='left-start'>
        <Button onClick={handleView}>
          <IconButton aria-label='view'>
            {isRequestingDownload === protocol ? (
              <CircularProgress size={16} />
            ) : (
              <RemoveRedEyeIcon style={IconStyle} />
            )}
          </IconButton>
        </Button>
      </Tooltip>
    );
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'cnpj',
      label: 'cnpj',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'protocol',
      label: 'Protocolo',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'document_type',
      label: 'Tipo de Documento',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'document_date',
      label: 'Data do Documento',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'protocol_date',
      label: 'Data do Protocolo',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'is_deferred',
      label: 'Deferimento',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: renderDeferred
      }
    },
    {
      name: 'view',
      label: 'Visualizar',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: renderView
      }
    }
  ];

  const handleChangeCanAppeal = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    setCanAppeal(newValue !== null ? newValue === 'true' : undefined);
  };

  const handleChangeAdditionalInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalInfo(e.target.value);
  };

  const handleSuccess = (newData: decisionDocument[]) => {
    setData(newData);
    setIsLoading(false);
  };

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getDecisionDocuments(handleSuccess, Number(trafficTicketId));
  }, [trafficTicketId]);

  useEffect(() => {
    setHasModuleAccess(
      userRole === ROLE.LEGAL ||
        userRole === ROLE.MANAGER ||
        userRole === ROLE.ADMIN ||
        userRole === ROLE.LEGAL_EXTERNAL
    );

    setAdditionalInfo(infractionData.legal_additional_info);
    const defensible = infractionData.legal_is_defensible;
    setCanAppeal(defensible);

    // First load no dependecies to watch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => fetchData(), [fetchData]);

  useEffect(() => {
    onChange({
      legal_is_defensible: canAppeal,
      legal_additional_info: additionalInfo
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInfo, canAppeal]);

  const formattedData = data?.map((item: decisionDocument) => formatData(item, infractionData));

  if (isLoading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormToggleGroup
          disabled={!hasModuleAccess}
          label='Esse processo é passível de defesa/recurso?'
          value={canAppeal === undefined ? '' : String(canAppeal)}
          onChange={handleChangeCanAppeal}
          options={appealOptions}
          fixedWidth={60}
        />
      </Grid>

      <Grid item xs={12}>
        <FormText
          label='Descreva de forma objectiva as evidências identificadas para auxiliar no processo de defesa/recurso'
          value={additionalInfo || ''}
          onChange={handleChangeAdditionalInfo}
          rows={2}
          disabled={!hasModuleAccess}
          fullWidth
          multiline
        />
      </Grid>

      <Grid item xs={12}>
        <DataTable columns={columns} data={formattedData} optionsObj={optionsObj} />
      </Grid>
    </Grid>
  );
}

export default Legal;
