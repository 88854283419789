import { styled } from '@mui/material/styles';
import { EFontFamily } from 'helpers/constants';

export const Container = styled('div')(() => ({
  '& .MuiAlert-root': {
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 30px',
    margin: '10px 0',
    fontWeight: 500,
    fontSize: 14,
    borderRadius: '8px',
    borderWidth: ' 1px 1px 1px 10px',
    borderStyle: 'solid',
    fontFamily: EFontFamily.inter
  },

  '& .MuiAlert-action': {
    padding: 0
  },

  '& .payment-status-pending': {
    backgroundColor: '#FADCB1',
    color: '#A7875A',

    borderTopColor: '#E1CDB1',
    borderRightColor: '#E1CDB1',
    borderBottomColor: '#E1CDB1',
    borderLeftColor: '#F0AD4E'
  },

  '& .payment-status-unpaid': {
    backgroundColor: '#EABBBB',
    color: '#CE7474',

    borderTopColor: '#DCA3A3',
    borderRightColor: '#DCA3A3',
    borderBottomColor: '#DCA3A3',
    borderLeftColor: '#D77F7F'
  },

  '& .payment-status-paid': {
    backgroundColor: '#D5D9D9',
    color: '#6C8979',

    borderTopColor: '#C8CFCF',
    borderRightColor: '#C8CFCF',
    borderBottomColor: '#C8CFCF',
    borderLeftColor: '#6C8979'
  }
}));
