/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, User } from 'services/auth';
import { useSnackbar } from 'notistack';

type handleSignIn = (data: unknown, updateSubmitting: (isSubmitting: boolean) => void) => void;

export type valueData = {
  token: string;
  user: User | null;
  handleSignIn: handleSignIn;
  handleSignOut: () => void;
};

const AuthContext = React.createContext({});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();
  const [token, setToken] = useState('');
  const [user, setUser] = useState<User | null>(null);

  const navigate = useNavigate();

  const obj = useMemo(() => {
    const handleSignIn: handleSignIn = (data, updateSubmitting) => {
      const handleSuccess = (token: string, user: User) => {
        localStorage.setItem('TK', token.split('|')[1]);
        setToken(token);

        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);

        if (user.first_login) navigate('/login/nova-senha');

        navigate('/inicio');
      };

      const handleError = (message: string) => {
        updateSubmitting(false);
        enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      };

      return login(data, handleSuccess, handleError);
    };

    const handleSignOut = () => {
      localStorage.removeItem('TK');
      setToken('');

      localStorage.removeItem('user');
      setUser(null);

      navigate('/login');
    };

    return { token, user, handleSignIn, handleSignOut };
  }, [enqueueSnackbar, navigate, token, user]);

  return <AuthContext.Provider value={obj}>{children}</AuthContext.Provider>;
}

export default AuthContext;
