import React from 'react';
import { Alert, AlertColor } from '@mui/material';
import texts from 'texts/alert-payment';
import { useTutorialStepEmitter } from 'hooks';
import { tutorialStepEmmiter } from 'components/Pages/Admin/event/eventEmitter';
import { StatusPayment } from 'helpers/types';
import UserTutorial from 'components/Shared/UserTutorial';
import { AlertPaymentTutorial } from 'texts/tutorial';
import { FINANCIAL_TUTORIAL_STEP } from 'helpers/constants';

import { Container } from './style';

type Props = {
  status: string;
};

const stepsTutorial = AlertPaymentTutorial.steps;

const statusMapping: Record<StatusPayment, { severity: AlertColor; label: string }> = {
  unpaid: { severity: 'error', label: texts.label.unpaid },
  pending: { severity: 'warning', label: texts.label.pending },
  paid: { severity: 'success', label: texts.label.paid }
};

function AlertPayment({ status }: Props) {
  const { severity, label } = statusMapping[status as keyof typeof statusMapping];
  const newtutorialStep = useTutorialStepEmitter();

  const handleOnFinish = () => {
    tutorialStepEmmiter.emit(FINANCIAL_TUTORIAL_STEP, 2);
  };

  return (
    <Container className='label-payment-status'>
      <UserTutorial stepsTutorial={stepsTutorial} run={newtutorialStep === 1} onFinish={() => handleOnFinish()} />
      <Alert variant='filled' severity={severity} className={`payment-status-${status}`}>
        {label}
      </Alert>
    </Container>
  );
}

export default React.memo(AlertPayment);
