import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { createUseStyles } from 'react-jss';
import { EFontFamily } from 'helpers/constants';
import { rem } from 'helpers/utils';

const useStyle = createUseStyles({
  stepper: {
    padding: '20px 0',

    '& .MuiStepLabel-alternativeLabel': {
      height: 10
    },

    '& .MuiTypography-caption': {
      display: 'none'
    }
  },
  listPaymentDetail: {
    padding: '10px 0 0 16px'
  },
  'circular-progress': {
    paddingBottom: 4
  },
  stepIcon: {
    position: 'relative',
    bottom: 6,
    color: '#7f7a9f',
    opacity: 1,
    zIndex: 4,
    height: '22px !important'
  },
  InvoicePreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8
  }
});

export const FinancialContainer = styled('div')(() => ({
  '& .react-joyride__tooltip': {
    borderRadius: '14px !important'
  }
}));

export const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: '#D9D9D9',
  position: 'relative',
  zIndex: 2,
  color: '#fff',
  width: 10,
  height: 10,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(ownerState.active && {
    backgroundColor: '#484557',
    width: 12,
    height: 12,

    '& div': {
      backgroundColor: '#8782AA',
      width: 24,
      height: 24,
      position: 'absolute',
      zIndex: 0,
      borderRadius: '100%',
      opacity: '0.8'
    }
  }),
  ...(ownerState.completed && {
    backgroundColor: '#7F7A9F',
    position: 'relative',
    zIndex: 2
  })
}));

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 4,
    left: 'calc(-50% + 4px)',
    right: 'calc(50% + 3px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7F7A9F'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7F7A9F'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));

export const StepperLabel = styled('p')(() => ({
  color: '#676767',
  fontWeight: 600,
  margin: 0,
  fontSize: 14,
  fontFamily: EFontFamily.inter
}));

export const StepperDate = styled('p')(() => ({
  color: '#656565',
  margin: 0,
  fontSize: 13,
  fontWeight: 200,
  fontFamily: EFontFamily.inter
}));

export const ContainerPaymentSummary = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 15,
  padding: '55px 0',
  flexWrap: 'nowrap',

  '.payment-summary': {
    width: rem(230)
  },
  '.payment-status-concluded': {
    width: rem(230)
  },

  [theme.breakpoints.down(1646)]: {
    gap: 7,

    '.payment-summary': {
      width: 200
    },
    '.payment-status-concluded': {
      width: 200
    }
  },

  [theme.breakpoints.down(1520)]: {
    flexWrap: 'wrap'
  }
}));

export const InvoiceAttachmentsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 6,
  width: '100%'
}));

export default useStyle;
