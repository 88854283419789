import { tableRequest } from 'services/antt';
import {
  INVOICES_AVAILABLE,
  INVOICES_DUE,
  INVOICES_EXPIRED,
  INVOICES_SERASA,
  NEW_INFRACTIONS
} from 'components/Pages/Admin/TrafficTickets/constants';
import {
  IN_ANALYSIS_BY_LEGAL,
  COMPLETED,
  NOTIFICATION_INITIAL_ANALYSIS,
  SCHEDULED_PAYMENT,
  LEGAL_PROCESS_TREATMENT,
  PAYMENT_COMPLETED
} from './constants';
import { getDateRelativeToToday, getFirstDayOfYear, getLastDayOfYear, getYesterday } from './date';

export const getColorFromString = (str: string): string => {
  let statusColor = '';

  switch (str) {
    case IN_ANALYSIS_BY_LEGAL:
      statusColor = '#84a4c5';
      break;
    case COMPLETED:
      statusColor = '#a3af9e';
      break;
    case NOTIFICATION_INITIAL_ANALYSIS:
      statusColor = '#f4e184';
      break;
    case SCHEDULED_PAYMENT:
      statusColor = '#f4e184';
      break;
    case PAYMENT_COMPLETED:
      statusColor = '#84a4c5';
      break;
    case LEGAL_PROCESS_TREATMENT:
      statusColor = '#f4e184';
      break;
    default:
      statusColor = '';
      break;
  }

  return statusColor;
};

export function getFilterParams(filterName: string): tableRequest {
  const commonParams: tableRequest = {
    perPage: 10,
    page: 1
  };

  const today = getDateRelativeToToday(true);
  const currentYear = new Date().getFullYear();
  const lastMonthDate = getDateRelativeToToday(true, -1);
  const nextWeekDate = getDateRelativeToToday(true, 0, 7);
  const firstDayOfYear = getFirstDayOfYear(currentYear);
  const lastDayOfYear = getLastDayOfYear(currentYear);
  const yesterday = getYesterday();

  switch (filterName) {
    case NEW_INFRACTIONS:
      return {
        ...commonParams,
        dateRef: 'occurrence',
        from: lastMonthDate,
        to: today,
        filters: '&columns[0][name]=datetime&columns[0][order]=desc'
      };
    case INVOICES_AVAILABLE:
      return {
        ...commonParams,
        dateRef: 'due_date',
        from: firstDayOfYear,
        to: lastDayOfYear,
        filters:
          '&columns[0][name]=datetime&columns[0][order]=desc&columns[1][name]=invoices.available&columns[1][filter]=true'
      };
    case INVOICES_DUE:
      return {
        ...commonParams,
        dateRef: 'due_date',
        from: today,
        to: nextWeekDate,
        filters:
          '&columns[0][name]=datetime&columns[0][order]=desc&columns[1][name]=invoices.available&columns[1][filter]=true'
      };
    case INVOICES_SERASA:
      return {
        ...commonParams,
        filters:
          '&columns[0][name]=infractions.details&columns[0][filter]=serasa&columns[1][name]=invoices.available&columns[1][filter]=true'
      };
    case INVOICES_EXPIRED:
      return {
        ...commonParams,
        dateRef: 'due_date',
        from: firstDayOfYear,
        to: yesterday,
        filters:
          '&columns[0][name]=datetime&columns[0][order]=desc&columns[1][name]=invoices.available&columns[1][filter]=true'
      };
    default:
      return commonParams;
  }
}

export const rem = (px: number): string => `${px / 16}rem`;

export function normalizeAddress(address: string): string {
  const addressWithoutCommas = address.replace(/,/g, '');
  const addressWithoutKM = addressWithoutCommas.replace(/\bKM\w*\b/gi, '').trim();
  const addressWithoutDash = addressWithoutKM.replace(/-\s*$/, '');
  const regex = /BR\s*(\d+)/i;
  const match = addressWithoutDash.match(regex);

  if (match && match[1]) {
    return `BR ${match[1]}`;
  }

  return addressWithoutDash;
}
