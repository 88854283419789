import { rem } from 'helpers/utils';
import { EFontFamily } from 'helpers/constants';
import { styled } from '@mui/material/styles';
import FormText from '../FormFields/FormText';

export const CustomFormText = styled(FormText)(() => ({
  '& .MuiInputLabel-root': {
    fontSize: `${rem(16)} !important`,
    color: '#585858 !important',
    fontWeight: 700,
    fontFamily: EFontFamily.inter
  },

  '& .MuiInputBase-input': {
    fontSize: rem(14),
    color: '#656565 !important',
    WebkitTextFillColor: 'unset !important'
  },

  '& .MuiInputBase-root': {
    borderRadius: rem(10)
  }
}));
