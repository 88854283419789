import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { EFontFamily } from 'helpers/constants';

export const CustomTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '110%',
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
  fontSize: '26px',
  fontWeight: 300,
  marginBottom: '20px',
  fontFamily: EFontFamily.exo
}));
