import * as React from 'react';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { forgotPassword } from 'services/auth';

import { FormBox, CustomTextField, CustomInputLabel, linkForgotPass, CustomSubmit } from '../styles';
import { ErrorMessage } from './styles';

const initialValues = { email: '' };

type typeValues = typeof initialValues;
type handleForgotPass = (data: unknown, updateSubmitting: (isSubmitting: boolean) => void) => void;

export default function ForgotPass() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onValidate = (values: typeValues) => {
    let errors = {};

    if (!values.email) {
      errors = {
        ...errors,
        email: 'Obrigatório'
      };
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors = {
        ...errors,
        email: 'Email inválido'
      };
    }

    return errors;
  };

  const handleForgotPass: handleForgotPass = (data, updateSubmitting) => {
    const success = () => {
      navigate('/login');
    };

    const error = (message: string) => {
      updateSubmitting(false);
      enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    };

    return forgotPassword(data, success, error);
  };

  const onSubmit = (values: typeValues, formikHelpers: FormikHelpers<typeValues>) => {
    const { setSubmitting } = formikHelpers;

    handleForgotPass(values, setSubmitting);
  };

  return (
    <>
      <Helmet>
        <title>Esqueci Minha Senha - UP Gestão de Frotas</title>
      </Helmet>

      <FormBox>
        <Formik initialValues={initialValues} validate={onValidate} onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='email'>
                  E-mail
                </CustomInputLabel>
                <CustomTextField
                  id='email'
                  name='email'
                  autoComplete='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.email && errors.email}</ErrorMessage>
              </FormControl>

              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to='/login' sx={linkForgotPass}>
                    Voltar
                  </Link>
                </Grid>
                <Grid item>
                  <CustomSubmit
                    disabled={isSubmitting || !!Object.keys(errors).length}
                    type='submit'
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isSubmitting ? <CircularProgress size={16} /> : 'Enviar'}
                  </CustomSubmit>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </FormBox>
    </>
  );
}
