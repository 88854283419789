/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Divider from 'components/Shared/Divider';
import FormText from 'components/Shared/FormFields/FormText';
import Loader from 'components/Shared/Loader';
import StaticField from 'components/Shared/StaticField';
import {
  BILL_PAID,
  DEFAULT_AMOUNT,
  DISCOUNT_TITLE,
  EMPTY_FIELD_VALUE,
  FEE_TITLE,
  RESIDUAL_TITLE,
  STATUS_CHANGE_EVENT
} from 'helpers/constants';
import { formatDate } from 'helpers/date';
import { formatCurrency } from 'helpers/formats';
import React, { useCallback, useEffect, useState } from 'react';
import { getFinancial, infraction, infractionPatchData } from 'services/antt';
import { ROLE } from 'services/auth';
import {
  downloadInvoice,
  downloadReceiptFile,
  generateInvoiceFile,
  generateReceiptFile,
  updateInvoice
} from 'services/antt/invoices';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import UserTutorial from 'components/Shared/UserTutorial';
import { usePaymentStatusEmitter, useTutorialStepEmitter } from 'hooks';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import texts from 'texts/warning-dialog';
import { InvoiceStatus, StepType, User } from 'helpers/types';

import { Box } from '@mui/material';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import { postFirstAccessCheck } from 'services/user';
import { FinancialTutorial } from 'texts/tutorial';
import ModalSetPayment from './ModalSetPayment';
import useStyles, {
  ColorlibConnector,
  ColorlibStepIconRoot,
  ContainerPaymentSummary,
  FinancialContainer,
  InvoiceAttachmentsContainer,
  StepperDate,
  StepperLabel
} from './styles';

import PaymentSummary from './PaymentSummary';
import ListPaymentDetail from './ListPaymentDetail';
import { ListPaymentDetailProps } from '../Logistics/constants';
import InvoicePreview from './InvoicePreview';
import WarningDialog from './WarningDialog';
import { paymentStatusEmitter } from '../../event/eventEmitter';

function Financial(props: {
  infractionData: infraction;
  onChange: (data: Partial<infractionPatchData>) => void;
  paymentStatus: string;
}) {
  const classes = useStyles();

  const { infractionData, onChange, paymentStatus } = props;
  const trafficTicketId = infractionData?.id;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [infraction, setInfraction] = useState<infraction>({} as infraction);
  const [additionalInfo, setAdditionalInfo] = useState(infractionData?.finance_additional_info);
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false);
  const [downloadingFileReceipt, setDownloadingFileReceipt] = useState<boolean>(false);

  const [warningModalIsOpen, setWarningModalIsOpen] = useState<boolean>(false);

  const [hasModuleAccess, setHasModuleAccess] = useState(false);
  const userSerialized = localStorage.getItem('user') || '';
  const storageUser: User = JSON.parse(userSerialized);
  const userRole = storageUser.roles[0].name;

  const handleSuccess = (newData: infraction) => {
    setInfraction(newData);
    setAdditionalInfo(infractionData.finance_additional_info);
    setIsFetching(false);
  };

  const fetchData = useCallback(() => {
    setIsFetching(true);
    getFinancial(handleSuccess, Number(trafficTicketId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trafficTicketId]);

  useEffect(() => {
    setHasModuleAccess(userRole !== ROLE.LEGAL && userRole !== ROLE.LEGAL_EXTERNAL);

    fetchData();
  }, [fetchData, userRole]);

  const [FileInvoice, setFileInvoice] = useState<any>();
  const [FileReceipt, setFileReceipt] = useState<any>();

  useEffect(() => {
    let isMounted = true;

    generateInvoiceFile(infractionData.id)
      .then(async data => {
        if (isMounted && data) {
          setFileInvoice(data);
        }
      })
      .catch(() => {
        setFileInvoice(undefined);
      });

    generateReceiptFile(infractionData.id)
      .then(async data => {
        if (isMounted && data) {
          setFileReceipt(data);
        }
      })
      .catch(() => {
        setFileReceipt(undefined);
      });

    return () => {
      isMounted = false;
    };
  }, [infractionData.id]);

  const {
    invoice_id,
    invoice_number,
    invoice_status,
    invoice_amount,
    invoice_amount_discounted,
    invoice_due_date,
    invoice_updated_at,
    invoice_paid_at,
    invoice_amount_paid,
    invoice_fee_amount,
    invoice_available,
    invoices_count,
    extrato_saldo_residual,
    extrato_situacao,
    extrato_data_pagamento,
    extrato_valor_pagamento,
    invoice_created_at,
    valor_multa_mora,
    desconto,
    pago_com_desconto,
    desconto_perdido,
    valor_juros
  } = infraction;
  const formattedDueDate = invoice_due_date ? formatDate(invoice_due_date) : EMPTY_FIELD_VALUE;
  const formattedUpdatedAt = invoice_updated_at ? formatDate(invoice_updated_at) : EMPTY_FIELD_VALUE;
  const formattedCreatedAt = invoice_created_at ? formatDate(invoice_created_at) : null;
  const formattedPaymentStatementDate = extrato_data_pagamento ? formatDate(extrato_data_pagamento) : EMPTY_FIELD_VALUE;
  const formattedPaymentDate = invoice_paid_at ? formatDate(invoice_paid_at) : EMPTY_FIELD_VALUE;
  const amountCharged = invoice_amount_discounted ? formatCurrency(invoice_amount_discounted, true) : DEFAULT_AMOUNT;
  const residualBalanceStatement = formatCurrency(extrato_saldo_residual, true) ?? DEFAULT_AMOUNT;

  const renderValue = (value: string) => {
    return value ?? EMPTY_FIELD_VALUE;
  };

  const handleDownloadInvoice = () => {
    setDownloadingInvoice(true);

    downloadInvoice(infractionData.id, infractionData.number, invoice_number, () => {
      setDownloadingInvoice(false);
    });
  };

  const handleDownloadReceipt = () => {
    setDownloadingFileReceipt(true);

    downloadReceiptFile(infractionData.id, infractionData.number, invoice_number, () => {
      setDownloadingFileReceipt(false);
    });
  };

  const handleToggleModal = () => {
    setModalIsOpen(prevState => !prevState);
  };

  const [stateAmountPaid, setStateAmountPaid] = useState<number>(0);
  const [statePaidAt, setStatePaidAt] = useState<string>('');

  useEffect(() => {
    setStateAmountPaid(Number(infractionData.invoice_amount) ?? Number(infractionData.invoice_amount_discounted));
    setStatePaidAt(invoice_paid_at ?? new Date().toISOString().split('T')[0]);
  }, [infractionData, invoice_paid_at]);

  const modalHandleSuccess = useCallback(() => {
    setIsFetching(false);
    setInfraction({
      ...infraction,
      invoice_amount_paid: stateAmountPaid,
      invoice_paid_at: statePaidAt,
      invoice_updated_at: new Date().toISOString()
    });
  }, [infraction, stateAmountPaid, statePaidAt]);

  const handleUpdateInvoiceError = () => {
    setWarningModalIsOpen(true);
  };

  const handleUpdate = useCallback(() => {
    setIsFetching(true);
    const data = {
      amount_paid: stateAmountPaid,
      paid_at: statePaidAt
    };

    updateInvoice(modalHandleSuccess, invoice_id, data, handleUpdateInvoiceError);
    paymentStatusEmitter.emit(STATUS_CHANGE_EVENT, 'pending');
  }, [invoice_id, modalHandleSuccess, stateAmountPaid, statePaidAt]);

  const additionalInfoChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalInfo(e.target.value);
  };

  useEffect(() => {
    onChange({
      finance_additional_info: additionalInfo
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInfo]);

  const generatePaymentSummary = () => {
    const paymentSummary = [];

    const userPaymentAmount = invoice_amount_paid !== null ? invoice_amount_paid : 0;
    const systemPaymentAmount = extrato_valor_pagamento !== null ? extrato_valor_pagamento : 0;
    const paymentAmount = systemPaymentAmount > 0 ? systemPaymentAmount : userPaymentAmount;

    const formattedPaymentAmount = formatCurrency(paymentAmount, true);

    paymentSummary.push({
      title: 'Valor do documento',
      value: formatCurrency(invoice_amount, true),
      icon: <SavingsRoundedIcon />
    });

    paymentSummary.push({
      title: 'Valor cobrado',
      value: amountCharged,
      icon: <PaymentsRoundedIcon />
    });

    paymentSummary.push({
      title: 'Valor pago',
      value: formattedPaymentAmount,
      icon: <AccountBalanceRoundedIcon />,
      paymentProcessed: true
    });

    return paymentSummary;
  };

  const generatePaymentDetailsList = (): ListPaymentDetailProps[] => {
    const list: ListPaymentDetailProps[] = [];

    const penaltyAmount = valor_multa_mora ?? invoice_fee_amount;
    const interestAmount = valor_juros ?? 0;
    const totalFees = penaltyAmount + interestAmount;
    const formattedTotalFees = totalFees > 0 ? formatCurrency(totalFees, true) : `R$ ${DEFAULT_AMOUNT}`;

    if (desconto || desconto_perdido) {
      list.push({
        title: DISCOUNT_TITLE,
        amount: desconto > 0 ? desconto : desconto_perdido,
        isDiscountApplied: pago_com_desconto
      });
    }

    list.push({
      title: FEE_TITLE,
      amount: formattedTotalFees
    });

    list.push({
      title: RESIDUAL_TITLE,
      amount: residualBalanceStatement
    });

    return list;
  };

  const newPaymentStatus = usePaymentStatusEmitter();
  const newtutorialStep = useTutorialStepEmitter();

  const getInvoiceLabel = (): StepType[] => {
    const pendingPayment = newPaymentStatus === 'pending';
    const steps: StepType[] = [
      { label: InvoiceStatus.WaitingForInvoice, date: null, active: false },
      { label: InvoiceStatus.InvoiceAvailable, date: null, active: false },
      { label: InvoiceStatus.InvoiceUpdated, date: null, active: false },
      { label: InvoiceStatus.InvoicePaid, date: null, active: false },
      { label: InvoiceStatus.InvoiceConcluded, date: null, active: false }
    ];

    const setActiveStep = (index: number, date: string | null = null) => {
      steps[index].active = true;
      if (date) steps[index].date = date;
    };

    if (extrato_situacao === BILL_PAID) {
      setActiveStep(4);
    }

    if (invoice_status === 'Pago' || pendingPayment) {
      const paymentDate =
        extrato_data_pagamento || !pendingPayment ? formatDate(extrato_data_pagamento) : 'Aguardando confirmação';
      setActiveStep(3, paymentDate);
    }

    if (invoices_count === 0 || !invoice_id) {
      setActiveStep(0);
      return steps;
    }

    if (invoice_available) {
      setActiveStep(1, formattedCreatedAt);
      setActiveStep(2, formattedUpdatedAt);
    }

    return steps;
  };

  const getFormattedPaymentDate = (): string => {
    if (extrato_data_pagamento) {
      return formattedPaymentStatementDate;
    }

    return formattedPaymentDate;
  };

  const steps = getInvoiceLabel();
  const activeStep = steps.reduce((index, step, i) => (step.active ? i : index), -1);
  const isStepFailed = (step: number) => {
    return step === 3 && extrato_situacao !== BILL_PAID && invoice_status === 'Pago';
  };

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    const isLastStep = activeStep === steps.length - 1;

    if (isLastStep && active) {
      return <CheckCircleRoundedIcon className={classes.stepIcon} />;
    }

    return (
      <div>
        {props.error ? (
          <WatchLaterRoundedIcon className={classes.stepIcon} />
        ) : (
          <div>
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
              <div />
            </ColorlibStepIconRoot>
          </div>
        )}
      </div>
    );
  }

  const handleCloseWarningModal = () => {
    setWarningModalIsOpen(false);
    window.location.reload();
  };

  const handleFinishTutorial = () => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const user = JSON.parse(storedUser);

      user.tutorial.financial = false;
      localStorage.setItem('user', JSON.stringify(user));

      const data = {
        tutorial: {
          financial: false
        }
      };

      postFirstAccessCheck(data);
    }
  };

  const stepsTutorial = FinancialTutorial.steps;

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <FinancialContainer>
          <Divider title='Detalhes Financeiros' />
          <UserTutorial
            stepsTutorial={stepsTutorial}
            run={newtutorialStep === 2}
            onFinish={() => handleFinishTutorial()}
          />
          <Box sx={{ width: '100%', height: 82 }} className='timeline'>
            <Stepper
              className={classes.stepper}
              alternativeLabel
              activeStep={activeStep === -1 ? 0 : activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((step, index) => {
                const labelProps: {
                  optional?: React.ReactNode;
                  error?: boolean;
                } = {};

                if (isStepFailed(index)) {
                  labelProps.error = true;
                }

                return (
                  <Step key={step.label}>
                    <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                      <div>
                        <StepperLabel>{step.label}</StepperLabel>
                        <StepperDate>{step?.date}</StepperDate>
                      </div>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <ContainerPaymentSummary>
            {generatePaymentSummary().map(payment => (
              <div key={payment.title}>
                <div>{payment?.paymentProcessed}</div>
                <PaymentSummary
                  title={payment.title}
                  amount={payment.value}
                  icon={payment.icon}
                  statusPayment={newPaymentStatus !== '' ? newPaymentStatus : paymentStatus}
                  showEditValue={!(extrato_situacao === 'Quitada')}
                  onReportPayment={() => handleToggleModal()}
                  tutorial='inform-payment'
                />
              </div>
            ))}
            <ListPaymentDetail className={classes.listPaymentDetail} items={generatePaymentDetailsList()} />
          </ContainerPaymentSummary>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StaticField label='Linha Digitável' value={renderValue(invoice_number)} />
            </Grid>
            <Grid item xs={3}>
              <StaticField label='Data de Pagamento' value={getFormattedPaymentDate()} />
            </Grid>

            <Grid item xs={3}>
              <StaticField label='Data do Vencimento' value={formattedDueDate} />
            </Grid>

            <Grid item xs={12}>
              <FormText
                label='Outras Informações'
                value={additionalInfo || ''}
                onChange={additionalInfoChanged}
                rows={3}
                multiline
                disabled={!hasModuleAccess}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ mt: 2 }} className={classes.InvoicePreview}>
            <InvoiceAttachmentsContainer className='invoice-preview'>
              {FileReceipt !== undefined && (
                <InvoicePreview
                  page={1}
                  file={FileReceipt}
                  onClick={handleDownloadReceipt}
                  loading={downloadingFileReceipt}
                  loadingIndicator={<CircularProgress size={16} className='circular-progress' />}
                  label='Extrato'
                />
              )}
              {FileInvoice !== undefined && (
                <InvoicePreview
                  page={1}
                  file={FileInvoice}
                  onClick={handleDownloadInvoice}
                  loading={downloadingInvoice}
                  loadingIndicator={<CircularProgress size={16} className='circular-progress' />}
                  label='Boleto'
                  disabled={!hasModuleAccess || !invoice_id || invoice_status === 'Vencido' || !invoice_available}
                />
              )}
            </InvoiceAttachmentsContainer>
          </Grid>
        </FinancialContainer>
      )}

      <ModalSetPayment
        amountPaid={stateAmountPaid}
        setAmountPaid={setStateAmountPaid}
        paidAt={statePaidAt}
        setPaidAt={setStatePaidAt}
        handleUpdate={handleUpdate}
        isOpen={modalIsOpen}
        setClose={handleToggleModal}
      />

      <WarningDialog
        open={warningModalIsOpen}
        handleClose={handleCloseWarningModal}
        title={texts.content.title}
        description={texts.content.body}
      />
    </>
  );
}

export default Financial;
