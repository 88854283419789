import React, { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import UPLogo from 'assets/images/upfleet_logo.png';

import { LogoutIcon } from 'components/Shared/SvgIcons';
import AuthContext, { valueData } from 'components/Providers/Auth';
import { User } from 'services/auth';

import { CustomAppBar } from './styles';

function Header() {
  const { handleSignOut, user } = useContext(AuthContext) as valueData;

  let storageUser: User | null = null;
  try {
    storageUser = JSON.parse(localStorage.getItem('user') || '') as User;
  } catch (e) {
    console.log((e as Error).message);
  }

  return (
    <CustomAppBar position='fixed'>
      <img src={UPLogo} alt='UP Fleet Logo' className='header-logo' />
      <div className='greetingText'>
        <big>{user?.access_groups[0].display_name || storageUser?.access_groups[0].display_name}</big>
        <small>
          Olá <strong>{user?.name || storageUser?.name}</strong>, seja bem vindo a UP
        </small>
      </div>
      <div>
        <Tooltip title='Configurações'>
          <IconButton>
            <SettingsOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Sair'>
          <IconButton onClick={handleSignOut}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </div>
    </CustomAppBar>
  );
}

export default Header;
