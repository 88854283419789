import React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
// import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BalanceIcon from '@mui/icons-material/Balance';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { PoliceIcon } from 'components/Shared/SvgIcons';
import { ROLE, User } from 'services/auth';

import { CustomList, CustomListItem, ListIconStyle, IconStyle, CustomListItemText, TextDecorationNone } from './styles';

const drawerWidth = 200;

function Sidebar() {
  const userSerialized = localStorage.getItem('user') || '';
  const currentUser: User = userSerialized ? JSON.parse(userSerialized) : {};
  const userRole = userSerialized ? currentUser.roles[0].name : '';

  const menuList = [];

  if (userRole !== ROLE.LEGAL_EXTERNAL) {
    menuList.push(
      {
        id: 3,
        name: 'Logística',
        icon: <LocalShippingIcon style={IconStyle} />,
        url: '/dashboard/logistica'
      },
      {
        id: 4,
        name: 'Financeiro',
        icon: <MonetizationOnIcon style={IconStyle} />,
        url: '/dashboard/financeiro'
      },
      {
        id: 5,
        name: 'Jurídico',
        icon: <BalanceIcon style={IconStyle} />,
        url: '/dashboard/juridico'
      }
    );
  }

  menuList.push({
    id: 2,
    name: 'Multas',
    icon: <PoliceIcon style={IconStyle} />,
    url: '/multas'
  });

  if (userRole === ROLE.ADMIN) {
    menuList.push({
      id: 6,
      name: 'Novo Usuário',
      icon: <GroupAddIcon style={IconStyle} />,
      url: '/usuarios/novo'
    });
  }

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: '#696391',
          border: '1px solid rgb(161 161 161 / 30%)',
          borderRadius: 0
        }
      }}
    >
      <Toolbar style={{ minHeight: 60 }} />
      <Box>
        <CustomList>
          {menuList.map(item => (
            <Link key={item.id} to={item.url} style={TextDecorationNone}>
              <CustomListItem>
                <ListItemIcon style={ListIconStyle}>{item.icon}</ListItemIcon>
                <CustomListItemText primary={item.name} />
              </CustomListItem>
            </Link>
          ))}
        </CustomList>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
