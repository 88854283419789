const FinancialTutorial = {
  steps: [
    {
      target: '.timeline',
      content: 'Aqui você poderá acompanhar o status do boleto de maneira simples e intuitiva.'
    },
    {
      target: '.inform-payment',
      content:
        'Aqui, você poderá registrar um pagamento até que o extrato de quitação seja disponibilizado. Após isso, a opção será desativada.'
    },
    {
      target: '.invoice-preview',
      content: 'Este espaço é destinado à apresentação de anexos, como o boleto e o extrato de quitação.'
    }
  ]
};

export default FinancialTutorial;
