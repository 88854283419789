import { Theme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';

const useStyle = (theme: Theme) => {
  return createUseStyles({
    root: {
      position: 'relative',
      '&:not(:last-of-type)': {
        borderBottom: `thin solid ${theme.palette.grey[300]}`
      }
    },
    downloadLink: {
      padding: 8,
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      fontSize: 13,
      color: theme.palette.grey[500],
      textDecoration: 'none',
      position: 'relative',
      zIndex: 99,
      paddingRight: 90,
      minHeight: 40,
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    info: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    name: {
      textTransform: 'uppercase',
      color: theme.palette.grey[700],
      fontStyle: 'oblique',
      padding: '0 4px 0 0'
    },
    description: {
      fontSize: 14,
      marginTop: 4,
      color: theme.palette.grey[700],
      width: '100%',
      textTransform: 'uppercase'
    },
    actions: {
      position: 'absolute',
      zIndex: 999,
      right: 8,
      top: 0,
      bottom: 0,
      margin: 'auto',
      height: 40
    }
  });
};

export default useStyle;
