const TrafficTicketDetailTutorial = {
  steps: [
    {
      target: '.welcome-tutorial',
      content:
        'A aba Financeiro foi reformulada, explore as melhorias e descubra todas as novidades que preparamos para você!',
      title: 'Olá, seja bem-vindo!'
    }
  ]
};

export default TrafficTicketDetailTutorial;
