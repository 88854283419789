import { CSSProperties } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export const root: CSSProperties = {
  position: 'absolute',
  width: '100%',
  left: 0,
  minHeight: '100vh',
  top: 0,
  background: 'radial-gradient(circle 150vh,#46398b 0,#27064b 40%,#1d033a 100%)',
  display: 'flex',
  justifyContent: 'space-around',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  padding: '20px 0'
};

export const imageBg: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  zIndex: 0,
  pointerEvents: 'none'
};

export const mainBox: CSSProperties = {
  width: 'calc(100vw - 40px)',
  maxWidth: 520,
  padding: '40px 35px 30px',
  margin: 'auto',
  borderRadius: '5px',
  backgroundColor: 'rgb(255 255 255 / 99%)',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const FormBox = styled(Box)({
  width: '100%',
  '& > form': {
    display: 'flex',
    flexDirection: 'column'
  }
});

export const linkForgotPass = {
  display: 'inline-block',
  position: 'relative',
  marginTop: '10px',
  paddingBottom: '5px',
  fontSize: '12px',
  color: '#54585a',
  textDecoration: 'none',
  '&::after': {
    content: '""',
    transition: 'all .3s ease-in-out',
    width: '100%',
    maxWidth: 0,
    height: '1px',
    left: 0,
    bottom: 0,
    position: 'absolute',
    backgroundColor: '#54585a'
  },
  '&:hover::after': {
    maxWidth: '100%'
  }
} as const;

export const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.primary.main
  }
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
    marginTop: 18,
    marginBottom: 10
  },
  '& input': {
    borderRadius: 5,
    fontSize: 18,
    padding: 0,
    height: 45,
    textAlign: 'center',
    fontWeight: 300,
    '&:disabled': {
      backgroundColor: '#e6e6e6',
      cursor: 'not-allowed'
    }
  }
}));

export const CustomSubmit = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '12px 30px',
    margin: '20px 0 5px 0',
    fontSize: '16px',
    fontWeight: 600,
    color: '#fff',
    textTransform: 'uppercase',
    lineHeight: '18px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    width: '100%',
    transition: 'all .3s ease-in-out',

    '&:hover': {
      backgroundColor: '#3a2f75'
    },

    '&:disabled': {
      pointerEvents: 'none',
      backgroundColor: theme.palette.action.disabled,
      color: '#7f7f7f'
    }
  }
}));
