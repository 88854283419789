import { EFontFamily } from 'helpers/constants';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  root: {
    margin: 0,
    padding: 0
  },
  description: {
    position: 'relative',
    bottom: 45,
    left: 28,
    width: 239
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1f1f1f',
    minHeight: '100vh',
    padding: '100px 0'
  },
  design: {
    display: 'flex',
    flexDirection: 'center',
    alignItems: 'center'
  },
  timeline: {
    width: '80%',
    maxWidth: 800,
    margin: ' 0 auto',
    display: 'flex',
    flexDirection: 'column',

    transition: '0.4s ease',

    '& p': {
      margin: '0 0 0 20px',
      fontSize: 12,
      padding: 0,
      fontFamily: EFontFamily.poppins,

      '& span': {
        fontWeight: 700,
        fontFamily: EFontFamily.poppins
      }
    },

    '& p:nth-of-type(2)': {
      color: '#848484',
      fontSize: 12
    }
  },
  timelineMiddle: {
    backgroundColor: '#D9D9D9',
    width: 2,
    height: 'auto'
  },
  mainMiddle: {
    opacity: 0
  },
  timelineCircle: {
    position: 'relative',
    right: 36,
    width: 70,
    height: 25,
    borderRadius: 20,
    backgroundColor: '#F2F2F2',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 13,

    '& span': {
      fontSize: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

export default useStyle;
