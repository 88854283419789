import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword } from 'services/auth';
import { useState } from 'react';
import TypographyValidate from 'components/Shared/Typography/TypographyValidate';

import { FormBox, CustomTextField, CustomInputLabel, CustomSubmit } from '../styles';
import { rulesTitle, ErrorMessage } from './styles';

const initialValues = { email: '', password: '', password_confirmation: '', token: '' };

type typeValues = typeof initialValues;
type handleResetPass = (data: unknown, updateSubmitting: (isSubmitting: boolean) => void) => void;

export default function ResetPass() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [urlParams, setUrlParams] = useSearchParams();

  const [requirements, setRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });

  const onValidate = (values: typeValues) => {
    let errors = {};

    if (!values.email) {
      errors = {
        ...errors,
        email: 'Obrigatório'
      };
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors = {
        ...errors,
        email: 'Email inválido'
      };
    }

    if (!values.password) {
      errors = {
        ...errors,
        password: 'Obrigatório'
      };
    } else if (!/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,20}$/i.test(values.password)) {
      errors = {
        ...errors,
        password: 'Senha não atende os requisitos mínimos de segurança'
      };
    }

    setRequirements({
      ...requirements,
      length: values.password.length >= 8,
      uppercase: /[A-Z]/.test(values.password),
      lowercase: /[a-z]/.test(values.password),
      number: /[0-9]/.test(values.password),
      special: /[!@#$&*]/.test(values.password)
    });

    if (!values.password_confirmation) {
      errors = {
        ...errors,
        password_confirmation: 'Obrigatório'
      };
    } else if (values.password !== values.password_confirmation) {
      errors = {
        ...errors,
        password_confirmation: 'Confirmação da senha está diferente da nova senha'
      };
    }

    return errors;
  };

  const handleResetPass: handleResetPass = (data, updateSubmitting) => {
    const success = () => {
      navigate('/login');
    };

    const error = (message: string) => {
      updateSubmitting(false);
      enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    };

    return resetPassword(data, success, error);
  };

  const onSubmit = (values: typeValues, formikHelpers: FormikHelpers<typeValues>) => {
    const { setSubmitting } = formikHelpers;

    const valuesWithToken: typeValues = {
      ...values,
      token: urlParams.get('token') || ''
    };

    handleResetPass(valuesWithToken, setSubmitting);
  };

  return (
    <>
      <Helmet>
        <title>Cadastro de Senha - UP Gestão de Frotas</title>
      </Helmet>

      <FormBox>
        <Formik initialValues={initialValues} validate={onValidate} onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='email'>
                  E-mail
                </CustomInputLabel>
                <CustomTextField
                  id='email'
                  name='email'
                  autoComplete='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  autoFocus
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.email && errors.email}</ErrorMessage>
              </FormControl>

              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='password'>
                  Nova Senha
                </CustomInputLabel>
                <CustomTextField
                  id='password'
                  name='password'
                  autoComplete='password'
                  type='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.password && errors.password}</ErrorMessage>
              </FormControl>

              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='password_confirmation'>
                  Confirme a senha
                </CustomInputLabel>
                <CustomTextField
                  id='password_confirmation'
                  name='password_confirmation'
                  autoComplete='password_confirmation'
                  type='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password_confirmation}
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.password_confirmation && errors.password_confirmation}</ErrorMessage>
              </FormControl>

              <Grid container>
                <Grid item xs>
                  <Typography component='strong' sx={rulesTitle}>
                    Requisitos para nova senha:
                  </Typography>

                  <TypographyValidate
                    component='span'
                    isValid={requirements.length}
                    label='Mínimo de 8 e máximo de 20 caracteres'
                  />
                  <TypographyValidate
                    component='span'
                    isValid={requirements.uppercase}
                    label='Conter ao menos 1 caractere MAIÚSCULO'
                  />
                  <TypographyValidate
                    component='span'
                    isValid={requirements.lowercase}
                    label='Conter ao menos 1 caractere minúsculo'
                  />
                  <TypographyValidate component='span' isValid={requirements.number} label='Conter ao menos 1 núm3r0' />
                  <TypographyValidate
                    component='span'
                    isValid={requirements.special}
                    label='Conter ao menos 1 caractere e$peci@l'
                  />
                </Grid>
                <Grid item>
                  <CustomSubmit
                    disabled={isSubmitting || !!Object.keys(errors).length}
                    type='submit'
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isSubmitting ? <CircularProgress size={16} /> : 'Salvar'}
                  </CustomSubmit>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </FormBox>
    </>
  );
}
