import styled from '@emotion/styled';
import { EFontFamily } from 'helpers/constants';
import { rem } from 'helpers/utils';

export const Cart = styled('div')(() => ({
  height: 110,

  '.cart': {
    border: `1px solid #E7E7E9`,
    borderRadius: 10,
    padding: 10,
    margin: `${rem(10)} 0`,
    width: 317,
    cursor: 'pointer',

    '@media (max-width: 1860px)': {
      width: 250
    },

    '@media (max-width: 1530px)': {
      margin: `${rem(10)} ${rem(10)}`
    },

    '& p': {
      padding: `0 ${rem(20)}`,
      margin: 0,
      fontFamily: EFontFamily.poppins,

      '@media (max-width: 1860px)': {
        padding: `0 ${rem(10)}`
      }
    },

    '.label': {
      fontSize: 14,
      color: '#737171',
      fontWeight: 500,

      '@media (max-width: 1860px)': {
        fontSize: 12
      }
    },

    '.value': {
      fontSize: 28,
      color: '#4A4A4A',
      fontWeight: 500
    },

    '.value-loading': {
      margin: '5px 20px'
    },

    '&.selected': {
      border: `1px solid #696391`,
      backgroundColor: '#F2F0FF',

      '.label, .value': {
        color: '#696391'
      }
    }
  },

  '.icon-close': {
    position: 'relative',
    left: 306,
    top: 29,
    fontSize: 26,
    cursor: 'pointer',

    '@media (max-width: 1860px)': {
      left: 240
    }
  }
}));
