import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Skeleton } from '@mui/material';
import { Cart } from './styles';

type Props = {
  title: string;
  value: any;
  selected: boolean;
  onSelect: () => void;
  onCancelClick?: () => void;
};

function CartFastFilter({ title, value, selected, onSelect, onCancelClick }: Props) {
  const handleCartClick = () => {
    if (!selected) {
      onSelect();
    }
  };

  const handleCancelClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onCancelClick?.();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleCartClick();
    }
  };

  return (
    <Cart>
      <CancelIcon
        color='primary'
        fontSize='inherit'
        className='icon-close'
        onClick={handleCancelClick}
        aria-label='Cancel'
        style={{ visibility: selected ? 'visible' : 'hidden' }}
      />

      <div
        className={`cart ${selected ? 'selected' : ''}`}
        role='button'
        tabIndex={0}
        onClick={handleCartClick}
        onKeyDown={handleKeyDown}
      >
        <div className='label'>
          <p>{title}</p>
        </div>
        {value >= 0 ? (
          <div className='value'>
            <p>{value}</p>
          </div>
        ) : (
          <Skeleton className='value-loading' variant='rounded' width={104} height={32} />
        )}
      </div>
    </Cart>
  );
}

export default CartFastFilter;
