import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { saveNewPassword } from 'services/user';
import { User } from 'services/auth';

import { FormBox, CustomTextField, CustomInputLabel, CustomSubmit } from '../styles';
import { rulesItem, rulesTitle, ErrorMessage } from './styles';

const initialValues = { current_password: '', password: '', password_confirmation: '' };

type typeValues = typeof initialValues;
type handleNewPass = (data: unknown, updateSubmitting: (isSubmitting: boolean) => void) => void;

export default function NewPass() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onValidate = (values: typeValues) => {
    let errors = {};

    if (!values.current_password) {
      errors = {
        ...errors,
        current_password: 'Obrigatório'
      };
    }

    if (!values.password) {
      errors = {
        ...errors,
        password: 'Obrigatório'
      };
    } else if (!/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,20}$/i.test(values.password)) {
      errors = {
        ...errors,
        password: 'Senha não atende os requisitos mínimos de segurança'
      };
    }

    if (!values.password_confirmation) {
      errors = {
        ...errors,
        password_confirmation: 'Obrigatório'
      };
    } else if (values.password !== values.password_confirmation) {
      errors = {
        ...errors,
        password_confirmation: 'Confirmação da senha está diferente da nova senha'
      };
    }

    return errors;
  };

  const handleNewPass: handleNewPass = (data, updateSubmitting) => {
    const success = (user: User) => {
      localStorage.setItem('user', JSON.stringify(user));

      navigate('/inicio');
    };

    const error = (message: string) => {
      updateSubmitting(false);
      enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    };

    return saveNewPassword(data, success, error);
  };

  const onSubmit = (values: typeValues, formikHelpers: FormikHelpers<typeValues>) => {
    const { setSubmitting } = formikHelpers;

    handleNewPass(values, setSubmitting);
  };

  return (
    <>
      <Helmet>
        <title>Cadastro de Senha - UP Gestão de Frotas</title>
      </Helmet>

      <FormBox>
        <Formik initialValues={initialValues} validate={onValidate} onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              {/* <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='accessGroup'>
                  Grupo de Acesso
                </CustomInputLabel>
                <CustomTextField
                  id='accessGroup'
                  name='accessGroup'
                  autoComplete='accessGroup'
                  autoFocus
                  required
                  fullWidth
                  disabled
                />
              </FormControl>

              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='email'>
                  E-mail
                </CustomInputLabel>
                <CustomTextField id='email' name='email' autoComplete='email' required fullWidth disabled />
              </FormControl> */}

              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='current_password'>
                  Senha Atual
                </CustomInputLabel>
                <CustomTextField
                  id='current_password'
                  name='current_password'
                  autoComplete='current_password'
                  type='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.current_password}
                  autoFocus
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.current_password && errors.current_password}</ErrorMessage>
              </FormControl>

              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='password'>
                  Nova Senha
                </CustomInputLabel>
                <CustomTextField
                  id='password'
                  name='password'
                  autoComplete='password'
                  type='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.password && errors.password}</ErrorMessage>
              </FormControl>

              <FormControl variant='standard'>
                <CustomInputLabel shrink htmlFor='password_confirmation'>
                  Confirme a senha
                </CustomInputLabel>
                <CustomTextField
                  id='password_confirmation'
                  name='password_confirmation'
                  autoComplete='password_confirmation'
                  type='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password_confirmation}
                  required
                  fullWidth
                />
                <ErrorMessage>{touched.password_confirmation && errors.password_confirmation}</ErrorMessage>
              </FormControl>

              <Grid container>
                <Grid item xs>
                  <Typography component='strong' sx={rulesTitle}>
                    Requisitos para nova senha:
                  </Typography>

                  <Typography component='span' sx={rulesItem}>
                    Mínimo de 8 e máximo de 20 caracteres
                  </Typography>
                  <Typography component='span' sx={rulesItem}>
                    Conter ao menos 1 caractere MAIÚSCULO
                  </Typography>
                  <Typography component='span' sx={rulesItem}>
                    Conter ao menos 1 caractere minúsculo
                  </Typography>
                  <Typography component='span' sx={rulesItem}>
                    Conter ao menos 1 núm3r0
                  </Typography>
                  <Typography component='span' sx={rulesItem}>
                    Conter ao menos 1 caractere e$peci@l
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomSubmit
                    disabled={isSubmitting || !!Object.keys(errors).length}
                    type='submit'
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isSubmitting ? <CircularProgress size={16} /> : 'Salvar'}
                  </CustomSubmit>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </FormBox>
    </>
  );
}
