import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';

export const rulesTitle: CSSProperties = {
  color: '#46398b',
  fontWeight: 'bold',
  fontSize: '14px',
  textDecoration: 'underline',
  display: 'inline-block'
};

export const rulesItem: CSSProperties = {
  color: '#F44336',
  fontSize: '12px',
  display: 'inline-block'
};

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: 12,
  margin: '-8px 2px 10px',
  color: theme.palette.error.main
}));
