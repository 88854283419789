import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from '../api';
import type { objError } from '../antt';

export type decisionDocument = {
  id: number;
  type: string;
  date: string;
  protocol: string;
  protocol_date: string;
  download_at: string;
  created_at: string;
  updated_at: string;
  document_link: string;
  decision_status: string;
  is_deferred: boolean;
};

export function getDecisionDocuments(callback: (data: decisionDocument[]) => void, infractionId: number) {
  api
    .get(`/antt/infraction/${infractionId}/decision/documents`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;

      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function downloadDecisionDocument(infractionId: number, decisionProtocol: string, finishCallback?: () => void) {
  api
    .download(`/antt/infraction/${infractionId}/decision/document/${decisionProtocol}`)
    .then(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';
      link.download = `${decisionProtocol}.pdf`;
      link.click();
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      console.log(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    })
    .finally(() => finishCallback && finishCallback());
}
