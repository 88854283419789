import type { AxiosError } from 'axios';
import { DEFAULT_RESQUEST_ERROR_MESSAGE } from 'helpers/constants';
import api from './api';
import { User } from './auth';

type objError = {
  data: null;
  message: string;
  status: string;
};

export type accessGroupCreate = {
  id: number;
  profile_id: number;
  role_id: number;
};

export type userDataCreate = {
  name: string;
  email: string;
  access_groups?: accessGroupCreate[];
};

export type userTutorial = {
  tutorial: {
    financial: boolean;
  };
};

export function saveNewPassword(
  data: unknown,
  callback: (user: User) => void,
  handleError: (errorMsg: string) => void
) {
  api
    .put('/user/password', data)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      handleError(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function createUser(
  data: userDataCreate,
  callback: (user: User) => void,
  handleError: (errorMsg: string) => void
) {
  api
    .post(`users`, data)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: AxiosError<objError>) => {
      const errorData = error?.response?.data;
      handleError(errorData?.message || DEFAULT_RESQUEST_ERROR_MESSAGE);
    });
}

export function postFirstAccessCheck(data: userTutorial) {
  return api.patch(`/user/settings/tutorial`, data).then(result => {
    return result;
  });
}
