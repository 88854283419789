import React from 'react';
import { CustomFormText } from './styles';

type Props = {
  label: string;
  value: string;
};

function StaticField({ label, value }: Props) {
  return (
    <CustomFormText
      type='text'
      label={label}
      value={value}
      rows={1}
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
      disabled
    />
  );
}

export default StaticField;
