import React, { useCallback, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormGroup from '@mui/material/FormGroup';
import { MUIDataTableState, MUISortOptions } from 'mui-datatables';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DateTime } from 'luxon';

import { EMPTY_FIELD_VALUE } from 'helpers/constants';
import { cnpjMask, unmaskDocument } from 'helpers/mask';
import { formatDate } from 'helpers/date';

import DataTable from 'components/Shared/Table';
import TopPage from 'components/Shared/TopPage';
import Loader from 'components/Shared/Loader';
import FormSelect from 'components/Shared/FormFields/FormSelect';
import FormSwitch from 'components/Shared/FormFields/FormSwitch';
import FormText from 'components/Shared/FormFields/FormText';
import FormAutoComplete from 'components/Shared/FormFields/FormAutoComplete';
import { FastFilterType } from 'helpers/types';
import {
  getInfractions,
  infraction,
  downloadInfractions,
  getTotalAccountsByFilter,
  downloadInfractionsFastFilter
} from 'services/antt';
import { getLicensePlateOwnersOptions } from 'services/licensePlateOwner';
import {
  getAnttAuthoritySituationOptions,
  getAnttInfractionStatusOptions,
  getAnttInfractionSubtypeOptions,
  getAnttInfractionTypeOptions,
  getAnttInvoiceStatusOptions,
  getAuthorities,
  getClientOptions,
  Option,
  Options
} from 'services/filters';
import { ROLE, User } from 'services/auth';
import CartFastFilter from 'components/Shared/Table/fast-filters';
import { getFilterParams } from 'helpers/utils';
import { buildInfractionUrl } from 'helpers/formats';
import { FAST_FILTERS_LABEL, columns, filterByOptions, filterByPeriodOptions } from './constants';
import type { FilterItem, ToggleItem } from './constants';
import useStyle, {
  CustomAccordion,
  CustomAccordionSummary,
  CustomAccordionDetails,
  CustomLoadingButton,
  CustomCardContent,
  WrapperCartFastFilter,
  TitleCartFastFilter
} from './styles';

const defaultSort: MUISortOptions = {
  name: 'datetime',
  direction: 'desc'
};

function TrafficTickets() {
  let timeCounter: number;
  const classes = useStyle();

  const [data, setData] = useState<infraction[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterIsLoading, setFilterIsLoading] = useState<boolean>(false);
  const [exportIsLoading, setExportIsLoading] = useState<boolean>(false);
  const [hasFilterSelected, setHasFilterSelected] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<FilterItem[]>([]);
  const [toggleList, setToggleList] = useState<ToggleItem[]>([]);
  const [urlFilter, setUrlFilter] = useState<string>('');

  const [companySelected, setCompanySelected] = useState('');
  const [typeSelected, setTypeSelected] = useState('');
  const [subtypeSelected, setSubtypeSelected] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const [situationSelected, setSituationSelected] = useState('');
  const [authoritySelected, setAuthoritySelected] = useState('');
  const [paymentStatusSelected, setPaymentStatusSelected] = useState('');
  const [plateOwner, setPlateOwner] = useState<Option | null>(null);
  const [filterBy, setFilterBy] = useState('');
  const [filterByPeriod, setFilterByPeriod] = useState('');
  const [initialDate, setinitialDate] = useState('');
  const [finalDate, setfinalDate] = useState('');

  const [search, setSearch] = useState('');

  const [pagePagination, setPagePagination] = useState<number>(1);
  const [perPagePagination, setPerPagePagination] = useState<number>(10);
  const [countPagination, setCountPagination] = useState<number>(0);
  const [sortOptions, setSortOptions] = useState<MUISortOptions>(defaultSort);

  const [companyList, setCompanyList] = useState<Options>([]);
  const [typeList, setTypeList] = useState<Options>([]);
  const [subtypeList, setSubtypeList] = useState<Options>([]);
  const [statusList, setStatusList] = useState<Options>([]);
  const [situationList, setSituationList] = useState<Options>([]);
  const [auhtorityList, setAuhtorityList] = useState<Options>([]);
  const [paymentStatusList, setPaymentStatusList] = useState<Options>([]);
  const [plateOwnerList, setPlateOwnerList] = useState<Options>([]);

  const [infractionTotals, setInfractionTotals] = useState<number[]>([]);
  const [selectedFastFilter, setSelectedFastFilter] = useState<string | null>(null);

  const userSerialized = localStorage.getItem('user') || '';
  const storageUser: User = JSON.parse(userSerialized);
  const isLegalExternal: boolean | undefined = storageUser.roles[0].name === ROLE.LEGAL_EXTERNAL;

  const handleSuccess = (newData: infraction[], newCount: number) => {
    setIsLoading(false);
    setData(newData);
    setCountPagination(newCount);
    setFilterIsLoading(false);
  };

  const loadFiltersLists = () => {
    getClientOptions((options: Options) => setCompanyList(options));
    getAuthorities((options: Options) => setAuhtorityList(options));
    getAnttInfractionTypeOptions((options: Options) => setTypeList(options));
    getAnttInfractionSubtypeOptions((options: Options) => setSubtypeList(options));
    getAnttInfractionStatusOptions((options: Options) => setStatusList(options));
    getAnttAuthoritySituationOptions((options: Options) => setSituationList(options));
    getAnttInvoiceStatusOptions((options: Options) => setPaymentStatusList(options));
    getLicensePlateOwnersOptions((options: Options) => setPlateOwnerList(options));
  };

  const fetchData = useCallback(
    (
      perPage?: number,
      page?: number,
      search?: string,
      filters?: string,
      dateRef?: string,
      from?: string,
      to?: string,
      plateOwner?: Option | null
    ) => {
      setIsLoading(true);
      let defaultFilter = `&columns[0][name]=${defaultSort.name}&columns[0][order]=${defaultSort.direction}`;

      if (isLegalExternal) {
        defaultFilter += '&toggles[0]=forwardedToLegal';
      }

      getInfractions(handleSuccess, {
        perPage: perPage || 10,
        page: page || 1,
        search: search || '',
        filters: filters || defaultFilter,
        dateRef,
        from,
        to,
        plateOwner: plateOwner
          ? { cnpj: plateOwner.data.responsable_cnpj || '', name: plateOwner.data.responsable_name || '' }
          : null
      });
    },
    [isLegalExternal]
  );

  const clearAllFilters = () => {
    setCompanySelected('');
    setTypeSelected('');
    setSubtypeSelected('');
    setStatusSelected('');
    setSituationSelected('');
    setAuthoritySelected('');
    setPaymentStatusSelected('');
    setPlateOwner(null);
    setFilterBy('');
    setFilterByPeriod('');
    setFilterList([] as FilterItem[]);
    setHasFilterSelected(false);
    setinitialDate('');
    setfinalDate('');
    setToggleList([] as ToggleItem[]);
    setUrlFilter('');
    setSearch('');
    setSelectedFastFilter(null);
    setPerPagePagination(10);
    setPagePagination(1);
  };

  const onAppyFastFilter = (data: FastFilterType) => {
    const { name: filterName, page: filterPageNumber, rowsPerPage: filterRowsPerPage } = data;
    let filter = '';

    if (!data.page || !data.rowsPerPage || !data.name) {
      setSelectedFastFilter(null);
      clearAllFilters();
    }

    if (filterPageNumber) {
      setPagePagination(filterPageNumber);
    }

    if (filterRowsPerPage) {
      setPerPagePagination(filterRowsPerPage);
    }

    if (filterName) {
      const filterParams = getFilterParams(filterName);
      filter = buildInfractionUrl(filterParams);
      setSelectedFastFilter(filterName);
    }

    fetchData(
      filterRowsPerPage,
      filterPageNumber,
      unmaskDocument(search),
      filter,
      filterBy,
      initialDate,
      finalDate,
      plateOwner
    );
  };

  const onChangePage = (page: number) => {
    if (selectedFastFilter) {
      const data = {
        name: selectedFastFilter,
        page: page
      };

      onAppyFastFilter(data);
      return;
    }

    setPagePagination(page);

    fetchData(perPagePagination, page, unmaskDocument(search), urlFilter, filterBy, initialDate, finalDate, plateOwner);
  };

  const onChangeRowsPerPage = (rowsPerPage: number) => {
    if (selectedFastFilter) {
      const data = {
        name: selectedFastFilter,
        rowsPerPage: rowsPerPage
      };

      onAppyFastFilter(data);
      return;
    }

    setPerPagePagination(rowsPerPage);

    fetchData(
      rowsPerPage,
      pagePagination,
      unmaskDocument(search),
      urlFilter,
      filterBy,
      initialDate,
      finalDate,
      plateOwner
    );
  };

  const onSearch = (search: string) => {
    window.clearTimeout(timeCounter);

    timeCounter = window.setTimeout(() => {
      const validLenght = search.length === 0 || search.length > 2;

      const triggSearch = () => {
        setSearch(search);
        fetchData(
          perPagePagination,
          pagePagination,
          unmaskDocument(search),
          urlFilter,
          filterBy,
          initialDate,
          finalDate,
          plateOwner
        );
      };

      if (validLenght) triggSearch();
    }, 2000);
  };

  const onChangeFilterBy = (filter: string) => {
    setFilterBy(filter);
    setHasFilterSelected(filterByPeriod !== '');
  };

  const onChangeFilterByPeriod = (period: string) => {
    setFilterByPeriod(period);
    setHasFilterSelected(filterBy !== '');

    const dateFormat = 'yyyy-MM-dd';
    const today = DateTime.now();
    switch (period) {
      case 'week':
      case 'month':
      case 'year':
        setinitialDate(today.startOf(period).toFormat(dateFormat));
        break;
      case '30':
      case '365':
        setinitialDate(today.minus({ days: Number(period) }).toFormat(dateFormat));
        break;

      default:
        setinitialDate(today.toFormat(dateFormat));
        break;
    }
    setfinalDate(today.toFormat(dateFormat));
  };

  const onChangeFilterPlateOwner = (owner: Option) => {
    setPlateOwner(owner);
    setHasFilterSelected(true);
  };

  const addFilter = (name: string, value: unknown): void => {
    const index = filterList.findIndex(filter => filter.name === name);

    if (index < 0) {
      filterList.push({
        name: name,
        filter: value
      });
    } else {
      filterList[index].filter = value;
    }

    setFilterList(filterList);
  };

  const removeFilter = (name: string): void => {
    const newFilterList = filterList.filter(filter => {
      return filter.name !== name;
    });

    setFilterList(newFilterList);
  };

  const createURLFilter = (sortOpts: MUISortOptions): string | null => {
    if (filterList.length === 0) {
      return `&columns[0][name]=${sortOpts.name}&columns[0][order]=${sortOpts.direction}`;
    }

    let sortUrlCreated = false;
    let urlFilter = '';
    filterList.forEach((filterItem: FilterItem, index: number) => {
      urlFilter += `&columns[${index}][name]=${filterItem.name}`;
      urlFilter += filterItem.filter ? `&columns[${index}][filter]=${filterItem.filter}` : '';

      if (filterItem.name === sortOpts.name) {
        urlFilter += `&columns[${index}][order]=${sortOpts.direction}`;
        sortUrlCreated = true;
      }
    });

    if (!sortUrlCreated) {
      const lastIndex = filterList.length;
      urlFilter += `&columns[${lastIndex}][name]=${sortOpts.name}&columns[${lastIndex}][order]=${sortOpts.direction}`;
    }

    return urlFilter;
  };

  const createURLToggle = (): string | null => {
    if (toggleList.length === 0) {
      return null;
    }

    let urlFilter = '';
    toggleList.forEach((toggleItem: ToggleItem, index: number) => {
      urlFilter += `&toggles[${index}]=${toggleItem.name}`;
    });

    return urlFilter;
  };

  const onFilter = (): void => {
    setFilterIsLoading(true);
    setPerPagePagination(10);
    setPagePagination(1);

    let filter = '';
    filter += createURLFilter(sortOptions) ?? '';
    filter += createURLToggle() ?? '';

    setUrlFilter(filter);
    setPagePagination(1);
    fetchData(
      perPagePagination,
      pagePagination,
      unmaskDocument(search),
      filter,
      filterBy,
      initialDate,
      finalDate,
      plateOwner
    );

    setSelectedFastFilter(null);
  };

  type setState = React.Dispatch<React.SetStateAction<string>>;

  const onClear = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, setState: setState, name: string) => {
    setState('');
    removeFilter(name);
  };

  const onAutoCompleteSelectedFilter = (opt: Option, name: string, setState: setState) => {
    const value = opt?.value || '';
    setState(value);
    setHasFilterSelected(true);
    addFilter(name, value);
  };

  const onSortTable = (sortOpts: MUISortOptions) => {
    setSortOptions(sortOpts);

    let filter = '';
    filter += createURLFilter(sortOpts) ?? '';
    filter += createURLToggle() ?? '';

    setUrlFilter(filter);
    fetchData(
      perPagePagination,
      pagePagination,
      unmaskDocument(search),
      filter,
      filterBy,
      initialDate,
      finalDate,
      plateOwner
    );
  };

  const onToggle = (name: string, checked: boolean) => {
    if (checked) {
      toggleList.push({ name: name });

      setToggleList(toggleList);
      setHasFilterSelected(true);

      return;
    }

    const newToggleList: ToggleItem[] = toggleList.filter(toggle => {
      return toggle.name !== name;
    });

    setToggleList(newToggleList);
  };

  const onExport = () => {
    let filter = '';
    const hasFastFilterApplied = selectedFastFilter !== null;

    filter += createURLFilter(sortOptions) ?? '';
    filter += createURLToggle() ?? '';

    setExportIsLoading(true);

    if (hasFastFilterApplied) {
      const filterParams = getFilterParams(selectedFastFilter);
      const fastFilter = buildInfractionUrl(filterParams);

      downloadInfractionsFastFilter(() => setExportIsLoading(false), fastFilter);

      return;
    }

    downloadInfractions(() => setExportIsLoading(false), {
      perPage: perPagePagination || 10,
      page: pagePagination || 1,
      search: unmaskDocument(search) || '',
      filters: filter,
      dateRef: filterBy,
      from: initialDate,
      to: finalDate,
      plateOwner: plateOwner
        ? { cnpj: plateOwner.data.responsable_cnpj || '', name: plateOwner.data.responsable_name || '' }
        : null
    });
  };

  async function fetchInfractionCounts() {
    const data = await Promise.all(FAST_FILTERS_LABEL.map(item => getTotalAccountsByFilter(item)));

    if (selectedFastFilter) {
      const fastFilter = {
        name: selectedFastFilter
      };

      onAppyFastFilter(fastFilter);
    }

    return data;
  }

  useEffect(() => {
    loadFiltersLists();

    fetchInfractionCounts().then(data => {
      setInfractionTotals(data);
    });

    if (isLegalExternal) {
      onToggle('forwardedToLegal', true);
    }

    return fetchData();
    // eslint-disable-next-line
  }, [fetchData]);

  const formattedData = data?.map((item: infraction) => {
    const { infraction_date, authority_name, subtype, status, cnpj } = item;
    const dateFormatted = infraction_date ? formatDate(infraction_date) : EMPTY_FIELD_VALUE;
    const cnpjFormatted = cnpjMask(cnpj);

    return {
      ...item,
      cnpj: cnpjFormatted,
      datetime: dateFormatted,
      authority: authority_name,
      status: status || '-',
      subtype: subtype || '-'
    };
  });

  const filter = {
    searchText: search
  };

  const pagination = {
    page: pagePagination,
    count: countPagination,
    rowsPerPage: perPagePagination,
    sortOrder: sortOptions,
    onTableChange: (action: string, tableState: MUIDataTableState) => {
      switch (action) {
        case 'changePage':
          return onChangePage(tableState.page);
        case 'changeRowsPerPage':
          return onChangeRowsPerPage(tableState.rowsPerPage);
        case 'search':
          return onSearch(tableState.searchText || '');
        case 'sort':
          return onSortTable(tableState.sortOrder);
        default:
          return console.info(`action (${action}) not handled.`);
      }
    }
  };

  const optionsObj = {
    ...filter,
    ...pagination,
    jumpToPage: true
  };

  return (
    <>
      <TopPage title='Multas' />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={CustomCardContent}>
            <CardContent>
              {!isLoading && infractionTotals && (
                <>
                  <TitleCartFastFilter>
                    <p>Filtros rápidos</p>
                    <hr />
                  </TitleCartFastFilter>
                  <WrapperCartFastFilter>
                    {FAST_FILTERS_LABEL.map((item: string, index: number) => (
                      <CartFastFilter
                        key={item}
                        value={infractionTotals[index]}
                        title={item}
                        selected={item === selectedFastFilter}
                        onSelect={() => {
                          onAppyFastFilter({ name: item });
                        }}
                        onCancelClick={() => onAppyFastFilter({ name: null })}
                      />
                    ))}
                  </WrapperCartFastFilter>
                </>
              )}

              {!isLoading && (
                <CustomAccordion elevation={0}>
                  <CustomAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <FilterAltIcon />
                    <p>Filtros detalhados</p>
                  </CustomAccordionSummary>
                  <CustomAccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <FormAutoComplete
                          id='cnpj'
                          label='CNPJ'
                          options={companyList}
                          value={companySelected}
                          onChange={(_, newValue) =>
                            onAutoCompleteSelectedFilter(newValue as Option, 'cnpj', setCompanySelected)
                          }
                          onClear={event => onClear(event, setCompanySelected, 'cnpj')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormAutoComplete
                          id='type'
                          label='Tipo'
                          value={typeSelected}
                          onChange={(_, newValue) =>
                            onAutoCompleteSelectedFilter(newValue as Option, 'type', setTypeSelected)
                          }
                          onClear={event => onClear(event, setTypeSelected, 'type')}
                          options={typeList}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormAutoComplete
                          id='subtype'
                          label='Sub Tipo'
                          value={subtypeSelected}
                          onChange={(_, newValue) =>
                            onAutoCompleteSelectedFilter(newValue as Option, 'subtype', setSubtypeSelected)
                          }
                          options={subtypeList}
                          onClear={event => onClear(event, setSubtypeSelected, 'subtype')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormAutoComplete
                          id='status'
                          label='Status'
                          value={statusSelected}
                          onChange={(_, newValue) =>
                            onAutoCompleteSelectedFilter(newValue as Option, 'status', setStatusSelected)
                          }
                          options={statusList}
                          onClear={event => onClear(event, setStatusSelected, 'status')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormAutoComplete
                          id='situation'
                          label='Situação'
                          value={situationSelected}
                          onChange={(_, newValue) =>
                            onAutoCompleteSelectedFilter(newValue as Option, 'authority_status', setSituationSelected)
                          }
                          options={situationList}
                          onClear={event => onClear(event, setSituationSelected, 'authority_status')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormAutoComplete
                          id='authority'
                          label='Autoridade'
                          value={authoritySelected}
                          onChange={(_, newValue) =>
                            onAutoCompleteSelectedFilter(newValue as Option, 'authority_name', setAuthoritySelected)
                          }
                          options={auhtorityList}
                          onClear={event => onClear(event, setAuthoritySelected, 'authority_name')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormAutoComplete
                          id='paymentStatus'
                          label='Status do Boleto'
                          value={paymentStatusSelected}
                          onChange={(_, newValue) =>
                            onAutoCompleteSelectedFilter(newValue as Option, 'invoice_status', setPaymentStatusSelected)
                          }
                          options={paymentStatusList}
                          onClear={event => onClear(event, setPaymentStatusSelected, 'invoice_status')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormAutoComplete
                          id='plateOwner'
                          label='Responsável pelo Veículo'
                          options={plateOwnerList}
                          value={plateOwner ? plateOwner.label : ''}
                          onChange={(_, newValue) => onChangeFilterPlateOwner(newValue as Option)}
                          onClear={() => setPlateOwner(null)}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <FormGroup>
                              <FormSwitch
                                label='Encaminhado ao jurídico'
                                checked={isLegalExternal}
                                disabled={isLegalExternal}
                                handleOnChange={(_event, checked) => {
                                  onToggle('forwardedToLegal', checked);
                                }}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={3}>
                            <FormGroup>
                              <FormSwitch
                                label='Passível de Defesa/Recurso'
                                disabled={isLegalExternal}
                                handleOnChange={(_event, checked) => {
                                  onToggle('defensible', checked);
                                }}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={3}>
                            <FormGroup>
                              <FormSwitch
                                label='Pendente de envio ao financeiro'
                                disabled={isLegalExternal}
                                handleOnChange={(_event, checked) => {
                                  onToggle('pendingForwardFinancial', checked);
                                }}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={3}>
                            <FormGroup>
                              <FormSwitch
                                label='Encaminhado ao financeiro'
                                disabled={isLegalExternal}
                                handleOnChange={(_event, checked) => {
                                  onToggle('forwardedToFinancial', checked);
                                }}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        <FormSelect
                          id='filterBy'
                          label='Filtrar por'
                          value={filterBy}
                          onChange={event => onChangeFilterBy(event.target.value)}
                          options={filterByOptions}
                          onClear={() => setFilterBy('')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormSelect
                          id='filterByPeriod'
                          label='Período'
                          value={filterByPeriod}
                          onChange={event => onChangeFilterByPeriod(event.target.value)}
                          options={filterByPeriodOptions}
                          onClear={() => setFilterByPeriod('')}
                        />
                      </Grid>

                      {filterByPeriod === 'custom' && (
                        <>
                          <Grid item xs={3}>
                            <FormText
                              type='date'
                              InputLabelProps={{ shrink: true }}
                              label='Data Inicial'
                              value={initialDate}
                              onChange={e => setinitialDate(e.target.value)}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <FormText
                              type='date'
                              InputLabelProps={{ shrink: true }}
                              label='Data Final'
                              value={finalDate}
                              onChange={e => setfinalDate(e.target.value)}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid
                        container
                        item
                        xs={filterByPeriod === 'custom' ? 12 : 3}
                        justifyContent={`flex-${filterByPeriod === 'custom' ? 'end' : 'start'}`}
                        alignItems={`flex-${filterByPeriod === 'custom' ? 'end' : 'start'}`}
                      >
                        <CustomLoadingButton
                          variant='contained'
                          size='medium'
                          color='inherit'
                          onClick={() => {
                            clearAllFilters();
                            fetchData();
                          }}
                          loading={filterIsLoading}
                          disabled={!hasFilterSelected}
                          disableElevation
                        >
                          Limpar
                        </CustomLoadingButton>
                        &nbsp;
                        <CustomLoadingButton
                          variant='contained'
                          size='medium'
                          onClick={onFilter}
                          loading={filterIsLoading}
                          disabled={!hasFilterSelected}
                          className='submit-button'
                          disableElevation
                        >
                          Aplicar
                        </CustomLoadingButton>
                      </Grid>
                    </Grid>
                  </CustomAccordionDetails>
                </CustomAccordion>
              )}

              {!isLoading && formattedData?.length !== 0 && (
                <div className={classes.actionsRoot}>
                  <CustomLoadingButton
                    startIcon={<FileDownloadIcon />}
                    variant='outlined'
                    onClick={onExport}
                    loading={exportIsLoading}
                    disabled={exportIsLoading}
                  >
                    Exportar
                  </CustomLoadingButton>
                </div>
              )}

              {isLoading ? <Loader /> : <DataTable columns={columns} data={formattedData} optionsObj={optionsObj} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default TrafficTickets;
