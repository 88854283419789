import { EFontFamily } from 'helpers/constants';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';

export const SubmitButton = styled(Button)(() => ({
  borderRadius: 40,
  paddingLeft: 30,
  paddingRight: 30,
  '&:hover': {
    backgroundColor: '#DDE524'
  }
}));

export const PrimaryButton = styled(Button)(() => ({
  borderRadius: 40,
  paddingLeft: 30,
  paddingRight: 30
}));

const useStyle = (theme: Theme) => {
  return createUseStyles({
    topInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.main
    },
    topTitle: {
      margin: 0,
      fontSize: 20,
      fontFamily: EFontFamily.exo
    },
    topItem: {
      margin: 0,
      fontSize: 12
    },
    tabsContainer: {
      padding: '16px 0'
    },
    bottomButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      '& a': {
        textDecoration: 'none'
      }
    }
  });
};

export default useStyle;
