import * as React from 'react';
import Button from '@mui/material/Button';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  BootstrapDialog,
  WarningDiologTypography,
  WarningDiologTypographyTitle,
  WarningImage,
  WarningImageContent
} from './style';

type Props = {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
};

export default function WarningDialog({ open, handleClose, title, description }: Props) {
  const handleClickClose = () => {
    handleClose();
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='warning-dialog'
      open={open}
      maxWidth='sm'
      style={{ borderRadius: '20px !important' }}
    >
      <IconButton
        aria-label='close'
        onClick={handleClickClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <WarningDiologTypographyTitle align='center'>
          <WarningImageContent>
            <WarningImage />
          </WarningImageContent>
          {title}
        </WarningDiologTypographyTitle>

        <WarningDiologTypography align='center'>{description}</WarningDiologTypography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClickClose}>
          Voltar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
