export enum EFontFamily {
  exo = '"Exo 2", sans-serif',
  poppins = '"Poppins", sans-serif',
  inter = '"Inter", sans-serif'
}

export const EMPTY_FIELD_VALUE = 'Não informado';

export const DEFAULT_AMOUNT = '0,00';

export const DEFAULT_RESQUEST_ERROR_MESSAGE =
  'Ops! Houve algum erro. Tente novamente mais tarde ou contate o administrador se o erro persistir';

export const IN_ANALYSIS_BY_LEGAL = 'Em Análise pelo Jurídico';

export const COMPLETED = 'Finalizado';

export const NOTIFICATION_INITIAL_ANALYSIS = 'Notificação em Análise Inicial';

export const SCHEDULED_PAYMENT = 'Pagamento Programado';

export const LEGAL_PROCESS_TREATMENT = 'Ação Judicial (Tratativa Jurídica)';

export const PAYMENT_COMPLETED = 'Pagamento Realizado';

export const TITLE_LAYER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

export const AMOUNT_PAID = 'Valor pago';

export const BILL_PAID = 'Quitada';

export const FINANCIAL_TUTORIAL_STEP = 'tutorialStep';

export const STATUS_CHANGE_EVENT = 'statusChange';

export const DISCOUNT_TITLE = 'Desconto/abatimento';

export const FEE_TITLE = 'Juros + Multa';

export const RESIDUAL_TITLE = 'Valor Residual';
