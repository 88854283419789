import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 490.3 490.3' {...props}>
      <path d='M0 121.05v248.2c0 34.2 27.9 62.1 62.1 62.1h200.6c34.2 0 62.1-27.9 62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3s-12.3 5.5-12.3 12.3v40.2c0 20.7-16.9 37.6-37.6 37.6H62.1c-20.7 0-37.6-16.9-37.6-37.6v-248.2c0-20.7 16.9-37.6 37.6-37.6h200.6c20.7 0 37.6 16.9 37.6 37.6v40.2c0 6.8 5.5 12.3 12.3 12.3s12.3-5.5 12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1c-34.2 0-62.1 27.8-62.1 62.1z' />
      <path d='M385.4 337.65c2.4 2.4 5.5 3.6 8.7 3.6s6.3-1.2 8.7-3.6l83.9-83.9c4.8-4.8 4.8-12.5 0-17.3l-83.9-83.9c-4.8-4.8-12.5-4.8-17.3 0s-4.8 12.5 0 17.3l63 63H218.6c-6.8 0-12.3 5.5-12.3 12.3 0 6.8 5.5 12.3 12.3 12.3h229.8l-63 63c-4.8 4.7-4.8 12.5 0 17.2z' />
    </SvgIcon>
  );
}

export function PoliceIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 46.17 46.17' {...props}>
      <path d='M9.787 5.615h10.839c.536 0 1.017-.34 1.216-.837L23.556.486A.356.356 0 0 0 23.232 0H9.787c-.723 0-1.286.586-1.286 1.31v2.967c.001.724.563 1.338 1.286 1.338zM15.264 16.296c3.486 0 6.36-2.647 6.752-6.035h1.229c.135 0 .259-.059.316-.182a.314.314 0 0 0-.049-.352l-1.703-2.005-12.01.022c-.673 0-1.238.486-1.303 1.156a6.12 6.12 0 0 0-.031.624c0 3.755 3.043 6.772 6.799 6.772zM25.646 41.427H11.62c-.825 0-1.494-.675-1.494-1.5s.668-1.5 1.494-1.5h9.869a.528.528 0 0 0 .479-.274.521.521 0 0 0-.037-.549S8.899 18.958 8.857 18.848l-.652-.007a3.352 3.352 0 0 0-3.385 3.292l-.352 19.368a3.148 3.148 0 0 0 3.126 3.209 1.32 1.32 0 0 0 1.314 1.46h15.764c.374 0 .73-.159.98-.438a1.32 1.32 0 0 0 .333-1.021l-.339-3.284zM35.534 25.136a2.785 2.785 0 0 0-2.036.653l-2.219 1.881-7.854-7.819a3.353 3.353 0 0 0-2.365-.977h-8.593L24.64 36.268a.246.246 0 0 0 .447-.166l-.792-7.58 3.958 4.041a4.104 4.104 0 0 0 2.935 1.229c.941 0 1.886-.319 2.65-.967l3.271-2.774a2.794 2.794 0 0 0-1.575-4.915zM39.189 8.228h-5.92a2.506 2.506 0 0 0-2.505 2.508v8.922a2.506 2.506 0 0 0 2.505 2.508h.294c.06.473.299.904.671 1.188.432.33.986.418 1.491.241 0 0 4.269-1.548 4.392-1.604.987-.458 1.585-1.273 1.585-2.332v-8.922a2.513 2.513 0 0 0-2.513-2.509zm-4.499 2.807c-.675.238-1.119.907-1.119 1.659v7.535h-.334a.55.55 0 0 1-.537-.535v-8.928c0-.284.252-.506.537-.506h3.638l-2.185.775z' />
    </SvgIcon>
  );
}
