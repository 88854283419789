import React from 'react';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import { Locale } from 'helpers/types';
import dollarGif from '../../../assets/images/dollar.gif';
import { UserTutorialContainer } from './style';

interface Props {
  stepsTutorial: any;
  customLocale?: Locale;
  run?: boolean;
  onFinish?: () => void;
}

export default function UserTutorial({ stepsTutorial, customLocale, onFinish, run }: Props) {
  const locale = {
    back: 'Voltar',
    close: 'Fechar',
    last: 'Último',
    next: 'Próximo',
    open: 'Abrir tutorial',
    skip: 'Pular',
    ...customLocale
  };

  const handleCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.FINISHED) {
      if (onFinish) {
        onFinish();
      }
    }
  };

  return (
    <UserTutorialContainer>
      <Joyride
        disableCloseOnEsc
        disableOverlayClose
        hideCloseButton
        hideBackButton
        steps={stepsTutorial}
        locale={locale}
        callback={handleCallback}
        run={run}
        scrollOffset={80}
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            overlayColor: 'rgb(26 26 26 / 40%)',
            primaryColor: '#888ea2',
            textColor: '#424242',
            zIndex: 2000
          },
          beacon: {
            backgroundColor: 'rgb(255 255 255 / 79%)',
            border: '2px solid #888ea2',
            borderRadius: '50%',
            boxShadow: 'none',
            position: 'absolute',
            top: '-27px'
          },
          beaconInner: {
            position: 'absolute',
            top: 16
          },
          beaconOuter: {
            position: 'absolute',
            top: 0
          },
          buttonNext: {
            backgroundColor: '#7f7f7f',
            color: '#ffffff',
            borderRadius: '50px',
            padding: '12px 20px',
            fontSize: '14px',
            width: '100%'
          },
          buttonBack: {
            backgroundColor: '#fff',
            color: '#888ea2',
            borderRadius: '50px',
            padding: '10px 20px',
            fontSize: '14px'
          },
          buttonSkip: {
            backgroundColor: '#fff',
            color: '#888ea2',
            borderRadius: '50px',
            padding: '10px 20px',
            fontSize: '14px'
          },
          buttonClose: {
            backgroundColor: '#fff',
            color: '#888ea2',
            borderRadius: '50px',
            padding: '10px 20px',
            fontSize: '14px'
          },
          tooltip: {
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 0 0 2px #fff',
            color: '#888ea2',
            fontSize: '14px',
            textAlign: 'center'
          },
          tooltipTitle: {
            fontSize: '20px',
            fontWeight: 700,
            color: '#000',
            backgroundImage: `url(${dollarGif})`,
            backgroundSize: '50%',
            backgroundPosition: '52% 74%',
            backgroundRepeat: 'no-repeat',
            height: 198,
            opacity: '55%'
          },
          tooltipContent: {
            fontSize: '14px',
            padding: '0px 8px 0px 8px'
          },
          tooltipFooter: {
            fontSize: '14px'
          },
          tooltipContainer: {
            padding: '10px 20px'
          }
        }}
      />
    </UserTutorialContainer>
  );
}
