import { styled } from '@mui/material/styles';
import { EFontFamily } from 'helpers/constants';

export const List = styled('ul')(() => ({
  listStyle: 'none',
  margin: 0,
  padding: 0
}));

export const Item = styled('li')(() => ({
  padding: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 26,

  '&::before': {
    content: '""',
    position: 'relative',
    left: 0,
    top: 2,
    backgroundColor: '#9BADDA',
    width: 22,
    height: 10,
    display: 'inline-block',
    borderRadius: 10,
    marginRight: 5
  },

  '&.fee::before': {
    backgroundColor: '#D18585'
  },

  '&.residual::before': {
    backgroundColor: '#E8DDA4'
  }
}));

export const Title = styled('p')(() => ({
  paddingRight: 6,
  margin: 0,
  color: '#58585B',
  fontWeight: 500,
  fontFamily: EFontFamily.inter,
  fontSize: 14
}));

export const Amount = styled('p')(() => ({
  padding: 0,
  margin: 0,
  color: '#7D7D7D',
  fontWeight: 200,
  fontFamily: EFontFamily.inter,
  fontSize: 14
}));

export const ContentItem = styled('p')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > .MuiSvgIcon-fontSizeMedium': {
    fontSize: '18px !important',
    color: '#7D7D7D'
  }
}));
