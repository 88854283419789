import { styled } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export const CustomList = styled(List)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.primary.main,
  margin: 5
}));

export const CustomListItem = styled(ListItem)(() => ({
  position: 'relative',
  color: '#f2f2f2',
  padding: '10px 12px',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    width: '100%',
    height: '100%',
    maxWidth: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'all .3s ease-in-out',
    backgroundColor: '#605a82',
    borderRadius: 6
  },
  '&:hover::before': { maxWidth: '100%' }
}));

export const ListIconStyle = {
  minWidth: 'unset'
};

export const IconStyle = {
  width: 18,
  height: 18,
  color: '#fff',
  marginRight: 12
};

export const CustomListItemText = styled(ListItemText)(() => ({
  margin: 0,
  '& > span': {
    fontSize: 14,
    fontWeight: 400,
    position: 'relative',
    zIndex: 100
  }
}));

export const TextDecorationNone = {
  textDecoration: 'none'
};
